
import React, {  } from 'react'
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import {Popover, Tag, Typography } from 'antd'
import { Breadcrumbs } from '@mui/material'
import { ROLE_ADMIN, ROLE_USER } from 'constant';
import {
  MoreVertOutlined} from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
const DocumentViewer = ({
  caseName,
  document,
  filename,
  caseId,
  fileUrl,
  isEligible,
  showEligibility= true,
  showNote= true,
  onRename = () => { },
  onDelete = () => { }}) => {


  const { t } = useTranslation()
  const navigate = useNavigate()
  const optionsPopover = [
    {
      title: t('document.rename'),
      callback: onRename,
      role: [ROLE_ADMIN, ROLE_USER]
    },
    {
      title: t('document.delete'),
      callback: onDelete,
      role: [ROLE_ADMIN]
    }
  ]

  function handleNavigation(value, state = {}, isReplace = false) {
    return navigate(value, { state: state, replace: isReplace })
  }
  
  


  return (
    fileUrl && document?.ext && (
      <div className="overflow-hidden flex-1 h-full pt-4 bg-white border-solid border-[#E3E3E8] border rounded-3xl no-scrollbar relative">
        <div className="border-solid border-[#E3E3E8] border-t-0 border-x-0 px-6 pb-3 flex justify-between items-end">
          <div>
          <Breadcrumbs>
              <Typography.Text
                className="text-[#828282] hover:text-black font-normal text-[14px] cursor-pointer"
                onClick={() => {
                  handleNavigation('/cases')
                }}>
                {t('breadcrumb.cases')}
              </Typography.Text>
              <Typography.Text
                className="text-[#828282] hover:text-black font-normal text-[14px] cursor-pointer"
                onClick={handleNavigation.bind(
                  this,
                  '/cases/detail',
                  {
                    data: { id: caseId }
                  },
                  true
                )}>
                {caseName}
              </Typography.Text>
              <Typography.Text className="text-black font-normal text-[14px]">
              {t('breadcrumb.documents')}
              </Typography.Text>
            </Breadcrumbs>
            <Typography.Text className="font-semibold text-3xl">{filename}</Typography.Text>
          </div>
          <div className="flex items-center mb-1">
          {showEligibility && (
              <>
                {isEligible && (
                  <Tag color="green" className="rounded-xl px-3 mr-0">
                    <Typography.Text className="font-semibold text-green-600">
                      {t('document.eligible')}
                    </Typography.Text>
                  </Tag>
                )}
                {!isEligible && (
                  <Tag color="red" className="rounded-xl px-3 mr-0">
                    <Typography.Text className="font-semibold text-red-600">
                      {t('document.Ineligible')}
                    </Typography.Text>
                  </Tag>
                )}
              </>

            )}
            <Popover
              content={
                <div className="w-[128px] h-full flex flex-col text-black">
                  {
                    optionsPopover.map((option, index) => (
                      // option.role.includes(platformData?.role?.toUpperCase()) && (
                      <div
                        key={index}
                        onClick={option.callback}
                        className="px-2 py-1 cursor-pointer hover:bg-[#0000000A] text-black rounded text-sm font-normal">
                        {option.title}
                      </div>
                    ))
                    // )
                  }
                </div>
              }
              trigger="click"
              placement="bottomRight"
              arrow={false}>
              <MoreVertOutlined
                fontSize="small"
                className={`cursor-pointer hover:text-[#0049EE] ml-4`}
              />
            </Popover>
          </div>
        </div>
        <div className="flex h-full">

          <div className="flex-1 h-full overflow-y-scroll border-solid border-[#E3E3E8] border-t-0 border-r-0 border-b-0 no-scrollbar">
            <DocViewer
              style={{ width: "100%", height: "100%" }}
              pluginRenderers={DocViewerRenderers}
              documents={[{ uri: fileUrl, fileType: document?.ext }]} />
            <div className="h-[150px] w-full" />
          </div>
        </div>

      </div>
    )

      

  )





}

export default DocumentViewer
