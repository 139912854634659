import CustomButton from 'components/atoms/CustomButton'
import ModalUser from 'components/molecules/user/ModalUser'
import React from 'react'
import { useTranslation } from 'react-i18next'

const LogoutModal = ({ show, onLogout, closeModal }) => {
  const { t } = useTranslation()

  return (
    <ModalUser
      titleComponent={
        <div className="flex justify-start items-center">{t('LogoutModalTitle')}</div>
      }
      show={show}
      width={444}
      onClose={closeModal}
      closable={false}>
      <div className="flex justify-between flex-grow flex-col gap-4">
        <span className="text-[#595961]">{t('Are you sure you want to leave the platform?')}</span>
        {/* wrapper button */}
        <div className="flex flex-grow justify-end items-center gap-2">
          <CustomButton buttonColor="secondary" onClick={closeModal}>
            {t('Cancel')}
          </CustomButton>
          <CustomButton onClick={onLogout}> {t('Logout')}</CustomButton>
        </div>
      </div>
    </ModalUser>
  )
}

export default LogoutModal
