import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/thumbnail/lib/styles/index.css'
import '@react-pdf-viewer/bookmark/lib/styles/index.css'
import '@react-pdf-viewer/search/lib/styles/index.css'

import React, { useEffect, useState } from 'react'
import { Viewer, Worker, SpecialZoomLevel } from '@react-pdf-viewer/core'
import { searchPlugin } from '@react-pdf-viewer/search'
import { Dropdown, Typography } from 'antd'
import {
  AddCircleOutlineOutlined,
  Close,
  KeyboardArrowDown,
  KeyboardArrowUp,
  PostAdd,
  RemoveCircleOutlineOutlined,
  Search as SearchIcon
} from '@mui/icons-material'
import { zoomPlugin } from '@react-pdf-viewer/zoom'
import { getFilePlugin } from '@react-pdf-viewer/get-file'
import { useTranslation } from 'react-i18next'
import { NOTE_TYPE } from 'constant'

const DocumentComparison = ({
  fileUrl,
  onAddNote,
  openShowIndexDocument,
  pageNavigationInstance,
  showIndexDocument,
  document
}) => {
  const { t } = useTranslation()

  const [showSearch, setShowSearch] = useState(false)
  const searchPluginInstance = searchPlugin()
  const { Search } = searchPluginInstance
  const zoomPluginInstance = zoomPlugin()
  const { Zoom, CurrentScale, ZoomIn, ZoomOut } = zoomPluginInstance
  const { CurrentPageLabel } = pageNavigationInstance
  const getFilePluginInstance = getFilePlugin()
  const items = [
    {
      label: (
        <Zoom>
          {(renderZoomProps) => (
            <div onClick={() => renderZoomProps.onZoom(SpecialZoomLevel.ActualSize)}>
              <Typography.Text> {t('document.actualSize')}</Typography.Text>
            </div>
          )}
        </Zoom>
      ),
      key: 'actual_size'
    },
    {
      label: (
        <Zoom>
          {(renderZoomProps) => (
            <div onClick={() => renderZoomProps.onZoom(SpecialZoomLevel.PageFit)}>
              <Typography.Text> {t('document.pageFit')}t</Typography.Text>
            </div>
          )}
        </Zoom>
      ),
      key: 'page_fit'
    },
    {
      label: (
        <Zoom>
          {(renderZoomProps) => (
            <div onClick={() => renderZoomProps.onZoom(SpecialZoomLevel.PageWidth)}>
              <Typography.Text> {t('document.pageWidth')}</Typography.Text>
            </div>
          )}
        </Zoom>
      ),
      key: 'page_width'
    },
    {
      type: 'divider'
    },
    {
      label: (
        <Zoom>
          {(renderZoomProps) => (
            <div onClick={() => renderZoomProps.onZoom(0.1)}>
              <Typography.Text>10%</Typography.Text>
            </div>
          )}
        </Zoom>
      ),
      key: '0.1'
    },
    {
      label: (
        <Zoom>
          {(renderZoomProps) => (
            <div onClick={() => renderZoomProps.onZoom(0.3)}>
              <Typography.Text>30%</Typography.Text>
            </div>
          )}
        </Zoom>
      ),
      key: '0.3'
    },
    {
      label: (
        <Zoom>
          {(renderZoomProps) => (
            <div onClick={() => renderZoomProps.onZoom(0.5)}>
              <Typography.Text>50%</Typography.Text>
            </div>
          )}
        </Zoom>
      ),
      key: '0.5'
    },
    {
      label: (
        <Zoom>
          {(renderZoomProps) => (
            <div onClick={() => renderZoomProps.onZoom(0.8)}>
              <Typography.Text>80%</Typography.Text>
            </div>
          )}
        </Zoom>
      ),
      key: '0.8'
    },
    {
      label: (
        <Zoom>
          {(renderZoomProps) => (
            <div onClick={() => renderZoomProps.onZoom(1.0)}>
              <Typography.Text>100%</Typography.Text>
            </div>
          )}
        </Zoom>
      ),
      key: '1'
    },
    {
      label: (
        <Zoom>
          {(renderZoomProps) => (
            <div onClick={() => renderZoomProps.onZoom(1.5)}>
              <Typography.Text>150%</Typography.Text>
            </div>
          )}
        </Zoom>
      ),
      key: '1.5'
    },
    {
      label: (
        <Zoom>
          {(renderZoomProps) => (
            <div onClick={() => renderZoomProps.onZoom(2.0)}>
              <Typography.Text>200%</Typography.Text>
            </div>
          )}
        </Zoom>
      ),
      key: '2'
    }
  ]
  return (
    <div className="relative flex-1 h-[calc(100vh-170px)]">
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js">
        <div className="overflow-hidden flex-1 h-full ">
          {/* Document Wrapper */}
          <div className="flex-1 h-full overflow-y-scroll">
            <Viewer
              enableSmoothScroll={true}
              defaultScale={1.0}
              fileUrl={fileUrl}
              plugins={[
                searchPluginInstance,
                zoomPluginInstance,
                pageNavigationInstance,
                getFilePluginInstance
              ]}
            />
          </div>
          {/* Bottom Features */}
          <div className="absolute bottom-0 left-0 right-0 flex items-end bg-transparen pb-4 pt-2 bg-white" style={{
            borderTop: '1px solid #E3E3E8'
          }}>
            <div className="flex-1 flex flex-col">
              {showSearch && (
                <Search>
                  {(renderSearchProps) => {
                    const [readyToSearch, setReadyToSearch] = useState(false)
                    const [keyword, setKeyword] = useState('')
                    useEffect(() => {
                      if (renderSearchProps.keyword !== '') {
                        renderSearchProps.search()
                      }
                    }, [renderSearchProps.keyword])
                    return (
                      <div className="mx-4 flex items-center rounded-xl border border-solid border-[#E3E3E8] w-[350px] mb-2 self-end justify-between px-2 bg-white">
                        <div className="flex justify-between flex-1 backdrop-blur">
                          <input
                            className="outline-none border-none flex-1 bg-transparent"
                            placeholder="Enter to search"
                            type="text"
                            value={renderSearchProps.keyword}
                            onChange={(e) => {
                              e.target.value.length > 3
                              {
                                renderSearchProps.setKeyword(e.target.value)
                                setKeyword(e.target.value)
                                if (e.target.value === '') {
                                  setReadyToSearch(false)
                                } else {
                                  setReadyToSearch(true)
                                }
                              }
                            }}
                            onKeyDown={(e) => {
                              if (
                                e.keyCode === 13 &&
                                renderSearchProps.keyword &&
                                renderSearchProps.keyword !== keyword
                              ) {
                                setReadyToSearch(true)
                                renderSearchProps.search()
                              } else {
                                setReadyToSearch(true)
                                renderSearchProps.jumpToNextMatch()
                              }
                            }}
                          />
                          <div className="mx-2">
                            {readyToSearch &&
                              renderSearchProps.keyword &&
                              renderSearchProps.numberOfMatches === 0 && <span>Not Found</span>}
                            {readyToSearch &&
                              renderSearchProps.keyword &&
                              renderSearchProps.numberOfMatches > 0 && (
                                <span>
                                  {renderSearchProps.currentMatch} /{' '}
                                  {renderSearchProps.numberOfMatches}
                                </span>
                              )}
                          </div>
                        </div>
                        <div className="border border-solid border-[#E3E3E8] border-r-0 border-y-0 flex justify-end self-end py-2 items-center">
                          <div className="flex items-center mr-5">
                            <div
                              className="group group-hover:text-[#0049EE] hover:cursor-pointer ml-2"
                              onClick={() => renderSearchProps.jumpToNextMatch()}>
                              <KeyboardArrowDown className="hover:text-[#0049EE] hover:cursor-pointer" />
                            </div>
                            <div
                              className="group group-hover:text-[#0049EE] hover:cursor-pointer ml-2"
                              onClick={() => renderSearchProps.jumpToPreviousMatch()}>
                              <KeyboardArrowUp className="hover:text-[#0049EE] hover:cursor-pointer" />
                            </div>
                          </div>
                          <Close
                            className="hover:cursor-pointer hover:text-[#0049EE]"
                            onClick={() => setShowSearch((prev) => !prev)}
                          />
                        </div>
                      </div>
                    )
                  }}
                </Search>
              )}
              <div className="mx-4 flex justify-between items-center px-4 py-3 rounded-xl border border-solid border-[#E3E3E8] backdrop-blur">
                <div
                  onClick={openShowIndexDocument}
                  className={`rounded-lg items-center justify-center hover:cursor-pointer `}>
                  <Typography.Text
                    className={`${
                      showIndexDocument
                        ? 'text-[#0049EE] hover:text-black'
                        : 'text-black hover:text-[#0049EE]'
                    } font-semibold `}>
                    {t('document.index')}
                  </Typography.Text>
                </div>
                <div className=" flex items-center justify-center">
                  <div className="mr-4">
                    <CurrentPageLabel>
                      {(renderCurrentPageLabelProps) => (
                        <span>
                          <span>{renderCurrentPageLabelProps.currentPage + 1}</span>
                          <span className="mx-2">/</span>
                          <span>{renderCurrentPageLabelProps.numberOfPages}</span>
                        </span>
                      )}
                    </CurrentPageLabel>
                  </div>

                  <ZoomIn>
                    {(renderZoomInProps) => (
                      <div
                        onClick={renderZoomInProps.onClick}
                        className="mx-2 hover:cursor-pointer">
                        <AddCircleOutlineOutlined className="hover:cursor-pointer hover:text-[#0049EE]" />
                      </div>
                    )}
                  </ZoomIn>
                  <div className="flex items-center">
                    <Dropdown
                      menu={{ items }}
                      trigger={['click']}
                      placement="top"
                      arrow={{
                        pointAtCenter: true
                      }}>
                      <div
                        className="hover:cursor-pointer hover:bg-[#ededef] flex items-center justify-between pl-2 pr-1 py-1 rounded-md"
                        onClick={(e) => e.preventDefault()}>
                        <CurrentScale />
                        <KeyboardArrowDown className="self-end text-end" />
                      </div>
                    </Dropdown>
                  </div>
                  <ZoomOut>
                    {(renderZoomOutProps) => (
                      <div
                        onClick={renderZoomOutProps.onClick}
                        className="mx-2 hover:cursor-pointer">
                        <RemoveCircleOutlineOutlined className="hover:cursor-pointer hover:text-[#0049EE]" />
                      </div>
                    )}
                  </ZoomOut>
                </div>
                <div className=" flex self-end justify-end gap-2">
                  <div
                    className="hover:cursor-pointer"
                    onClick={() => setShowSearch((prev) => !prev)}>
                    <SearchIcon
                      className={`hover:cursor-pointer hover:text-[#0049EE] ${
                        showSearch && 'text-[#0049EE]'
                      }`}
                    />
                  </div>
                  <div
                    className="hover:cursor-pointer"
                    onClick={() => onAddNote({ type: NOTE_TYPE.DOCUMENT, document, edit: true })}>
                    <PostAdd className="hover:cursor-pointer hover:text-[#0049EE]" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Worker>
    </div>
  )
}

export default DocumentComparison
