import { getDocumentListNewChat } from 'api'
import useAuth from 'hooks/useAuth'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { errorObj } from 'utils/handleError'
import { showErrorMessage } from 'utils/showMessage'

const INITIAL_FILTER_NEW_CHAT = {
  eligibility: { value: null },
  date: { value: null }
}
const useCreateNewProcessedDocument = (
  currentContent,
  isContentSpecificCase,
  showDocumentModal,
  tableState
) => {
  const { getAccessToken } = useAuth()
  const { t } = useTranslation();
  // Filled Chat
  const [documentListByCase, setDocumentListByCase] = useState([])
  const [filterModal, setFilterModal] = useState(false)
  const [filterState, setFilterState] = useState(INITIAL_FILTER_NEW_CHAT)
  const [selectedDocuments, setSelectedDocuments] = useState(null)
  // Fetch Docs

  const { isFetching: isFetchingDocumentByCase, refetch: refetchingDocumentByCase } = useQuery({
    queryKey: [filterState, tableState],
    queryFn: () =>
      getDocumentListNewChat(getAccessToken(), currentContent.caseId, filterState, tableState),
    onSuccess: (data) => {
    
      setDocumentListByCase(data?.resData?.data.filter(el => el.ext === 'pdf'))
    },
    onError: (err) => {
      showErrorMessage(t('error messages.Failed fetch document list, Please refresh the page'))
    },
    enabled: isContentSpecificCase() && !showDocumentModal
  })

  const handleChangeSelectedDocuments = (options) => {
  

    if (options.selectedRows.length > 0) {
      setSelectedDocuments(options.selectedRows[0]);
    } else {
      setSelectedDocuments(null);
    }

    const selectedRowId = options.selectedRows[0]
    setSelectedDocuments(selectedRowId);
  }
  function openFilterModal() {
    setFilterModal(true)
  }
  function closeFilterModal() {
    setFilterModal(false)
  }
  function handleSubmitFilter(data) {
    setFilterState((prev) => ({ ...prev, ...data }))
    closeFilterModal()
  }
  function handleResetFilterState() {
    setFilterState(INITIAL_FILTER_NEW_CHAT)
    closeFilterModal()
  }
  function resetSelectedDocument() {
    setSelectedDocuments(null)
  }

  return {
    documentListByCase,
    selectedDocuments,
    isFetchingDocumentByCase,
    refetchingDocumentByCase,
    filterModal,
    filterState,
    openFilterModal,
    closeFilterModal,
    handleSubmitFilter,
    handleResetFilterState,
    handleChangeSelectedDocuments,
    resetSelectedDocument
  }
}

export default useCreateNewProcessedDocument
