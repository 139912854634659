import React, { useState, useRef, useEffect } from 'react'
import { Typography } from 'antd'
import { useNavigate, useOutletContext } from 'react-router-dom'
import RegulationQueryContainer from 'components/organisms/users/regulation/RegulationQueryContainer'
import RegulationChatContainer from 'components/organisms/users/regulation/RegulationChatContainer'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import {
  getRegulationChat,
  getRegulations,
  postRegulationsChat,
  postRegenerateMessageChat,
  deleteChatRegulation
} from 'api'
import RegulationExportDocument from 'components/organisms/users/regulation/RegulationExportDocument'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'hooks'
const initialListState = {
  total: 0,
  page: 1,
  limit: 20,
  search: ''
}
const RegulationPage = () => {
  const { t } = useTranslation();
  const { onLogout } = useAuth()
  const [layoutInfo] = useOutletContext()
  const [activeData, setActiveData] = useState({})
  const [regulationsList, setRegulationsList] = useState([])
  const [regulationListState, setRegulationListState] = useState(initialListState)
  const [detailChat, setDetailChat] = useState([])
  const [newChatModal, setNewChatModal] = useState(false)
  const token = useSelector((state) => state.user.access_token)
  const queryClient = useQueryClient()
  const openExportDocument = () => {
    queryClient.invalidateQueries(['getRegulationExportFile'])
    setNewChatModal(true)
  }
  const closeExportDocument = () => {
    setNewChatModal(false)
  }

  useQuery({
    queryKey: ['regulations-list'],
    refetchOnWindowFocus: false,
    queryFn: () => getRegulations(token, regulationListState),
    onSuccess: (data) => {


      // setRegulationsList(data.resData.data)
      const newData = data.resData.data.map((data, idx) => {
        if (idx === 0) {
          return { ...data, active: true }
        }
        return data
      })

      setRegulationsList(newData)
      // set active data
      setActiveData(newData[0])
    },
    
  })

  useQuery({
    queryKey: ['getRegulationChat', activeData],
    queryFn: () => activeData && getRegulationChat(token, activeData.id),
    onSuccess: (res) => {
      setDetailChat(res.resData?.data)
    },
    onError: (err) => {
      
      if(err.response.status === 403){
        onLogout()
      }
    },
  })

  const postChatMessageMutation = useMutation({
    mutationKey: 'postRegulationsChat',
    mutationFn: (data) => postRegulationsChat(token, activeData.id, data),
    onSuccess: (res) => {
      setDetailChat(res.resData?.data?.messages)
    }
  })

  const postRegenerateMessageMutation = useMutation({
    mutationKey: 'postRegenerateMessageChatRegulation',
    mutationFn: () => postRegenerateMessageChat(token, activeData.id),
    onSuccess: (res) => {
      setDetailChat(res.resData?.data?.messages)
    }
  })

  const handleSubmitChat = (data) => {
    const newMessages = detailChat ?? []
    newMessages.push({ role: 'user', content: data?.message, choices: null })
    newMessages.push({ role: 'assistant', content: '', choices: null })
    setDetailChat(newMessages)
    postChatMessageMutation.mutate(data.message)
  }

  const handleRegenerateMessage = () => {
    postRegenerateMessageMutation.mutate()
  }
  const deleteChatHistory = useMutation({
    mutationKey: 'deleteChatHistory',
    mutationFn: (chatId) => deleteChatRegulation(token, chatId),
    onSuccess: () => {
      setDetailChat([])
    }
  })
  const handleDeleteChat = () => {
    deleteChatHistory.mutate(activeData.id)
    queryClient.invalidateQueries(['getRegulationChat'])
  }
  return (
    <div>
      <div className="grid grid-cols-12 w-full h-full">
        <RegulationQueryContainer
          height={layoutInfo}
          data={regulationsList}
          setData={setRegulationsList}
          setActiveData={setActiveData}
          onExport={openExportDocument}
          onDelete={handleDeleteChat}
        />

        <div className="grid col-span-9 grid-cols-12 rounded-3xl border border-solid border-[#E3E3E8] bg-white">
          <div className="col-span-12 pt-8 w-full h-full">
            <div className="flex flex-col gap-2 pl-8 pb-6 border-0 border-solid border-b-2 border-[#E3E3E8]">
              <Typography.Text className="text-black font-normal text-[14px]">
                {t('InterrogationQuery')}
              </Typography.Text>
              <Typography.Text className="text-black font-semibold text-[30px]">
                {t(activeData?.title)}
              </Typography.Text>
            </div>
            <div className="flex px-5 max-h-[calc(100vh-160px)] h-full justify-between flex-col items-center">
              <RegulationChatContainer
                onRegenerateMessage={handleRegenerateMessage}
                chatHistory={detailChat ? detailChat : []}
                onSubmit={handleSubmitChat}
                isLoading={
                  postChatMessageMutation.isLoading || postRegenerateMessageMutation.isLoading
                }
              />
            </div>
          </div>
        </div>

        <RegulationExportDocument
          show={newChatModal}
          closeModal={closeExportDocument}
          id={activeData.id}
        />
      </div>
    </div>
  )
}

export default RegulationPage
