import React, { forwardRef } from 'react'
import ChatHistoryList from './ChatHistoryList'
import InputChat from './InputChat'

const ChatContainer = (
  { isLoading, onRegenerateMessage, chatHistory, onSubmit, onAddNote = () => {} },
  ref
) => {
  return (
    <>
      {/* Chat Wrapper Scroll */}
      <div
        ref={ref}
        className="pt-5 flex flex-col flex-auto w-full h-full max-h-[calc(100vh-240px)] gap-6 overflow-y-auto">
        <ChatHistoryList
          onAddNote={onAddNote}
          isLoading={isLoading}
          onRegenerateMessage={onRegenerateMessage}
          chatHistory={chatHistory}
        />
      </div>

      {/* Input Chat */}
      <div className="w-full">
        <InputChat onSubmit={onSubmit} isLoading={isLoading} />
      </div>
    </>
  )
}

export default forwardRef(ChatContainer)
