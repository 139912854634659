import { apiDelete } from 'api/api-delete'
import { apiGet } from 'api/api-get'
import { apiPost } from 'api/api-post'
import { apiPut } from 'api/api-put'
import { urls } from 'api/api-url'
import moment from 'moment'

export const getUserList = (token, page, filterState, tableState) => {
  let urlParam = ''
  if (tableState.search) urlParam += `&search=${tableState.search}`

  if (filterState.company) urlParam += `&company_id=${filterState.company.value}`

  if (filterState.typeDate) urlParam += `&filter_date_type=${filterState.typeDate}`

  if (filterState.startDate)
    urlParam += `&first_date=${moment(filterState.startDate).format('DD/MM/YYYY')}`

  if (filterState.endDate)
    urlParam += `&second_date=${moment(filterState.endDate).format('DD/MM/YYYY')}`
  if (filterState.role?.length > 0) {
    filterState.role.map((role) => (urlParam += `&role=${role}`))
  }

  let limit = 9
  if (tableState?.limit && tableState?.limit === 'all') {
    limit = ''
  }

  return apiGet({
    url: `${urls.user.users}?page=${page}&limit=${limit}&sort=${tableState.sort}${urlParam}`,
    token: token
  })
}

export const postCreateUser = (token, userData) => {
  return apiPost({
    url: `${urls.user.users}`,
    token: token,
    data: {
      name: userData?.name,
      surname: userData?.surname,
      email: userData?.email,
      phone: userData?.phoneNumber,
      company_id: userData?.company,
      role: userData?.role
    }
  })
}

export const getUserDetailById = (token, userId) => {
  return apiGet({ url: `${urls.user.users}/${userId}`, token: token })
}
export const deleteUserById = (token, userId) => {
  return apiDelete({ url: `${urls.user.users}/${userId}`, token: token })
}
export const putRetrieveUserById = (token, userId) => {
  return apiPut({ url: `${urls.user.users}/${userId}/${urls.user.retrieve}`, token: token })
}
export const putPairUserCompany = (token, userId, companyId, role) => {
  return apiPut({
    url: `${urls.user.users}/${userId}/${urls.user.pairCompany}`,
    token: token,
    data: {
      company_id: companyId,
      role: role
    }
  })
}
export const putUpdateUserDetail = (token, userId, userData) => {
  return apiPut({
    url: `${urls.user.users}/${userId}`,
    token: token,
    data: {
      name: userData.name,
      surname: userData.surname,
      email: userData.email,
      phone: userData.phoneNumber
    }
  })
}
