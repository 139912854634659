import React from 'react'
import RegulationChatHistoryList from './RegulationChatHistoryList'
import RegulationChatInput from './RegulationChatInput'
const RegulationChatContainer = ({ isLoading, onRegenerateMessage, chatHistory, onSubmit }) => {
  return (
    <>
      <div className=" flex flex-col flex-auto w-full h-full max-h-[calc(100vh-240px)] gap-6 overflow-y-auto">
        <RegulationChatHistoryList
          isLoading={isLoading}
          onRegenerateMessage={onRegenerateMessage}
          chatHistory={chatHistory}
        />
      </div>

      <div className="w-full">
        <RegulationChatInput onSubmit={onSubmit} isLoading={isLoading} />
      </div>
    </>
  )
}

export default RegulationChatContainer
