import { AutoFixNormalOutlined, InfoOutlined } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import { IcSend } from 'assets/icons'

import { CONST_QUESTION, CONST_SHORTCUT } from 'constant'
import useTCPP from 'hooks/useTCPP'
import React, { useEffect, useRef, useState } from 'react'
import TCModal from '../TCModal'
import { useAuth } from 'hooks'
import { useTranslation } from 'react-i18next'
import TCModalProfile from '../profile/TCModalProfile'
const INITIAL_STATE = {
  type: CONST_QUESTION,
  message: ''
}
const shortcutSummary = 'summary'
const shortcutSpecifications = 'specifications'
const shortcutTimeline = 'timeline'
const shortcutRegulation = 'regulation'
const shortcutKeyword = 'keyword'
const shortcutChat = [
  {
    titleButton: 'Riassunto',
    tooltipTitle: "Breve esposizione dei contenuti principali del documento",
    value: shortcutSummary
  },
  {
    titleButton: 'Specifiche',
    tooltipTitle:
      'Estrazione dei dati tecnici più rilevanti del documento (cliente, reato, giudice, etc...)',
    value: shortcutSpecifications
  },
  {
    titleButton: 'Cronologia',
    tooltipTitle: 'Estrazione ordinata di tutte le date presenti nel documento',
    value: shortcutTimeline
  },
  {
    titleButton: 'Normativa',
    tooltipTitle: 'Estrazione di tutti gli articoli penali/civili presenti nel documento',
    value: shortcutRegulation
  },
  {
    titleButton: 'Keyword',
    tooltipTitle: 'Individuazione di un set di keywords rilevanti all’interno del documento',
    value: shortcutKeyword
  }
]
const InputChat = ({ onSubmit = () => { }, isLoading }) => {
  const { t } = useTranslation();

  const { onLogout } = useAuth()
  const { handleSubmitTcModal, tcModal, acceptTC, isPPFetching, isTCFetching, openTcModal, closeTcModal } =
    useTCPP()
  const [showShortcut, setShowShortcut] = useState(false)
  function toggleShortcut() {
    setShowShortcut((prev) => !prev)
  }
  const [keyword, setKeyword] = useState(INITIAL_STATE)
  const [isFocused, setIsFocused] = useState(false);

  const isFocus = useRef(false)
  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === 'Enter' && isFocus.current) {
        event.preventDefault()
        onSubmit(keyword)
        setKeyword(INITIAL_STATE)
      }
    }

    document.addEventListener('keydown', keyDownHandler)

    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [keyword, onSubmit])

  function handleChangeKeyword(value) {
    setKeyword((prev) => ({ ...prev, message: value }))
  }
  function handleShortcut(value) {
    onSubmit({ type: CONST_SHORTCUT, message: value })
  }

  return (
    <>
      {/* Input Wrapper */}
      <div
        className={`${isFocused ? 'border-neutral-600' : 'border-neutral-300'} nt-input-container flex gap-1 relative overflow-hidden px-3 flex-col border border-solid border-neutral-300 bg-white rounded-lg`}>
        {/* shortcut Wrapper */}
        <div
          style={{ overflowX: "auto", overflowY: "hidden" }}
          className={`h-14 border-solid border-0 border-b border-[#E3E3E8] py-4 w-full gap-3 duration-300 ease-in-out ${showShortcut ? 'flex' : 'hidden'
            }`}>
          {shortcutChat.map((shortcut, index) => (
            <div

              className="h-7 flex justify-center group items-center gap-2 p-3 border border-solid border-[#828282] rounded bg-[#F7F7F8] hover:border-[#0049EE] hover:bg-[#EBF1FF] cursor-pointer"
              key={`shortcut-${index}-button`}
              onClick={handleShortcut.bind(this, shortcut.value)}>
              <span className="font-semibold text-xs text-[#595961] group-hover:text-[#0049EE]">
                {shortcut.titleButton}
              </span>
              <Tooltip title={shortcut.tooltipTitle} arrow>
                <InfoOutlined
                  className="text-[#595961] group-hover:text-[#0049EE]"
                  fontSize="12px"
                />
              </Tooltip>
            </div>
          ))}
        </div>
        {/* Input Field Wrapper */}
        <div className="w-full flex gap-2">
          {/* Show Shortcut */}

          
          {/* <div className="flex justify-center items-center">
            <div
              className={`${showShortcut ? 'bg-[#0049EE] hover:bg-[#EDEDEF]' : 'bg-[#EDEDEF] hover:bg-[#0049EE]'
                } h-9 w-9 p-2 rounded flex justify-center items-center cursor-pointer group `}
              onClick={toggleShortcut}>
              <AutoFixNormalOutlined
                className={`${showShortcut
                  ? 'text-white group-hover:text-[#828282]'
                  : 'text-[#828282] group-hover:text-white'
                  }`}
              />
            </div>
          </div> */}


          {/* Input */}
          <input
            id='chatInput'
            autoFocus
            disabled={isLoading}
            onFocus={() => {
              setIsFocused(true); // Aggiorna lo stato a true quando è in focus
              isFocus.current = true;
            }}
            onBlur={() => {
              setIsFocused(false); // Aggiorna lo stato a false quando perde il focus
              isFocus.current = false;
            }}
            value={keyword.message}

            onChange={(e) => {
              e.preventDefault()
              handleChangeKeyword(e.target.value)
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && keyword.message.length === 0) {
                e.stopPropagation()
                e.preventDefault();
              }
            }}
            className={`${isFocused && "focused"} w-full h-14 rounded-lg border-none text-neutral-700 text-sm disabled:bg-white pl-2 focus:outline-none`}
            placeholder={t("Scrivi un messaggio a LegalAi")}
            type="text"
          />
          {/* Button Wrapper */}
          <div className="flex justify-center items-center">
          
            <div
              className={`${keyword.message.length > 0 ? "bg-[#0049EE]" : "bg-[#EBF1FF]"}  h-9 w-9 p-2 rounded flex justify-center items-center cursor-pointer hover:bg-[#2e5fd1]`}
              onClick={() => {
                if (!isLoading && keyword.message.length > 0) {
                  onSubmit(keyword)
                  setKeyword(INITIAL_STATE)
                }
              }}>
                
                <IcSend color={keyword.message.length > 0 ? 'white' : '#0049EE'} className={'h-5'} />
            </div>
          </div>
        </div>
      </div>
      <div className="text-[11px] flex justify-center items-center pt-1 text-[#828282] text-center w-full">
        LegalAI può commettere errori, quindi verifica le sue risposte.{' '}
        <span className="underline cursor-pointer" onClick={openTcModal}>
          La tua privacy policy
        </span>
      </div>
      {tcModal.show && (
        <TCModalProfile
          isFetching={isPPFetching || isTCFetching}
          isLoading={acceptTC.isLoading}
          pp={tcModal.pp}
          tc={tcModal.tc}
          show={tcModal.show}
          onClose={closeTcModal}
        />
      )}
    </>
  )
}

export default InputChat
