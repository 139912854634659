import moment from 'moment'

export const getNext5Minute = () => {
  let now = moment()

  let minutes = now.minutes()

  let remainder = minutes % 5

  if (remainder < 1) {
    now.add(5 - remainder, 'minutes')
  } else {
    let roundedMinutes = minutes + (5 - remainder)
    now.minutes(roundedMinutes)
  }

  now.seconds(0)
  now.milliseconds(0)

  return now
}
