import React from 'react'
import { z } from 'zod'
import TextFields from 'components/molecules/TextFields'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import CustomButton from 'components/atoms/CustomButton'
import { useMutation } from 'react-query'
import { putUpdateEmail } from 'api'
import { errorObj } from 'utils/handleError'
import useAuth from 'hooks/useAuth'
import { useTranslation } from 'react-i18next'

import { showErrorMessage, showSuccessMessage } from 'utils/showMessage'
const schema = z.object({
  email: z.string().email({ message: 'Invalid email address' })
})
const ChangeEmailProfileForm = () => {
  const { t } = useTranslation();

  const { getAccessToken, handleSetEmailAddress, adminData } = useAuth()
  const {
    handleSubmit,
    formState: { errors, isDirty, isValid },
    control
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: adminData?.profile?.email ? adminData?.profile?.email : ''
    },
    values: {
      email: adminData?.profile?.email ? adminData?.profile?.email : ''
    },
    resolver: zodResolver(schema)
  })

  const putChangeEmailMutation = useMutation({
    mutationKey: 'putChangeEmail',
    mutationFn: (email) => putUpdateEmail(getAccessToken(), email),
    onSuccess: (res) => {
      handleSetEmailAddress(res.resData.data?.email, res.resData.data?.updated_at)
      showSuccessMessage(t('success messages.Email changes saved'))
    },
    onError: (err) => {
      const errObj = errorObj(err)
      showErrorMessage(t('error messages.Email was not possible to save the changes'))
    }
  })

  const onSubmit = (data) => {
    putChangeEmailMutation.mutate(data.email)
  }
  return (
    <div className="flex flex-col gap-6 max-w-80">
      <TextFields
        theme="admin"
        label={'Email'}
        control={control}
        autoComplete="off"
        name={'email'}
        variant="filled"
        errors={errors}
      />
      <CustomButton
        className={'!max-w-40'}
        disabled={!isDirty || !isValid}
        onClick={handleSubmit(onSubmit)}
        buttonColor="primary">
        {t('Save Changes')}
      </CustomButton>
    </div>
  )
}

export default ChangeEmailProfileForm
