import { Box, Button, CircularProgress } from '@mui/material'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import TextFields from 'components/molecules/TextFields'
import PasswordFields from 'components/molecules/PasswordFields'
import ErrorMessage from 'components/atoms/ErrorMessage'
import { NavLink, useNavigate } from 'react-router-dom'
import { isAdminPage } from 'utils/isAdminPage'
import { LoginFormSchema } from 'lib/schema'
import { useMutation, useQuery } from 'react-query'
import { getUserProfile, postLoginAdmin, postLoginPlatform } from 'api'
import { errorObj } from 'utils/handleError'
import useAuth from 'hooks/useAuth'
import { showErrorMessage } from 'utils/showMessage'
import useNavigatorOnLine from 'hooks/useNavigatorOnline'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

export const LoginForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const { onLogin, handleSetProfile } = useAuth()
  const statusConnection = useNavigatorOnLine()
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    setError
  } = useForm({
    defaultValues: {
      email: '',
      password: ''
    },
    resolver: zodResolver(LoginFormSchema)
  })

  const { refetch } = useQuery({
    queryKey: 'getProfile',
    queryFn: () => {
      let token = localStorage.getItem('token')
      return getUserProfile(token)
    },
    onSuccess: (res) => {
      let token = localStorage.getItem('token')
      localStorage.removeItem('token')
      handleSetProfile(res?.resData?.data, res?.resData?.data?.role)
      onLogin(token, isAdminPage())
      if (isAdminPage()) {
        return navigate('/admin/companies')
      } else {
        return navigate('/cases')
      }
    },
    onError: (err) => {
      const obj = errorObj(err)
      showErrorMessage(obj.resData.errors[0].message)
    },
    enabled: false
  })

  const loginMutation = useMutation({
    mutationKey: 'PostLogin',
    mutationFn: (data) => (isAdminPage() ? postLoginAdmin(data) : postLoginPlatform(data)),
    onSuccess: (res) => {
      localStorage.setItem('token', res?.resData?.data?.access_token)
      refetch()
    },
    onError: (err) => {
      const obj = errorObj(err)

      if (isAdminPage()) {
        if (obj.resStatus === 403) {
          showErrorMessage(t('error messages.Unauthorized! your account cannot access Back Office'))
        }
      }
      if (obj.resStatus === 429) {
        showErrorMessage(t('error messages.Account temporarily locked, try again in 15 minutes'))
      } else if (
        obj.resStatus === 400 &&
        obj.resData.errors[0].message == 'This account is deleted'
      ) {
        showErrorMessage(t('error messages.Deleted account'))
      } else if (obj.resStatus === 400 && obj.resData.errors[0].message == 'Invalid Credential') {
        setError('root', { message: 'The credentials entered are not valid. Please try again.' })
      } else {
        showErrorMessage(obj.resData.errors[0].message)
      }
    }
  })
  const processForm = async (data) => {
    if (!data.email && !data.password)
      return showErrorMessage('Fill in all mandatory fields to continue')
    if (!statusConnection) return showErrorMessage('Internet connection error')
    loginMutation.mutate(data)
  }
  return (
    <>
      <Box
        noValidate
        component="form"
        onSubmit={handleSubmit(processForm)}
        className="flex flex-col  w-full">
        {/* Form */}
        <div className="flex flex-col gap-4 justify-center items-center">
          <TextFields
            inputStyles={{
              '& .MuiInput-underline:before': {
                borderBottomColor: 'rgba(0, 0, 0, 0.12)',
              },
              '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                borderBottomColor: '#0049EE',
              },
              '& .MuiInput-underline:after': {
                borderBottomColor: '#0049EE',
              },
            }}
            control={control} label={t('Email')} name={'email'} errors={errors} />
          <PasswordFields
            inputStyles={{
              '& .MuiInput-underline:before': {
                borderBottomColor: 'rgba(0, 0, 0, 0.12)',
              },
              '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                borderBottomColor: '#0049EE',
              },
              '& .MuiInput-underline:after': {
                borderBottomColor: '#0049EE',
              },
            }}
            control={control} label={'Password'} name={'password'} errors={errors} />

          <div className="w-full">
            {errors['root'] ? (
              <ErrorMessage className={'mt-2'} errorMessage={errors['root'].message} />
            ) : null}
          </div>
          <div className="flex w-full ">
            <NavLink
              className={'no-underline text-[#828282]  text-helper'}
              to={!isAdminPage() ? '/reset-password' : '/admin/reset-password'}>
              {t("auth.paswforgot")}
            </NavLink>
          </div>
        </div>

        {/* Submit Button */}
        <Button
          disabled={isSubmitting || loginMutation.isLoading}
          type="submit"
          fullWidth
          variant="contained"
          sx={{
            py: 1,
            mt: 4,
            backgroundColor: '#0049EE',
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '25.6px'
          }}>
          {loginMutation.isLoading ? <CircularProgress size={'1.75rem'} /> : 'Login'}
        </Button>
      </Box>
    </>
  )
}
