import CustomButton from 'components/atoms/CustomButton'
import ModalAdmin from 'components/molecules/admin/ModalAdmin'
import i18next from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'

const ResetPasswordUserModal = ({ show, onClose, onResetPassword = () => {}, isLoading }) => {
  const { t } = useTranslation();

  return (
    <ModalAdmin show={show} onClose={isLoading ? () => {} : onClose} title={t('Reset Password')}>
      <div className="flex flex-col gap-8">
        <span className="font-manrope text-base text-[#999999]">
          

          {t('resetPswtext')}
        </span>

        <div className="flex gap-2 justify-end items-center">
          <CustomButton buttonColor="secondary" disabled={isLoading} onClick={onClose}>
          {t('Cancel')}
          </CustomButton>
          <CustomButton isLoading={isLoading} disabled={isLoading} onClick={onResetPassword}>
          {t('sendEmail')}
           
          </CustomButton>
        </div>
      </div>
    </ModalAdmin>
  )
}

export default ResetPasswordUserModal
