import { useDispatch, useSelector } from 'react-redux'
import { adminActions, userActions } from 'store/reducers/auth'
import { isAdminPage } from 'utils/isAdminPage'
import { getUserProfile } from 'api'
import { useQuery } from 'react-query'

const useAuth = () => {
  const dispatch = useDispatch()
  const adminData = useSelector((state) => state.admin)
  const platformData = useSelector((state) => state.user)

  function onLogin(access_token, isAdmin) {
    const payload = {
      access_token: access_token
    }
    if (isAdmin) dispatch(adminActions.loginBackOffice(payload))
    else dispatch(userActions.loginPlatform(payload))
  }
  function onLogout() {
    return isAdminPage()
      ? dispatch(adminActions.logoutBackOffice())
      : dispatch(userActions.logoutPlatform())
  }
  function getAccessToken() {
    return isAdminPage() ? adminData.access_token : platformData.access_token
  }
  function getRoleUser() {
    return isAdminPage() ? adminData.role : platformData.role
  }
  function handleSetEmailAddress(email, updated_at) {
    return isAdminPage()
      ? dispatch(adminActions.setEmailAddress({ email: email, updated_at: updated_at }))
      : dispatch(userActions.setEmailAddress({ email: email, updated_at: updated_at }))
  }
  function handleAcceptTC() {
    dispatch(userActions.setAcceptTc())
  }
  function handleSetProfile(profile, role) {
    const payload = { role: role, profile: profile }
    return isAdminPage()
      ? dispatch(adminActions.setProfile(payload))
      : dispatch(userActions.setProfile(payload))
  }

  const { refetch: refetchProfile } = useQuery({
    queryKey: 'getProfile',
    queryFn: () => getUserProfile(getAccessToken()),
    onSuccess: (res) => {
      handleSetProfile(res?.resData?.data, res?.resData?.data?.role)
    },
    onError: () => {
      console.warn('Could not update your profile, please reload the page')
    },
    enabled: false,
    staleTime: Infinity
  })

  return {
    adminData,
    platformData,
    onLogout,
    onLogin,
    getAccessToken,
    getRoleUser,
    handleSetEmailAddress,
    handleSetProfile,
    handleAcceptTC,
    refetchProfile
  }
}

export default useAuth
