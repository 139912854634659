import { AddOutlined, FilterListOutlined } from '@mui/icons-material'
import SearchInput from 'components/atoms/SearchInput'
import GridListToggleButton from 'components/molecules/user/GridListToggleButton'
import { GRID_VIEW } from 'constant'
import { debounce } from 'lodash'
import React, { useEffect, useMemo } from 'react'
import useAuth from 'hooks/useAuth'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const CaseHeader = ({
  view = GRID_VIEW,
  onToggleView,
  onSearch = () => {},
  onAddCase,
  openFilterModal,
  totalFilterActive
}) => {
  const { platformData } = useAuth()
  const { t, i18n } = useTranslation();

  const handleSearch = (e) => {
    onSearch(e.target.value)
  }
  useEffect(() => {
    return () => {
      debouncedResults.cancel()
    }
  })
  const debouncedResults = useMemo(() => {
    return debounce(handleSearch, 300)
  }, [])
  return (
    <div className="flex justify-between items-center">
      {/* Case Title */}
      <div className="flex justify-center items-center gap-2">
        <span className="text-black font-normal text-2xl">{t("breadcrumb.cases")}</span>
        {platformData?.profile?.role === 'Admin' && (
           <AddOutlined
           className="cursor-pointer text-black hover:text-[#0049EE]"
           onClick={onAddCase}
         />
        )}
       
      </div>
      {/* Functional  */}
      <div className="flex justify-center items-center gap-4 pr-8">
        {/* Toggle Grid & List */}
        <GridListToggleButton view={view} onToggleView={onToggleView} />
        {/* Search Input */}
        <SearchInput variant="outlined" onSearch={debouncedResults} />
        {/* Filter Wrapper */}
        <div className="flex gap-1 justify-center items-center" onClick={openFilterModal}>
          <FilterListOutlined
            className={`cursor-pointer hover:text-[#0049EE] ${
              totalFilterActive > 0 && 'text-[#0049EE]'
            }`}
          />
          {/* Badge Total Count */}
          {totalFilterActive > 0 && (
            <div className="w-5 h-5 bg-[#0049EE] px-[6px] flex justify-center items-center rounded-full text-white">
              {totalFilterActive}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default CaseHeader
