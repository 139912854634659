import { apiDelete } from 'api/api-delete'
import { apiGet } from 'api/api-get'
import { apiPost } from 'api/api-post'
import { apiPut } from 'api/api-put'
import { urls } from 'api/api-url'
export * from './chat'
export const getExportNotes = (token, caseId) => {
  return apiGet({
    url: `${urls.notes.notes}/${caseId}/${urls.notes.export}`,
    token: token,
    isExportDocument: true
  })
}

export const deleteCaseById = (token, caseId) => {
  return apiDelete({
    url: `${urls.detailCase.cases}/${caseId}`,
    token: token
  })
}

export const getWorkspaceList = (token, data) => {
  let urlParam = '?search='
  if (data?.search) urlParam += `${data.search}`

  if (data?.filter_date_type) urlParam += `&filter_date_type=${data.filter_date_type}`

  if (data?.first_date) urlParam += `&first_date=${data.first_date}`

  if (data?.second_date) urlParam += `&second_date=${data.second_date}`

  if (data?.status) {
    data.status.forEach((item) => {
      urlParam += '&case_status_id=' + item
    })
  }

  if (data?.regulations) {
    data.regulations.forEach((item) => {
      urlParam += '&regulation_id=' + item
    })
  }

  return apiGet({
    url: `${urls.detailCase.cases}${urlParam}`,
    token: token
  })
}

export const createCase = (token, data) => {
  return apiPost({ url: urls.detailCase.cases, token: token, data: data })
}

export const updateCase = (token, data, id) => {
  return apiPut({ url: `${urls.detailCase.cases}/${id}`, token: token, data: data })
}
