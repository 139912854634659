export const urls = {
  auth: {
    login: 'login',
    forgotPassword: 'forgot-password',
    changePassword: 'change-password',
    confirmOtp: 'confirm-otp',
    resetPassword: 'reset-password'
  },
  user: {
    users: 'users',
    retrieve: 'retrieve',
    pairCompany: 'pair-company'
  },
  company: {
    companies: 'companies',
    validateGptKey: 'company/validate-gpt-key',
    validateEmail: 'company/validate-email',
    validatePhone: 'company/validate-phone',
    searchByName: 'company/search',
    countryList: 'countries/list'
  },
  documents: {
    documents: 'documents',
    uploadConfirm: 'upload/confirm'
  },
  chats: {
    chats: 'chats',
    regenerateMessage: 'regenerate-message',
    message: 'message',
    reference: 'reference'
  },
  appInformation: {
    appInformation: 'app-information'
  },
  termsConditions: {
    termsConditions: 'terms-and-conditions',
    termsConditonsByUser: 'term-and-condition-users',
    acceptTermsConditions: 'accept'
  },
  privacyPolicies: {
    privacyPolicies: 'privacy-policies'
  },
  profile: {
    profile: 'profile',
    me: 'me',
    avatar: 'avatar'
  },
  detailCase: {
    cases: 'cases',
    chatList: 'chats',
    statusList: 'case-statuses',
    documentList: 'documents',
    processedList: 'processed',
    notes: 'notes'
  },
  notes: {
    notes: 'notes',
    export: 'export'
  },
  regulations: {
    regulations: 'regulations',
    regulation: 'regulation',
    chats: 'chats'
  }
}
