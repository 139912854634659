import {
  ChatBubbleOutline,
  InsertDriveFileOutlined,
  MoreVertOutlined,
  StickyNote2Outlined
} from '@mui/icons-material'
import { Popover } from 'antd'
import { BgFolder } from 'assets/images'
import { getStatusColor } from 'lib/getStatusColor'
import React, { useState } from 'react'
import useAuth from 'hooks/useAuth'
import { ROLE_ADMIN, ROLE_USER } from 'constant'
import { useTranslation } from 'react-i18next'

const CaseCard = ({
  onEdit = () => {},
  onDeleteCase = () => {},
  onExport = () => {},
  onViewDetail = () => {},
  titleCase = 'Tumbarello',
  statusCase = 'Preliminary inquiries',
  totalNotes = 12,
  totalChat = 15,
  totalDocs = 10,
  date = '02/07/2023'
}) => {
  const [openPopover, setOpenPopover] = useState(false)
  const { platformData } = useAuth()
  const { t, i18n } = useTranslation()

  const optionsPopover = [
    {
      title: t('case.edit'),
      callback: onEdit,
      role: [ROLE_ADMIN]
    },
    {
      title: t('case.delete'),
      callback: onDeleteCase,
      role: [ROLE_ADMIN]
    },
    {
      title: t('case.exportNotes'),
      callback: onExport,
      role: [ROLE_ADMIN, ROLE_USER]
    }
  ]

  function handleOpenPopover() {
    setOpenPopover(true)
  }
  function handleClosePopover() {
    setOpenPopover(false)
  }
  function handleClickMenu(callback) {
    handleClosePopover()
    return callback()
  }

  return (
    <div
      id="card-case-container"
      className=" h-[146px] pt-[23px] bg-cover bg-no-repeat"
      style={{ backgroundImage: `url(${BgFolder})` }}>
      <div
        id="card-content"
        className="w-full cursor-pointer h-[146px] flex flex-col justify-between rounded-lg border border-solid border-[#E3E3E8] bg-white py-4 px-3">
        {/* Header */}
        <div className="flex justify-between items-start">
          {/* Case Color & Title Wrapper */}
          <div className="flex-1 flex gap-3 items-center" onClick={onViewDetail}>
            {/* Case Color */}
            <div
              className="w-1 h-12 rounded-lg bg-[#0049EE]"
              style={{ backgroundColor: `#${statusCase?.hex_code}` }}></div>
            {/* Title */}
            <div className="flex flex-col gap-2">
              <span className="font-bold text-lg text-black leading-4">{titleCase}</span>
              <span className="font-normal text-xs text-black">{statusCase?.name}</span>
            </div>
          </div>
          <Popover
            content={
              <div className="w-[158px] h-full flex flex-col text-black">
                {optionsPopover.map(
                  (option, index) =>
                    option.role.includes(platformData?.role?.toUpperCase()) && (
                      <div
                        key={index}
                        onClick={handleClickMenu.bind(this, option.callback)}
                        className="px-2 py-1 cursor-pointer hover:bg-[#0000000A] text-black rounded text-sm font-normal">
                        {option.title}
                      </div>
                    )
                )}
              </div>
            }
            open={openPopover}
            onOpenChange={handleClosePopover}
            trigger="click"
            placement="leftTop"
            arrow={false}>
            <MoreVertOutlined
              onClick={handleOpenPopover}
              className={`cursor-pointer hover:text-[#0049EE] ${openPopover && 'text-[#0049EE]'}`}
            />
          </Popover>
        </div>
        {/* Footer */}
        <div
          className="flex-1 flex justify-between items-end text-[#828282] font-normal text-xs"
          onClick={onViewDetail}>
          {/* Date */}
          <span>{date}</span>

          <div className="flex justify-center items-center gap-4">
            {/* Chat Count */}
            <div className="flex gap-1 justify-center items-center">
              <ChatBubbleOutline className="!w-4 !h-[18px]" />
              <span>{totalChat}</span>
            </div>
            {/* Document Count */}
            <div className="flex gap-1 justify-center items-center">
              <InsertDriveFileOutlined className="!w-4 !h-[18px]" />
              <span>{totalDocs}</span>
            </div>
            {/* Notes Count */}
            <div className="flex gap-1 justify-center items-center">
              <StickyNote2Outlined className="!w-4 !h-[18px]" />
              <span>{totalNotes}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CaseCard
