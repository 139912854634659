import React, { useState } from 'react'
import { Typography } from 'antd'
// import CustomButton from 'components/atoms/CustomButton'
import SearchInput from 'components/atoms/SearchInput'
import FilterButton from 'components/atoms/FilterButton'
import Table from 'components/atoms/Table'
import CompanyDetailHeader from 'components/organisms/admin/companies/CompanyDetailHeader'
import { useLocation, useNavigate } from 'react-router-dom'
import { showSuccessMessage } from 'utils/showMessage'
import ChangeGptKeyModal from 'components/organisms/admin/companies/ChangeGptKeyModal'
import CompanyDetailInfo from 'components/organisms/admin/companies/CompanyDetailInfo'
import CompanyGptKeyInfo from 'components/organisms/admin/companies/CompanyGptKeyInfo'
import AdminPagination from 'components/atoms/AdminPagination'
import { useQuery } from 'react-query'
import { getCompanyById, getCountryList, getUserList } from 'api'
import NullComponent from 'components/molecules/NullComponent'
import FilterPairUserCompany from 'components/organisms/admin/companies/FilterPairUserCompany'
import { useSelector } from 'react-redux'
import moment from 'moment'
import CustomButton from 'components/atoms/CustomButton'
import AddUserModal from 'components/organisms/admin/users/AddUserModal'
import { useTranslation } from 'react-i18next'
const initialTableState = {
  total: 0,
  page: 1,
  limit: 9,
  search: '',
  sort: 'created_at',
}

const initialFilterState = {
  role: undefined,
  typeDate: undefined,
  startDate: undefined,
  endDate: undefined
}

const CompanyDetailPage = () => {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation();
  const location = useLocation()
  const [tableState, setTableState] = useState(initialTableState)
  const [filter, setFilter] = useState({ show: false })
  const [filterState, setFilterState] = useState(initialFilterState)
  const [pairUserModal, setPairUserModal] = useState(false)
  const [showGptKeyModal, setShowGptKeyModal] = useState(false)
  const token = useSelector((state) => state.admin.access_token)

  const detail = useQuery({
    queryKey: ['admin-company-detail', location?.state?.id],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await getCompanyById(token, location?.state?.id)
      return res
    },
  })

  const countries = useQuery({
    queryKey: ['countries-list'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await getCountryList(token)
      return res
    }
  })

  const { isLoading, data, refetch } = useQuery({
    queryKey: [
      'admin-companies-user-list',
      tableState?.sort,
      tableState?.search,
      location?.state?.id,
      detail?.data?.resData?.data?.id,
      filterState
    ],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      let filter = {
        ...filterState,
        company: { value: location?.state?.id }
      }
      const res = await getUserList(token, tableState?.page, filter, tableState)
      return res
    },
    onSuccess: (res) => {
      setTableState({
        ...tableState,
        total: res?.resData?.meta?.total
      })
    }
  })

  function closeFilterModal() {
    setFilter((prev) => ({ ...prev, show: false }))
  }

  function openPairUserModal() {
    setPairUserModal(true)
  }

  function closePairUserModal() {
    setPairUserModal(false)
  }

  const handleNavigationBack = () => {
    return navigate(-1)
  }
  const handleEditProfile = () => {
    showSuccessMessage(t('success messages.Profile Edited'))
  }
  const handleDeleteProfile = () => {
    showSuccessMessage(t('success messages.Request sent account'))
  }

  const handleRetrieveProfile = () => {
    showSuccessMessage(t('success messages.Profile retrieved'))
  }

  function handleSubmitFilter(data) {
    setFilterState(data)
  }
  function resetFilter() {
    setFilterState({ ...initialFilterState })
  }

  const columns = [
    {
      id: 'name',
      name: 'USER NAME',
      selector: (row) => row?.name,
      sortable: true
    },
    {
      id: 'email',
      name: 'EMAIL',
      selector: (row) => row?.email,
      sortable: true
    },
    {
      id: 'phone',
      name: t('TELEPHONE NUMBER'),
      selector: (row) => row?.phone ?? '-',
      sortable: true
    },
    {
      id: 'role',
      name: t('ROLE'),
      selector: (row) => row?.role?.role ?? '-',
      sortable: true
    },
    {
      id: 'created_at',
      name: t('DATE'),
      selector: (row) => moment(row?.created_at).format('DD/MM/YYYY'),
      sortable: true
    }
  ]

  function handleNavUserDetails(data) {
    navigate(`/admin/users/${data?.id}`)
  }

  return (
    <div className="w-full overflow-y-auto pr-6">
      <CompanyDetailHeader
        goBackNav={handleNavigationBack}
        companyName={detail?.data?.resData?.data?.name ?? location?.state?.name}
        date={detail?.data?.resData?.data?.created_at ?? location?.state?.created_at}
        onDeleteProfile={handleDeleteProfile}
        onEditProfile={handleEditProfile}
        onRetrieveProfile={handleRetrieveProfile}
        data={detail?.data?.resData?.data ?? location?.state}
        countries={countries}
        refetch={detail?.refetch}
      />
      <CompanyDetailInfo {...(detail?.data?.resData?.data ?? location?.state)} />
      <div className="w-full h-[0.2px] bg-[#363636] my-2 mb-6" />
      <CompanyGptKeyInfo
        gptKey={detail?.data?.resData?.data?.gpt_key ?? location?.state?.gpt_key}
        onChangeGptKey={() => setShowGptKeyModal(true)}
      />
      <div className="w-full h-[0.2px] bg-[#363636] my-2" />
      <div className="flex items-center px-4 mt-8 mb-4">
        <Typography.Title className="text-h2 font-manrope !text-white">
          {t('Paired Users')} ({detail?.data?.resData?.data?.user_count ?? location?.state?.user_count})
        </Typography.Title>
        <div className="flex-1 flex gap-2 ml-4 justify-between">
          <div className="flex gap-2">
            <SearchInput
              onSearch={(event) => {
                setTableState((prevState) => {
                  return {
                    ...prevState,
                    search: event.target.value
                  }
                })
              }}
            />
            <FilterButton
              onClick={() => {
                setFilter({ show: true })
              }}
            />
          </div>
          <CustomButton className="ml-2" onClick={openPairUserModal}>
          {t('Add')}
          </CustomButton>
        </div>
      </div>
      {((isLoading && !data) || (data?.resData?.data?.length > 0 && !isLoading)) && (
        <>
          <Table
            defaultSortFieldId={'created_at'}
            defaultSortAsc={false}
            onChangePrevPage={() => {
              if (tableState?.page > 1)
                setTableState((prevState) => {
                  return {
                    ...prevState,
                    page: prevState?.page - 1
                  }
                })
            }}
            onChangeNextPage={() => {
              if ((tableState?.page + 1) * tableState?.limit <= data?.resData?.meta?.total)
                setTableState((prevState) => {
                  return {
                    ...prevState,
                    page: prevState?.page + 1
                  }
                })
            }}
            sortServer
            onSort={(column, sortDirection) => {
              setTableState((prevState) => ({
                ...prevState,
                sort: `${sortDirection === 'desc' ? '-' : ''}${column?.id}`,
                sort_order: sortDirection
              }))
            }}
            pending={isLoading}
            data={data?.resData?.data ?? []}
            columns={columns}
            currentPage={tableState?.page}
            totalPerPage={tableState?.limit}
            page={tableState?.page}
            pageSize={tableState?.limit}
            totalRows={data?.resData?.meta?.total}
            onRowClicked={handleNavUserDetails}
            hidePagination
          />
          {data && (
            <div className="flex w-full justify-end items-center pb-5 mt-2">
              <AdminPagination
                count={Math.ceil(data?.resData?.meta?.total / tableState?.limit)}
                page={tableState?.page}
                onChange={(_, page) => {
                  setTableState((prevState) => ({
                    ...prevState,
                    page: page
                  }))
                }}
                shape="rounded"
              />
            </div>
          )}
        </>
      )}
      {data?.resData?.data?.length < 1 && !isLoading && (
        <div className="mx-4">
          <NullComponent
            title={'No user paired'}
            // description={'Pair users to it'}
          />
        </div>
      )}
      <FilterPairUserCompany
        show={filter.show}
        onClose={closeFilterModal}
        onSubmit={handleSubmitFilter}
        resetFilter={resetFilter}
        filterState={filterState}
      />
      <AddUserModal
        show={pairUserModal}
        company={{
          id: location?.state?.id,
          name: location?.state?.name
        }}
        onClose={closePairUserModal}
        refetchUserList={refetch}
      />
      <ChangeGptKeyModal
        open={showGptKeyModal}
        gptKey={location?.state?.gpt_key}
        onClose={() => {
          setShowGptKeyModal(false)
        }}
        onSubmit={() => {
          showSuccessMessage(t('success messages.Secret key edited'))
          setShowGptKeyModal(false)
        }}
        refetch={detail?.refetch}
        data={detail?.data?.resData?.data ?? location?.state}
      />
    </div>
  )
}

export default CompanyDetailPage
