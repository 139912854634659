import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CustomButton from 'components/atoms/CustomButton'
import React, { useState } from 'react'
import ResetPasswordUserModal from './ResetPasswordUserModal'
import DeleteProfileUserModal from './DeleteProfileUserModal'
import EditProfileUserModal from './EditProfileUserModal'
import RetrieveProfileUsersModal from './RetrieveProfileUsersModal'
import { useMutation } from 'react-query'
import { deleteUserById, postForgotPassword, putRetrieveUserById, putUpdateUserDetail } from 'api'
import useAuth from 'hooks/useAuth'
import { showErrorMessage, showSuccessMessage } from 'utils/showMessage'
import {
  BACK_OFFICE,
  DELETE_PROFILE,
  EDIT_PROFILE,
  RESET_PASSWORD,
  RETRIEVE_PROFILE
} from 'constant'
import { useTranslation } from 'react-i18next'

const UsersDetailHeader = ({
  goBackNav,
  isAccountSuspend,
  name = 'Name Surname',
  created_at = '',
  userId,
  userDetail,
  refetchUserDetail = () => {}
}) => {
  const { t, i18n } = useTranslation()

  const { getAccessToken } = useAuth()
  const [modalResetPassword, setModalResetPassword] = useState(false)
  const [modalEditProfile, setModalEditProfile] = useState(false)
  const [modalDeleteProfile, setModalDeleteProfile] = useState(false)
  const [modalRetrieveProfile, setModalRetrieveProfile] = useState(false)

  const deleteUser = useMutation({
    mutationKey: 'putDeleteUser',
    mutationFn: () => deleteUserById(getAccessToken(), userId),
    onSuccess: () => {
      refetchUserDetail()
      showSuccessMessage(t('success messages.User deleted'))
      closeModal(DELETE_PROFILE)
    },
    onError: () => {
      showErrorMessage(t('error messages.Profile delete request could not be submitted'))
    }
  })
  const retrieveUser = useMutation({
    mutationKey: 'putRetrieveUser',
    mutationFn: () => putRetrieveUserById(getAccessToken(), userId),
    onSuccess: () => {
      refetchUserDetail()
      showSuccessMessage(t('success messages.Profile retrieved'))
      closeModal(RETRIEVE_PROFILE)
    },
    onError: () => {
      showErrorMessage(t('error messages.The profile could not be retrieved'))
    }
  })

  const forgotPassword = useMutation({
    mutationKey: 'forgotPassword',
    mutationFn: () => postForgotPassword(userDetail?.email, BACK_OFFICE),
    onSuccess: () => {
      refetchUserDetail()
      showSuccessMessage(t('success messages.Reset link sent'))
      closeModal(RESET_PASSWORD)
    },
    onError: () => {
      showErrorMessage(t('error messages.The link could not be sent'))
    }
  })
  const editProfile = useMutation({
    mutationKey: 'editProfile',
    mutationFn: (userData) => putUpdateUserDetail(getAccessToken(), userId, userData),
    onSuccess: () => {
      refetchUserDetail()
      showSuccessMessage(t('success messages.Profile edited'))
      closeModal(EDIT_PROFILE)
    },
    onError: () => {
      showErrorMessage(t('error messages.The profile could not be changed'))
    }
  })

  function onResetPassword() {
    return forgotPassword.mutate()
  }
  function onEditProfile(data) {
    return editProfile.mutate(data)
  }
  function onDeleteProfile() {
    return deleteUser.mutate()
  }
  function onRetrieveProfile() {
    return retrieveUser.mutate()
  }

  function openModal(modal) {
    switch (modal) {
      case EDIT_PROFILE:
        return setModalEditProfile(true)
      case DELETE_PROFILE:
        return setModalDeleteProfile(true)
      case RESET_PASSWORD:
        return setModalResetPassword(true)
      case RETRIEVE_PROFILE:
        return setModalRetrieveProfile(true)
      default:
        return false
    }
  }

  function closeModal(modal) {
    switch (modal) {
      case EDIT_PROFILE:
        return setModalEditProfile(false)
      case DELETE_PROFILE:
        return setModalDeleteProfile(false)
      case RESET_PASSWORD:
        return setModalResetPassword(false)
      case RETRIEVE_PROFILE:
        return setModalRetrieveProfile(false)
      default:
        return false
    }
  }

  return (
    <>
      <div className="flex w-full justify-between pb-6 border-b border-l-0 border-r-0 border-t-0 border-solid border-[#FFFFFF29]">
        {/* Title */}
        <div className="flex gap-2 justify-center items-center">
          <div
            onClick={goBackNav}
            className="pr-2 py-2 flex justify-center items-center cursor-pointer">
            <ArrowBackIcon className="text-[#FFFFFF7A]" />
          </div>
          <span className="text-white font-manrope text-2xl flex flex-col gap-1">
            {name}
            <span className="text-[#FFFFFF7A] font-manrope text-sm">
              {t('DATE')}: {created_at}
            </span>
          </span>
        </div>
        {/* Button Wrapper */}
        <div className="flex gap-2 items-center">
          <CustomButton onClick={openModal.bind(this, RESET_PASSWORD)} buttonColor="secondary">
            {t('Reset Password')}
          </CustomButton>
          <CustomButton onClick={openModal.bind(this, EDIT_PROFILE)} buttonColor="secondary">
            {t('Edit Profile')}
          </CustomButton>
          {isAccountSuspend ? (
            <CustomButton onClick={openModal.bind(this, RETRIEVE_PROFILE)}>
              {t('Retrieve Profile')}
            </CustomButton>
          ) : (
            <CustomButton onClick={openModal.bind(this, DELETE_PROFILE)} buttonColor="danger">
              {t('Delete Profile')}
            </CustomButton>
          )}
        </div>
      </div>
      {modalResetPassword ? (
        <ResetPasswordUserModal
          isLoading={forgotPassword.isLoading}
          show={modalResetPassword}
          onClose={closeModal.bind(this, RESET_PASSWORD)}
          onResetPassword={onResetPassword}
        />
      ) : null}
      {modalDeleteProfile ? (
        <DeleteProfileUserModal
          name={name}
          isLoading={deleteUser.isLoading}
          show={modalDeleteProfile}
          onClose={closeModal.bind(this, DELETE_PROFILE)}
          onDeleteProfile={onDeleteProfile}
        />
      ) : null}
      {modalEditProfile ? (
        <EditProfileUserModal
          isLoading={editProfile.isLoading}
          userDetail={userDetail}
          show={modalEditProfile}
          onClose={closeModal.bind(this, EDIT_PROFILE)}
          onEditProfile={onEditProfile}
        />
      ) : null}
      {modalRetrieveProfile ? (
        <RetrieveProfileUsersModal
          name={name}
          isLoading={retrieveUser.isLoading}
          show={modalRetrieveProfile}
          onClose={closeModal.bind(this, RETRIEVE_PROFILE)}
          onRetrieveProfile={onRetrieveProfile}
        />
      ) : null}
    </>
  )
}

export default UsersDetailHeader
