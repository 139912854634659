import { getAppInformation } from 'api'
import { useAuth } from 'hooks'
import React from 'react'
import { useQuery } from 'react-query'
import { showErrorMessage } from 'utils/showMessage'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'

const ContactsPlatformContent = () => {
  const { t } = useTranslation()

  const { getAccessToken } = useAuth()

  const contentList = [
    {
      id: 'address',
      title: t('Address')
    },
    {
      id: 'phone',
      title: t('Telephone Number')
    },
    {
      id: 'email',
      title: 'Email'
    }
  ]

  const { data: contactsData } = useQuery({
    queryKey: ['app-information-page'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await getAppInformation(getAccessToken())
      return res
    },
    onError: () => {
      showErrorMessage(t('error messages.Failed to fetch contacts, please try again'))
    }
  })

  return (
    <div className="flex flex-col flex-grow gap-12 max-w-lg">
      {/* Title */}
      <h1 className="text-black text-2xl font-normal">{t('Contacts')}</h1>

      {/* Content */}
      <div className="flex flex-col gap-8">
        {contentList.map((item) => (
          <div key={item.id} className="flex flex-col gap-1">
            <span className="text-black font-semibold text-base mb-2">{item.title}</span>
            <span className="text-black font-normal text-sm">
              {contactsData?.resData?.data?.find((dataItem) => dataItem?.key === item?.id)?.value}
            </span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ContactsPlatformContent
