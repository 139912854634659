import React, { useState } from 'react'
import { Controller } from 'react-hook-form'
import ErrorMessage from '../atoms/ErrorMessage'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material'
import { isAdminPage } from 'utils/isAdminPage'

const PasswordFields = ({
  control,
  label,
  name,
  errors,
  togglePassword = true,
  isRequired = false,
  inputStyles = {},
  ...inputProps
}) => {
  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }
  return (
    <div className="w-full flex flex-col gap-2">
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <>
            {isAdminPage() ? (
              <span className={`${errors[name] ? '!text-red-500' : 'text-[#FFFFFFA6]'} }`}>
                {label}
              </span>
            ) : null}
            <TextField
              sx={{ ...inputStyles }}
              label={
                isAdminPage() ? null : (
                  <span className={`${errors[name] ? '!text-red-500' : ''} }`}>{label}</span>
                )
              }
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: togglePassword ? (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}>
                      {showPassword ? (
                        <VisibilityOffOutlined
                          className={`${isAdminPage() ? '!fill-[#FFFFFF7A]' : null}`}
                        />
                      ) : (
                        <VisibilityOutlined
                          className={`${isAdminPage() ? '!fill-[#FFFFFF7A]' : null}`}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ) : (
                  <></>
                )
              }}
              required={isRequired}
              {...field}
              {...inputProps}
            />
          </>
        )}
      />
      {errors[name] ? <ErrorMessage errorMessage={errors[name].message} /> : null}
    </div>
  )
}

export default PasswordFields
