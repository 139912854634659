import React, { useMemo, useState } from 'react'
import NoteCard from 'components/atoms/user/cases/NoteCard'
import NotePanel from 'components/atoms/user/cases/NotePanel'
import NoteCardExpand from 'components/atoms/user/cases/NoteCardExpand'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from '@dnd-kit/sortable'
import { SortableOverlay } from 'components/atoms/user/cases/SortableOverlay'
import { adjustNoteOrder } from 'api'
import { useMutation, useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { showErrorMessage, showSuccessMessage } from 'utils/showMessage'
import { errorObj } from 'utils/handleError'
import { useTranslation } from 'react-i18next'

const ListExpandNote = ({
  value,
  height,
  setNoteData,
  caseId,
  dataInsight,
  dataTodo,
  setNoteDataInsight,
  setNoteDataTodo,
  heightInput,
  setHeightInput,
  isEditAll,
  setIsEditAll
}) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation();
  const token = useSelector((state) => state.user.access_token)
  const [active, setActive] = useState(null)
  const activeItemInsight = useMemo(
    () => dataInsight.find((item) => item.id === active?.id),
    [active, dataInsight]
  )
  const activeItemTodo = useMemo(
    () => dataTodo.find((item) => item.id === active?.id),
    [active, dataTodo]
  )
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )
  const onClickOutsideListener = () => {
    document.removeEventListener('click', onClickOutsideListener)
  }

  const onMouseLeaveHandler = () => {
    document.addEventListener('click', onClickOutsideListener)
    setIsEditAll(false)
    setNoteDataInsight((prev) => {
      return prev.map((item) => {
        return {
          ...item,
          edit: false
        }
      })
    })
    setNoteDataTodo((prev) => {
      return prev.map((item) => {
        return {
          ...item,
          edit: false
        }
      })
    })
  }
  const adjustOrderingNote = useMutation({
    mutationKey: ['adjust-note'],
    mutationFn: async (payload) =>
      await adjustNoteOrder(token, caseId, payload.firstId, payload.secondId),
    onSuccess: () => {
      showSuccessMessage(t('success messages.note order'))
      queryClient.invalidateQueries(['note-list-insight'])
      queryClient.invalidateQueries(['note-list-todo'])
    },
    onError: (err) => {
      const obj = errorObj(err)
      showErrorMessage(obj.resData.errors[0].message)
      queryClient.invalidateQueries(['note-list-insight'])
      queryClient.invalidateQueries(['note-list-todo'])
    }
  })

  const handleDragEndInsight = (event) => {
    const { active, over } = event

    if (active.id !== over.id) {
      setNoteDataInsight((prev) => {
        const oldIndex = prev.findIndex((item) => item.id === active.id)
        const newIndex = prev.findIndex((item) => item.id === over.id)
        const oldId = prev[oldIndex].id
        const newId = prev[newIndex].id

        adjustOrderingNote.mutate({ firstId: oldId, secondId: newId })
        return arrayMove(prev, oldIndex, newIndex)
      })
    }
  }

  const handleDragEndToDo = (event) => {
    const { active, over } = event

    if (active.id !== over.id) {
      setNoteDataTodo((prev) => {
        const oldIndex = prev.findIndex((item) => item.id === active.id)
        const newIndex = prev.findIndex((item) => item.id === over.id)
        const oldId = prev[oldIndex].id
        const newId = prev[newIndex].id
        adjustOrderingNote.mutate({ firstId: oldId, secondId: newId })
        return arrayMove(prev, oldIndex, newIndex)
      })
    }
  }

  return (
    <div
      className="w-full overflow-y-scroll py-2 "
      style={{ height: height }}
      onMouseLeave={() => onMouseLeaveHandler()}>
      <NotePanel value={value} index={0}>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEndInsight}
          onDragStart={({ active }) => {
            setActive(active)
          }}
          onDragCancel={() => {
            setActive(null)
          }}>
          <SortableContext
            items={dataInsight.map((note) => note.id)}
            strategy={verticalListSortingStrategy}>
            {dataInsight.map((note, idx) => (
              <NoteCardExpand
                key={`note-${idx}`}
                className="last:mb-10"
                setNoteData={setNoteDataInsight}
                noteData={dataInsight}
                tabNote={value}
                index={idx}
                height={heightInput}
                setHeight={setHeightInput}
                isEditAll={isEditAll}
                setIsEditAll={setIsEditAll}
                strategy={verticalListSortingStrategy}
                {...note}
              />
            ))}
          </SortableContext>
          <SortableOverlay active={activeItemInsight} />
        </DndContext>
      </NotePanel>
      <NotePanel value={value} index={1}>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEndToDo}
          onDragStart={({ active }) => {
            setActive(active)
          }}
          onDragCancel={() => {
            setActive(null)
          }}>
          <SortableContext
            items={dataTodo.map((note) => note.id)}
            strategy={verticalListSortingStrategy}>
            {dataTodo.map((note, idx) => (
              <NoteCardExpand
                key={`note-${idx}`}
                className="last:mb-10"
                setNoteData={setNoteDataTodo}
                noteData={dataTodo}
                tabNote={value}
                index={idx}
                height={heightInput}
                setHeight={setHeightInput}
                isEditAll={isEditAll}
                setIsEditAll={setIsEditAll}
                {...note}
              />
            ))}
          </SortableContext>
          <SortableOverlay active={activeItemTodo} />
        </DndContext>
      </NotePanel>
    </div>
  )
}

export default ListExpandNote
