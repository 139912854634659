import { apiGet } from 'api/api-get'
import { apiPost } from 'api/api-post'
import { urls } from 'api/api-url'
import { CONST_QUESTION, CONST_SHORTCUT } from 'constant'

export const getChatHistory = (token, chatId) => {
  return apiGet({ token: token, url: `${urls.chats.chats}/${chatId}` })
}

export const postChatMessage = (token, chatId, data) => {
  return apiPost({
    token: token,
    url: `${urls.chats.chats}/${urls.chats.message}`,
    data: {
      chat_id: chatId,
      question: data?.type == CONST_QUESTION ? data?.message : null,
      shortcut: data?.type == CONST_SHORTCUT ? data?.message : null
    }
  })
}

export const postRegenerateMessageChat = (token, chatId, messageId) => {
  return apiPost({
    token: token,
    url: `${urls.chats.chats}/${urls.chats.regenerateMessage}`,
    data: {
      chat_id: chatId,
      message_id: messageId,
      type: 'chat'
    }
  })
}
export const getReferenceRegulationChat = (token, number, reference) => {
  return apiGet({
    token: token,
    url: `${urls.chats.chats}/${urls.chats.reference}?number=${number}&reference=${reference}`
  })
}
