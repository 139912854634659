import ModalUser from 'components/molecules/user/ModalUser'
import React, { useState } from 'react'

import { useQuery } from 'react-query'

import { getRegulationExportFile } from 'api'
import { useAuth } from 'hooks'

import RegulationSelectableTable from './RegulationSelectableTable'
import { useTranslation } from 'react-i18next'

const INITIAL_DOCS_TABLE_STATE = {
  sort: '-created_at',
  sort_order: 'desc',
  search: ''
}

const RegulationExportDocument = ({ show, closeModal, id }) => {
  const { t } = useTranslation();
  const { getAccessToken } = useAuth()
  const [tableState, setTableState] = useState(INITIAL_DOCS_TABLE_STATE)
  const [tableData, setTableData] = useState([])
  const [selectedDocuments, setSelectedDocuments] = useState([])
  const handleChangeSelectedDocuments = (options) => {
    setSelectedDocuments(options.selectedRows)
  }

  function handleConfirmNewExportFile() {
    const selectedDocumentUrl = selectedDocuments.map((doc) => doc?.url)

    selectedDocumentUrl.forEach((url, index) => {
      window.open(url, '_blank')
    })
    closeModal()
  }

  useQuery({
    queryKey: ['getRegulationExportFile', id],
    queryFn: () => getRegulationExportFile(getAccessToken(), id),
    onSuccess: (res) => {
      console.log('res',res);
      
      setTableData(res?.resData?.data?.documents || [])
    },
    onError: (err) => {
      console.log('error getRegulations', err)
    }
  })

  return (
    <ModalUser
      titleComponent={
        <div className="flex justify-between items-center pb-2">
          <span>{t('Source Information')}</span>
        </div>
      }
      show={show}
      width={900}
      isCloseIconVisible={false}
      onClose={closeModal}
      closable={false}>
      <div className="mb-2">
        <p className="text-[14px]">
          {t('Source Text')}
        </p>
      </div>

      <RegulationSelectableTable
        tableState={tableState}
        handleChangeSelectedDocuments={handleChangeSelectedDocuments}
        documentListByCase={tableData}
        selectedDocuments={selectedDocuments}
        closeModal={closeModal}
        handleConfirmNewChat={handleConfirmNewExportFile}
      />
    </ModalUser>
  )
}

export default RegulationExportDocument
