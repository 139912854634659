import { ArrowForward, InsertDriveFileOutlined } from '@mui/icons-material'
import { Tooltip } from 'antd'
import CustomButton from 'components/atoms/CustomButton'
import Table from 'components/atoms/Table'
import moment from 'moment'
import React from 'react'

const SearchOtherCasesTable = ({
  isLoading,
  handleBreadcrumbNavigation,
  handleSort,
  documentListByCase,
  closeModal
}) => {
  // Table Other Cases
  const columnOtherCases = [
    {
      id: 'name',
      name: 'Case name',
      cell: (row) => (
        <div className="flex gap-2 items-center">
          <Tooltip title={row?.name}>
            <span className="line-clamp-1">{row?.name}</span>
          </Tooltip>
        </div>
      ),
      selector: (row) => row?.name,
      sortable: true,
      width: '150px'
    },

    {
      id: 'document_total',
      name: 'Documents',
      cell: (row) => (
        <div className="grid grid-cols-2 gap-2 items-center mx-auto text-end">
          {row?.document_total}{' '}
          <InsertDriveFileOutlined className="!w-4 !h-[18px] !text-[#828282]" />
        </div>
      ),
      width: '120px',
      selector: (row) => row?.document_total,
      sortable: true
    },
    {
      id: 'updated_at',
      name: 'Last change',
      selector: (row) => moment(row?.updated_at).format('DD/MM/YYYY'),
      sortable: true
    },

    {
      id: 'regulation_title',
      name: 'Regulation',
      selector: (row) => row?.regulation?.title,
      sortable: true
    },
    {
      id: 'case_status_name',
      name: 'Status',
      selector: (row) => row?.case_status?.name,
      cell: (row) => (
        <div>
          <Tooltip title={row?.case_status?.name}>
            <span className="line-clamp-1">{row?.case_status?.name}</span>
          </Tooltip>
        </div>
      ),
      width: '170px',
      sortable: true
    },
    {
      name: '',
      cell: (row) => (
        <div
          className="flex justify-center items-center"
          onClick={handleBreadcrumbNavigation.bind(this, {
            caseName: row?.name,
            caseId: row?.id
          })}>
          <ArrowForward className="!text-[#595961] cursor-pointer" />
        </div>
      ),
      width: '50px',
      sortable: false
    }
  ]
  return (
    <>
      <Table
        pending={isLoading}
        highlightOnHover
        onSort={handleSort}
        defaultSortFieldId={'updated_at'}
        defaultSortAsc={false}
        noDataComponent={
          <div className="flex w-full justify-start items-center text-[#828282] p-4">
            No cases available
          </div>
        }
        sortServer
        fixedHeader
        className={'flex-auto h-[300px] overflow-y-auto pr-6'}
        hidePagination
        columns={columnOtherCases}
        data={documentListByCase}
      />
      <div className="flex justify-end items-center gap-4">
        <CustomButton buttonColor="secondary" onClick={closeModal}>
          Cancel
        </CustomButton>
        <CustomButton buttonColor={'secondary'} disabled={true} onClick={() => {}}>
          Confirm
        </CustomButton>
      </div>
    </>
  )
}

export default SearchOtherCasesTable
