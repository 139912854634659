import { LogoutOutlined, ShieldOutlined } from '@mui/icons-material'
import { CONTACTS_CONTENT, PROFILE_CONTENT, SECURITY_CONTENT } from 'constant'
import useAuth from 'hooks/useAuth'
import React, { useState } from 'react'
import LogoutModal from './LogoutModal'
import TCModal from '../TCModal'
import { showSuccessMessage } from 'utils/showMessage'
import useTCPP from 'hooks/useTCPP'
import { useTranslation } from 'react-i18next'
import TCModalProfile from './TCModalProfile'

const listStyle =
  'px-6 py-6 h-7 flex justify-start text-[#595961]  items-center w-full rounded-md text-base cursor-pointer'

const listActiveStyle = 'bg-[#0049EE] text-white font-semibold hover:bg-[#0049EE] hover:text-white'
const listNotActiveStyle = 'hover:bg-[#e4e4e6] font-normal'

const SidebarProfile = ({ handleContent, currentContent }) => {
  const { t } = useTranslation()
  const { onLogout } = useAuth()
  const [logoutModal, setLogoutModal] = useState(false)
  const { handleSubmitTcModal, tcModal, acceptTC, isPPFetching, isTCFetching, openTcModal ,closeTcModal} =
    useTCPP()

  function openLogoutModal() {
    setLogoutModal(true)
  }
  function closeLogoutModal() {
    setLogoutModal(false)
  }
  function handleSubmitLogout() {
    showSuccessMessage(t('success messages.Successful logout'))
    return onLogout()
  }

  return (
    <div className="flex flex-col justify-between flex-grow p-4 w-full">
      {/* Profile, Security & contacts */}
      <ul className="list-none w-full">
        <li
          onClick={handleContent.bind(this, 'profile')}
          className={`${listStyle} ${
            currentContent === PROFILE_CONTENT ? listActiveStyle : listNotActiveStyle
          }`}>
          {t('profile.Profile')}
        </li>
        <li
          onClick={handleContent.bind(this, 'security')}
          className={`${listStyle} ${
            currentContent === SECURITY_CONTENT ? listActiveStyle : listNotActiveStyle
          }`}>
          {t('profile.Security')}
        </li>
        <li
          onClick={handleContent.bind(this, 'contacts')}
          className={`${listStyle} ${
            currentContent === CONTACTS_CONTENT ? listActiveStyle : listNotActiveStyle
          }`}>
          {t('profile.Contacts')}
        </li>
      </ul>
      {/* T&C , Logout */}
      <ul className="list-none w-full">
        <li className={`${listStyle} gap-2 ${listNotActiveStyle}`} onClick={openTcModal}>
          <ShieldOutlined /> <span>{t('profile.Terms and conditions')}</span>
        </li>
        <li className={`${listStyle} gap-2 ${listNotActiveStyle}`} onClick={openLogoutModal}>
          <LogoutOutlined /> <span>{t('Logout')}</span>
        </li>
      </ul>
      {/* Modal Logout */}
      {logoutModal && (
        <LogoutModal
          show={logoutModal}
          onLogout={handleSubmitLogout}
          closeModal={closeLogoutModal}
        />
      )}
      {/* Modal T&C */}
      {tcModal.show && (
        <TCModalProfile
          isFetching={isPPFetching || isTCFetching}
          isLoading={acceptTC.isLoading}
          pp={tcModal.pp}
          tc={tcModal.tc}
          show={tcModal.show}
          onClose={closeTcModal}
          
        />
      )}
    </div>
  )
}

export default SidebarProfile
