import React, { useEffect, useMemo, useRef, useState } from 'react'
import { ConfigProvider, Popover, Select, Spin, Typography } from 'antd'
import {
  AccountCircle,
  Add,
  Balance,
  CalendarToday,
  DataUsage,
  FilterListOutlined,
  Gavel,
  KeyboardArrowDown,
  MoreVertOutlined,
  Numbers,
  PeopleAltOutlined,
  PersonOutline,
  Search
} from '@mui/icons-material'

import { Breadcrumbs, ClickAwayListener, Tab, Tabs, Tooltip } from '@mui/material'

import ListChat from 'components/organisms/users/chat/ListChat'
import ListDocument from 'components/organisms/users/document/ListDocument'
import { useTranslation } from 'react-i18next'

import CustomButton from 'components/atoms/CustomButton'
import ChatFilterModal from 'components/organisms/users/chat/ChatFilterModal'
import { DOCUMENT_TYPE, INITIAL_CHAT_FILTER, ROLE_ADMIN, ROLE_USER } from 'constant'
import { showErrorMessage, showSuccessMessage } from 'utils/showMessage'
import RenameModal from 'components/organisms/users/RenameModal'
import NotesContainer from 'components/organisms/users/cases/NotesContainer'
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { errorObj } from 'utils/handleError'
import {
  deleteChat,
  deleteDocument,
  deleteProcessedDocument,
  exportDocument,
  exportProcessedDocument,
  getChatListDetailCase,
  getDetailCase,
  getDocumentListDetailCase,
  getProcessedDocumentListDetailCase,
  getRegulationList,
  getStatusListDetailCase,
  postNewChats,
  processDocumentApi,
  updateCase,
  updateChatNameDetailCase,
  updateDocumentNameDetailCase,
  updateProcessedDocumentNameDetailCase,
  updateStatusDetailCase
} from 'api'
import { useSelector } from 'react-redux'
import moment from 'moment'
import useAuth from 'hooks/useAuth'
import ExportNotesModal from 'components/organisms/users/ExportNotesModal'
import DeleteCaseModal from 'components/organisms/users/DeleteCaseModal'
import { useDeleteCase, useExportNotes } from 'hooks'
import NewChatModal from 'components/organisms/users/chat/NewChatModal'
import AddDocumentModal from 'components/organisms/users/document/AddDocumentModal'
import CaseModal from 'components/organisms/users/cases/CaseModal'
import SearchInput from 'components/atoms/SearchInput'
import _, { debounce } from 'lodash'
import ListProcessed from 'components/organisms/users/processedDocuments/ListProcessed'
import NewProcessedModal from 'components/organisms/users/processedDocuments/NewProcessedModal'

const initialTableState = {
  total: 0,
  page: 1,
  limit: 20,
  search: '',
  sort: 'name'
}
const initialFilterState = {
  role: undefined,
  typeDate: undefined,
  startDate: undefined,
  endDate: undefined
}

const CasesPage = () => {
  const { t } = useTranslation()
  
  const [modalCase, setModalCase] = useState({
    show: false,
    type: 'edit'
  })
  const navigate = useNavigate()
  const { platformData } = useAuth()
  const {
    exportNotesMutation,
    exportNotesModal,
    openExportNotesModal,
    closeExportNotesModal,
    handleExportNotes
  } = useExportNotes()
  const {
    deleteCaseMutation,
    deleteCaseModal,
    openDeleteCaseModal,
    closeDeleteCaseModal,
    handleDeleteCase
  } = useDeleteCase(navigateBackToWorkspace)
  const [layoutInfo] = useOutletContext()
  const [infoHeaderLayout, setInfoHeaderLayout] = useState(0)
  const infoHeaderRef = useRef()
  const [tableState, setTableState] = useState(initialTableState)
  const [filterState, setFilterState] = useState(initialFilterState)
  const intervalRef = useRef(null)
  const [seeMore, setSeeMore] = useState()
  const [tabTable, setTabTable] = useState(0)
  const [tabNote, setTabNote] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  
  const [filterChatModal, setFilterChatModal] = useState(INITIAL_CHAT_FILTER)
  const [renameModal, setRenameModal] = useState({
    title: '',
    show: false,
    onClick: () => {
    }
  })
  const [chatList, setChatList] = useState([])
  const [documentList, setDocumentList] = useState([])
  const [processedList, setProcessedList] = useState([])
  const [firstClient, setFirstClient] = useState()
  const [moreClient, setMoreClient] = useState([])
  const [firstCounterpart, setFirstCounterpart] = useState()
  const [moreCounterpart, setMoreCounterpart] = useState([])
  const [statusList, setStatusList] = useState([])
  const [statusValue, setStatusValue] = useState(null)
  const [chatId, setChatId] = useState(null)
  const [documentId, setDocumentId] = useState(null)
  const [dataRename, setDataRename] = useState(null)
  const [detailData, setDetailData] = useState({})
  const [newChatModal, setNewChatModal] = useState(false)
  const [newProcessedModal, setNewProcessedModal] = useState(false)
  
  const [openSearch, setOpenSearch] = useState(false)
  const [documentModal, setDocumentModal] = useState({
    show: false,
    files: []
  })
  const [imageStatus, setImageStatus] = useState({})
  
  const uploadRef = useRef()
  const docsList = useRef(documentList)
  const procssList = useRef(processedList)
  
  function navigateBackToWorkspace() {
    return navigate('/cases', { replace: true })
  }
  
  function openNewChatModal() {
    setNewChatModal(true)
  }
  
  function openNewProcessedModal() {
    setNewProcessedModal(true)
  }
  
  function closeNewChatModal() {
    setNewChatModal(false)
  }
  
  function closeNewProcessedModal() {
    setNewProcessedModal(false)
  }
  
  const openSearchBox = () => {
    setOpenSearch(true)
  }
  
  function navigateToChat(chatData) {
    navigate(`/chat`, {
      state: { caseId: detailData?.id, caseName: detailData?.name, chatData: chatData }
    })
  }
  
  const confirmNewChatMutationFromDocument = useMutation({
    mutationKey: '',
    mutationFn: (selectedDocumentIds) => {
      return postNewChats(token, detailData?.id, selectedDocumentIds)
    },
    onSuccess: (res) => {
      navigate(`/chat`, {
        state: {
          caseId: detailData?.id,
          caseName: detailData?.name,
          chatData: res.resData.data
        }
      })
    },
    onError: () => {
      showErrorMessage(t('error messages.Error create new chat, please try again.'))
    }
  })
  
  const goToTheChatFromDocument = (row) => {
    confirmNewChatMutationFromDocument.mutate([row.id])
  }
  
  function navigateToDoc(docData) {
    if (
      (docData?.status === 'SUCCEEDED' && docData?.sub_status === 'SUCCEEDED') ||
      docData?.status === 'FAILED'
    ) {
      navigate(`/document`, {
        state: { caseId: detailData?.id, caseName: detailData?.name, document: docData }
      })
    }
  }
  
  function navigateToProcessedDoc(docData) {
    if (docData?.status === 'SUCCEEDED') {
      navigate(`/processed`, {
        state: { caseId: detailData?.id, caseName: detailData?.name, document: docData }
      })
    }
  }
  
  const location = useLocation()
  const caseId = location.state?.data.id
  const token = useSelector((state) => state.user.access_token)
  const queryClient = useQueryClient()
  
  function openFilterChatModal() {
    setFilterChatModal((prev) => ({ ...prev, show: true }))
  }
  
  function closeFilterChatModal() {
    setFilterChatModal((prev) => ({
      ...prev,
      show: false
    }))
  }
  
  function handleSubmitFilter(data) {
    setFilterChatModal((prev) => ({ ...prev, data: data }))
    setFilterState((prev) => ({
      ...prev,
      startDate: data.fromDate,
      endDate: data.toDate,
      typeDate: 'between'
    }))
  }
  
  const cancelFilter = () => {
    setFilterChatModal((prev) => ({
      ...prev,
      show: false,
      data: { ...INITIAL_CHAT_FILTER.data }
    }))
    setFilterState(initialFilterState)
  }
  
  function openRenameChatModal(row) {
    setChatId(row.id)
    setDataRename(row)
    setRenameModal((prev) => ({
      ...prev,
      title: 'Rename chat',
      show: true,
      onClick: handleSubmitRenameChat
    }))
  }
  
  function openRenameDocumentModal(row) {
    setDocumentId(row.id)
    const filename = row?.filename.split('.')
    let name = filename.join('.')
    if (filename.length > 1) {
      name = filename.slice(0, filename.length - 1).join('.')
    }
    let newRow = {
      ...row,
      name: name
    }
    setDataRename(newRow)
    setRenameModal((prev) => ({
      ...prev,
      title: 'Rename document',
      show: true,
      onClick: (data) =>
        handleSubmitRenameDocument({
          renameValue: `${data.renameValue}.${filename[filename.length - 1]}`
        })
    }))
  }
  
  function openRenameProcessedDocumentModal(row) {
    setDocumentId(row.id)
    const filename = row?.filename.split('.')
    let name = filename.join('.')
    if (filename.length > 1) {
      name = filename.slice(0, filename.length - 1).join('.')
    }
    let newRow = {
      ...row,
      name: name
    }
    setDataRename(newRow)
    setRenameModal((prev) => ({
      ...prev,
      title: 'Rename document',
      show: true,
      onClick: (data) =>
        handleSubmitRenameProcessedDocument({
          renameValue: `${data.renameValue}.${filename[filename.length - 1]}`
        })
    }))
  }
  
  function closeRenameModal() {
    setRenameModal((prev) => ({ ...prev, show: false }))
  }
  
  // passing props to get data.renameValue in renameChat mutation
  const renameChat = useMutation({
    mutationKey: ['rename-chat'],
    mutationFn: async (data) => await updateChatNameDetailCase(token, chatId, data.renameValue),
    onSuccess: () => {
      showSuccessMessage(t('success messages.Chat renamed!'))
      queryClient.invalidateQueries(['data-list'])
    }
  })
  
  const deletingChat = useMutation({
    mutationKey: ['delete-chat'],
    mutationFn: async (id) => await deleteChat(token, id),
    onSuccess: () => {
      showSuccessMessage(t('success messages.Chat deleted!'))
      queryClient.invalidateQueries(['data-list'])
    }
  })
  
  const deletingDocument = useMutation({
    mutationKey: ['delete-document'],
    mutationFn: async (id) => await deleteDocument(token, id),
    onSuccess: () => {
      showSuccessMessage(t('success messages.Document deleted!'))
      queryClient.invalidateQueries(['data-list'])
    }
  })
  
  const deletingProcessedDocument = useMutation({
    mutationKey: ['delete-processed-document'],
    mutationFn: async (id) => await deleteProcessedDocument(token, id),
    onSuccess: () => {
      showSuccessMessage(t('success messages.Document deleted!'))
      queryClient.invalidateQueries(['data-list'])
    }
  })
  
  const exportingDocument = useMutation({
    mutationKey: ['export-document'],
    mutationFn: async (id) => await exportDocument(token, id),
    onSuccess: (response) => {
      setIsLoading(false)
      const { filepath } = response.resData.data
      showSuccessMessage(t('success messages.Document exported!'))
      window.open(filepath)
    },
    onError: (err) => {
      showErrorMessage('Errore nel download del file')
      setIsLoading(false)
    }
  })
  
  const exportingProcessedDocument = useMutation({
    mutationKey: ['export-processed-document'],
    mutationFn: async (id) => await exportProcessedDocument(token, id),
    onSuccess: (response) => {
      setIsLoading(false)
      const { filepath } = response.resData.data
      showSuccessMessage(t('success messages.Document exported!'))
      window.open(filepath)
    },
    onError: (err) => {
      showErrorMessage('Errore nel download del file')
      setIsLoading(false)
    }
  })
  
  const renameDocument = useMutation({
    mutationKey: ['rename-document'],
    mutationFn: async (data) =>
      await updateDocumentNameDetailCase(token, documentId, data.renameValue),
    onSuccess: () => {
      showSuccessMessage(t('success messages.Document renamed!'))
      queryClient.invalidateQueries(['data-list'])
    }
  })
  
  const renameProcessedDocument = useMutation({
    mutationKey: ['rename-processed-document'],
    mutationFn: async (data) =>
      await updateProcessedDocumentNameDetailCase(token, documentId, data.renameValue),
    onSuccess: () => {
      showSuccessMessage(t('success messages.Document renamed!'))
      queryClient.invalidateQueries(['data-list'])
    }
  })
  
  const regulationsData = useQuery({
    queryKey: ['workspace-regulation-list'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await getRegulationList(token)
      const regulations = res?.resData?.data?.filter?.((item) => {
        if (item?.title?.toLowerCase() === 'civil' || item?.title?.toLowerCase() === 'criminal') {
          return item
        }
      })
      return regulations
    }
  })
  
  const casesStatus = useQuery({
    queryKey: ['workspace-cases-status-list'],
    refetchOnWindowFocus: false,
    queryFn: () => getStatusListDetailCase(token)
  })
  
  const listData = useQuery({
    queryKey: ['data-list', filterState, tableState, tabTable],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      if (tabTable === 0) {
        return await getChatListDetailCase(token, filterState, tableState, caseId)
      } else if (tabTable === 1) {
        return await getDocumentListDetailCase(token, filterState, tableState, caseId)
      } else if (tabTable === 2) {
        return await getProcessedDocumentListDetailCase(token, filterState, tableState, caseId)
      }
    },
    onSuccess: (data) => {
      if (tabTable === 0) {
        setChatList(data.resData.data)
        setTableState((prev) => ({
          ...prev,
          total: data.resData.meta.total,
          limit: data.resData.meta.limit,
          page: data.resData.meta.page
        }))
      } else if (tabTable === 1) {
        setDocumentList(data.resData.data)
        setTableState((prev) => ({
          ...prev,
          total: data.resData.meta.total,
          limit: data.resData.meta.limit,
          page: data.resData.meta.page
        }))
      } else if (tabTable === 2) {
        setProcessedList(data.resData.data)
        setTableState((prev) => ({
          ...prev,
          total: data.resData.meta.total,
          limit: data.resData.meta.limit,
          page: data.resData.meta.page
        }))
      }
    },
    onError: (err) => {
      const obj = errorObj(err)
      if (obj.resData.errors) {
        showErrorMessage(obj.resData.errors[0].message)
      } else {
        showErrorMessage(err.message)
      }
    }
  })
  
  const updateStatus = useMutation({
    mutationKey: ['update-status'],
    mutationFn: async (value) => await updateStatusDetailCase(token, caseId, value),
    onSuccess: () => {
      showSuccessMessage(t('success messages.Status updated'))
    }
  })
  
  const processDocument = useMutation({
    mutationKey: '',
    mutationFn: (data) => {
      return processDocumentApi(token, detailData?.id, data.document_type, data.document_id)
    },
    onSuccess: (res) => {
      listData.refetch()
    },
    onError: () => {
      showErrorMessage(t('error messages.Error create new chat, please try again.'))
    }
  })
  
  const handleProcessDocument = (type, id) => {
    const data = {
      document_type: type,
      document_id: id
    }
    processDocument.mutate(data)
  }
  
  const handleProcessAllDocument = (id) => {
    Object.values(DOCUMENT_TYPE).forEach((type) => {
      handleProcessDocument(type, id)
    })
  }
  
  const handleDeleteChat = (row) => {
    deletingChat.mutate(row.id)
  }
  
  const handleDeleteDocument = (row) => {
    deletingDocument.mutate(row.id)
  }
  
  const handleDeleteProcessedDocument = (row) => {
    deletingProcessedDocument.mutate(row.id)
  }
  
  const handleDownloadDocument = async (row) => {
    window.open(row.filepath, '_blank')
    showSuccessMessage(t('file downloaded'))
    
  }
  
  const handleDownloadProcessedDocument = async (row) => {
    window.open(row.filepath, '_blank')
    showSuccessMessage(t('file downloaded'))
  }
  
  const handleExportDpcument = async (row) => {
    setIsLoading(true)
    exportingDocument.mutate(row.id)
  }
  
  const handleExportProcessedDpcument = async (row) => {
    setIsLoading(true)
    exportingProcessedDocument.mutate(row.id)
  }
  
  const { refetch } = useQuery({
    queryKey: ['case-detail'],
    refetchOnWindowFocus: false,
    queryFn: () => getDetailCase(token, caseId),
    onSuccess: (data) => {
      setStatusValue(data.resData.data.case_status_id)
      setDetailData(data.resData.data)
      setSeeMore(false)
      data.resData.data.case_clients.map((client, index) => {
        if (index === 0) {
          setFirstClient(client)
        } else {
          setMoreClient((prev) => [...prev, client])
        }
      })
      
      data.resData.data.case_counterparts.map((counterpart, index) => {
        if (index === 0) {
          setFirstCounterpart(counterpart)
        } else {
          setMoreCounterpart((prev) => [...prev, counterpart])
        }
      })
    }
  })
  
  async function handleSubmitCaseModal(data) {
    try {
      await updateCase(token, data, detailData?.id)
      refetch()
      showSuccessMessage(t('success messages.Case Edited'))
    } catch (error) {
      showErrorMessage(error?.response?.data?.errors?.[0]?.message)
    }
  }
  
  function handleSubmitRenameChat(data) {
    renameChat.mutate(data)
  }
  
  function handleSubmitRenameDocument(data) {
    renameDocument.mutate(data)
  }
  
  function handleSubmitRenameProcessedDocument(data) {
    renameProcessedDocument.mutate(data)
  }
  
  useQuery({
    queryKey: ['status-list'],
    refetchOnWindowFocus: false,
    queryFn: () => getStatusListDetailCase(token),
    
    onSuccess: (data) => {
      const newData = data.resData.data.map((status) => ({
        value: status.id,
        label: (
          <div className="flex items-center">
            <div
              className={`w-2 h-2 rounded-full mr-2`}
              style={{
                backgroundColor: `#${status.hex_code.toUpperCase()}`
              }}
            />
            <span className="text-black text-[13px]">{status.name}</span>
          </div>
        )
      }))
      
      setStatusList(newData)
    }
  })
  
  const handleChangeStatus = (value) => {
    setStatusValue(value)
    updateStatus.mutate(value)
  }
  
  const handleChangeSort = (e) => {
    if (e.sortable) {
      // if tablestate.sort === created_at change to updated_at and vice versa
      if (e.sortField === 'name') {
        setTableState((prev) => ({
          ...prev,
          sort: prev.sort === 'name' ? '-name' : 'name'
        }))
      } else if (e.sortField === 'created_at') {
        setTableState((prev) => ({
          ...prev,
          sort: prev.sort === 'created_at' ? '-created_at' : 'created_at'
        }))
      } else if (e.sortField === 'filename') {
        setTableState((prev) => ({
          ...prev,
          sort: prev.sort === 'filename' ? '-filename' : 'filename'
        }))
      } else if (e.sortField === 'document_type') {
        setTableState((prev) => ({
          ...prev,
          sort: prev.sort === 'document_type' ? '-document_type' : 'document_type'
        }))
      } else if (e.sortField === 'status') {
        setTableState((prev) => ({
          ...prev,
          sort: prev.sort === 'status' ? '-status' : 'status'
        }))
      }
    }
  }
  
  const handleChangeTab = (value) => {
    setTabTable(value)
    setTableState(initialTableState)
    // setFilterState(initialFilterState)
  }
  
  const onRowsPerPageChange = (event) => {
    setTableState((prev) => ({ ...prev, limit: event.target.value }))
  }
  
  const onPageChange = (event, newPage) => {
    setTableState((prev) => ({ ...prev, page: newPage + 1 }))
  }
  
  const optionsPopover = [
    {
      title: t('case.edit'),
      callback: () =>
        setModalCase((prev) => {
          return { ...prev, show: true }
        }),
      role: [ROLE_ADMIN]
    },
    {
      title: t('case.delete'),
      callback: openDeleteCaseModal.bind(this, detailData),
      role: [ROLE_ADMIN]
    },
    {
      title: t('case.exportNotes'),
      callback: openExportNotesModal.bind(this, detailData),
      role: [ROLE_ADMIN, ROLE_USER]
    }
  ]
  
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props
    
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}>
        {children}
      </div>
    )
  }
  
  function handleSearch(value) {
    console.log('value', value)
    setTableState((prev) => ({ ...prev, search: value }))
  }
  
  const onSearch = (e) => {
    handleSearch(e.target.value)
  }
  
  const debouncedResults = useMemo(() => {
    console.log('debouncedResults')
    return debounce(onSearch, 300)
  }, [])
  
  useEffect(() => {
    return () => {
      debouncedResults.cancel()
    }
  })
  
  useEffect(() => {
    if (infoHeaderRef?.current?.clientHeight !== infoHeaderLayout) {
      setInfoHeaderLayout(infoHeaderRef?.current?.clientHeight ?? 0)
    }
  }, [infoHeaderRef?.current !== null, seeMore])
  
  const handleImageError = (event) => {
    event.target.src = 'https://via.placeholder.com/150?text=Image+Not+Found'
    event.target.alt = 'Image not found'
  }
  
  const checkImageURL = (url) => {
    return new Promise((resolve) => {
      const img = new Image()
      img.src = url
      
      img.onload = () => resolve(false) // Gambar berhasil dimuat, return false (tidak error)
      img.onerror = () => resolve(true) // Gambar gagal dimuat, return true (error)
    })
  }
  
  const fetchDocuments = () => {
    getDocumentListDetailCase(token, filterState, tableState, caseId).then(
      (data) => {
        if (!arraysAreEqual(data?.resData?.data || [], docsList.current || [])) {
          listData.refetch()
        }
      },
      (err) => {
        showErrorMessage(t('error messages.Failed fetch document list, Please refresh the page'))
      }
    )
  }
  
  const fetchProcessed = () => {
    getProcessedDocumentListDetailCase(token, filterState, tableState, caseId).then(
      (data) => {
        if (!arraysAreEqual(data?.resData?.data || [], procssList.current || [])) {
          listData.refetch()
        }
      },
      (err) => {
        showErrorMessage(t('error messages.Failed fetch document list, Please refresh the page'))
      }
    )
  }
  
  function arraysAreEqual(array1, array2) {
    const statusArray1 = array1.map((item) => ({
      id: item.id,
      status: item.status,
      sub_status: item.sub_status,
      is_eligible: item.is_eligible
    }))
    const statusArray2 = array2.map((item) => ({
      id: item.id,
      status: item.statuss,
      sub_status: item.sub_status,
      is_eligible: item.is_eligible
    }))
    return _.isEqual(statusArray1, statusArray2)
  }
  
  const Loading = () => {
    return (
      <div
        className="z-50 flex flex-col items-center justify-center px-4 py-8 h-screen w-screen bg-opacity-70 bg-black fixed top-0 left-0">
        <Spin />
        <Typography.Text className="text-white text-lg text-primary-500 ml-2">
          In download
        </Typography.Text>
      </div>
    )
  }
  
  useEffect(() => {
    docsList.current = documentList
  }, [documentList])
  
  useEffect(() => {
    procssList.current = processedList
  }, [processedList])
  
  useEffect(() => {
    if (tabTable === 1) {
      fetchDocuments()
      intervalRef.current = setInterval(fetchDocuments, 30000)
    }
    
    if (tabTable === 2) {
      fetchProcessed()
      intervalRef.current = setInterval(fetchProcessed, 30000)
    }
    
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
        intervalRef.current = null
      }
    }
  }, [tabTable])
  
  useEffect(() => {
    const checkImages = async () => {
      if (detailData?.users) {
        // Pastikan detailData dan detailData.users sudah terdefinisi
        const statuses = await Promise.all(
          detailData.users.map(async (user) => {
            const isError = await checkImageURL(user.avatar_url)
            return {
              ...user,
              isError
            }
          })
        )
        const statusMap = statuses.reduce((acc, { avatar_url, isError }) => {
          acc[avatar_url] = isError
          return acc
        }, {})
        setImageStatus(statusMap)
      }
    }
    
    checkImages()
  }, [detailData?.users])
  
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault()
      event.returnValue = ''
    }
    
    window.addEventListener('beforeunload', handleBeforeUnload)
    
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
        intervalRef.current = null
      }
      
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])
  
  return (
    <div className="grid grid-cols-1" style={{ height: 200 }}>
      {isLoading && <Loading />}
      
      <div className="grid grid-cols-12 gap-4">
        <div className="w-full col-span-9 cursor-pointer rounded-3xl border border-solid border-[#E3E3E8] bg-white">
          <div ref={infoHeaderRef}>
            <div
              className="flex justify-between px-6 items-end border-solid border-t-0 border-x-0 border-[#E3E3E8] py-4 pt-8 mb-6">
              <div>
                <Breadcrumbs>
                  <Typography.Text className="text-black font-normal text-[14px]">
                    {t('breadcrumb.cases')}
                  </Typography.Text>
                </Breadcrumbs>
                <Typography.Text className="text-black font-semibold text-[30px]">
                  {detailData?.name}
                </Typography.Text>
              </div>
              <div className="flex items-center">
                <Typography.Text className="text-black font-normal text-[14px]">
                  {t('case.edited')} {moment(detailData?.updated_at).format('DD/MM/yyyy')}
                </Typography.Text>
                <Popover
                  content={
                    <div className="w-[128px] h-full flex flex-col text-black">
                      {optionsPopover.map(
                        (option, index) =>
                          option.role.includes(platformData?.role?.toUpperCase()) && (
                            <div
                              key={index}
                              onClick={option.callback}
                              className="px-2 py-1 cursor-pointer hover:bg-[#0000000A] text-black rounded text-sm font-normal">
                              {option.title}
                            </div>
                          )
                      )}
                    </div>
                  }
                  trigger="click"
                  placement="bottomRight"
                  arrow={false}>
                  <MoreVertOutlined
                    fontSize="small"
                    className={`cursor-pointer hover:text-[#0049EE] ml-4`}
                  />
                </Popover>
              </div>
            </div>
            <div className="flex gap-x-2 px-6">
              <div className="flex-1">
                <div className="flex items-start mb-4">
                  <div className="flex-1 flex items-center">
                    <DataUsage fontSize="small" className={`cursor-pointer text-[#828282] mr-2`} />
                    <Typography.Text className="text-[#828282] font-normal">
                      {t('case.status')}
                    </Typography.Text>
                  </div>
                  <div className="flex-[2_2_0%] flex items-center">
                    {statusList.length === 0 ? (
                      '-'
                    ) : (
                      <ConfigProvider
                        theme={{
                          components: {
                            Select: {
                              colorBorder: '#EDEDEF',
                              borderRadius: 60,
                              paddingContentVertical: 0,
                              padding: 0,
                              paddingContentVerticalLG: 0,
                              paddingContentVerticalSM: 0,
                              paddingMD: 0,
                              controlHeight: 28,
                              controlItemBgActive: '#F5F5F5'
                            }
                          }
                        }}>
                        <Select
                          className="status-case-page"
                          value={statusValue}
                          popupClassName="status-case-page-popup"
                          // disabled
                          suffixIcon={<KeyboardArrowDown fontSize="small" className="text-black" />}
                          style={{
                            minWidth: 250
                            // fontWeight: 500
                          }}
                          onChange={(value) => handleChangeStatus(value)}
                          options={statusList}
                        />
                      </ConfigProvider>
                    )}
                  </div>
                </div>
                
                <div className="flex items-start mb-4">
                  <div className="flex-1 flex items-center">
                    <Balance fontSize="small" className={`cursor-pointer text-[#828282] mr-2`} />
                    <Typography.Text className="text-[#828282] font-normal">
                      {t('case.regulation')}
                    </Typography.Text>
                  </div>
                  <div className="flex-[2_2_0%] flex items-center">
                    <Typography.Text className="text-black font-semibold">
                      {t(detailData?.regulation?.title) || '-'}
                    </Typography.Text>
                  </div>
                </div>
                
                {seeMore && (
                  <div className="flex items-start mb-4">
                    <div className="flex-1 flex items-center">
                      <CalendarToday
                        fontSize="small"
                        className={`cursor-pointer text-[#828282] mr-2`}
                      />
                      <Typography.Text className="text-[#828282] font-normal">
                        {t('starting date')}
                      </Typography.Text>
                    </div>
                    <div className="flex-[2_2_0%] flex items-center">
                      <Typography.Text className="text-black font-semibold">
                        {moment(detailData?.starting_date).format('DD/MM/yyyy') || '-'}
                      </Typography.Text>
                    </div>
                  </div>
                )}
                
                {seeMore && (
                  <div className="flex items-start mb-4">
                    <div className="flex-1 flex items-center">
                      <Numbers fontSize="small" className={`cursor-pointer text-[#828282] mr-2`} />
                      <Typography.Text className="text-[#828282] font-normal">
                        Register No.
                      </Typography.Text>
                    </div>
                    <div className="flex-[2_2_0%] flex items-center">
                      <div>
                        {detailData?.case_numbers.length > 0
                          ? detailData?.case_numbers.map((data, index) => {
                            return (
                              <Typography.Text className="text-black font-semibold" key={index}>
                                {index === 0 ? '' : ', '}
                                {data.number}
                              </Typography.Text>
                            )
                          })
                          : '-'}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="flex-1">
                <div className="flex items-start mb-4">
                  <div className="flex-1 flex items-center">
                    <PersonOutline
                      fontSize="small"
                      className={`cursor-pointer text-[#828282] mr-2`}
                    />
                    <Typography.Text className="text-[#828282] font-normal">
                      {' '}
                      {t('client')}{' '}
                    </Typography.Text>
                  </div>
                  <div className={`flex-[2_2_0%] ${!seeMore ? 'flex items-center' : ''}`}>
                    <Typography.Text className="text-black font-semibold">
                      {firstClient?.first_name} {firstClient?.last_name}
                      {firstClient?.nickname && !seeMore ? ',' : ''}{' '}
                      {firstClient?.nickname === '' ? '' : `(${firstClient?.nickname})`}
                      <span>
                        {moreClient.length !== 0 && !seeMore && (
                          <Typography.Text className="text-black font-semibold mx-1">
                            {' '}
                            and{' '}
                          </Typography.Text>
                        )}
                      </span>
                      <span>
                        {moreClient.length !== 0 && !seeMore && (
                          <Tooltip
                            arrow
                            title={
                              <div>
                                {moreClient.map((client, index) => {
                                  return (
                                    <Typography.Text key={index} className="block">
                                      {client.first_name} {client.last_name}
                                      {client.nickname ? ',' : ''}{' '}
                                      {client.nickname === '' ? '' : `(${client.nickname})`}
                                    </Typography.Text>
                                  )
                                })}
                              </div>
                            }>
                            <Typography.Text className="text-[#0049EE] font-semibold">
                              {' '}
                              {moreClient.length} others
                            </Typography.Text>
                          </Tooltip>
                        )}
                      </span>
                    </Typography.Text>
                    {moreClient.length !== 0 && seeMore && (
                      <Typography.Text className="text-black font-semibold">
                        ,{' '}
                        {moreClient
                          .map((client, index) => {
                            return (
                              <span key={index}>
                                {client.first_name} {client.last_name}{' '}
                                {client.nickname === '' ? '' : `(${client.nickname})`}
                              </span>
                            )
                          })
                          // add comma for each client
                          .reduce((prev, curr) => [prev, ', ', curr])}
                      </Typography.Text>
                    )}
                  </div>
                </div>
                
                <div className="flex items-start mb-4">
                  <div className="flex-1 flex items-center">
                    <PeopleAltOutlined
                      fontSize="small"
                      className={`cursor-pointer text-[#828282] mr-2`}
                    />
                    <Typography.Text className="text-[#828282] font-normal">
                      {t('counterpart')}
                    </Typography.Text>
                  </div>
                  <div className={`flex-[2_2_0%] ${!seeMore ? 'flex items-center' : ''}`}>
                    {firstCounterpart ? (
                      <Typography.Text className="text-black font-semibold">
                        {firstCounterpart?.first_name} {firstCounterpart?.last_name}
                        {firstCounterpart?.nickname && !seeMore ? ',' : ''}{' '}
                        {firstCounterpart?.nickname === '' ? '' : `(${firstCounterpart?.nickname})`}
                        <span>
                          {moreCounterpart.length !== 0 && !seeMore && (
                            <Typography.Text className="text-black font-semibold mx-1">
                              {' '}
                              and{' '}
                            </Typography.Text>
                          )}
                        </span>
                        <span>
                          {moreCounterpart.length !== 0 && !seeMore && (
                            <Tooltip
                              arrow
                              title={
                                <div>
                                  {moreCounterpart.map((counterpart, index) => {
                                    return (
                                      <Typography.Text key={index} className="block">
                                        {counterpart.first_name} {counterpart.last_name}
                                        {counterpart.nickname ? ',' : ''}{' '}
                                        {counterpart.nickname === ''
                                          ? ''
                                          : `(${counterpart.nickname})`}
                                      </Typography.Text>
                                    )
                                  })}
                                </div>
                              }>
                              <Typography.Text className="text-[#0049EE] font-semibold">
                                {' '}
                                {moreCounterpart.length} others
                              </Typography.Text>
                            </Tooltip>
                          )}
                        </span>
                      </Typography.Text>
                    ) : (
                      <Typography.Text className="text-black font-semibold">-</Typography.Text>
                    )}
                    
                    {moreCounterpart.length !== 0 && seeMore && (
                      <Typography.Text className="text-black font-semibold">
                        ,{' '}
                        {moreCounterpart
                          .map((counterpart, index) => {
                            return (
                              <span key={index}>
                                {counterpart.first_name} {counterpart.last_name}{' '}
                                {counterpart.nickname === '' ? '' : `(${counterpart.nickname})`}
                              </span>
                            )
                          })
                          .reduce((prev, curr) => [prev, ', ', curr])}
                      </Typography.Text>
                    )}
                  </div>
                </div>
                
                {seeMore && (
                  <div className="flex items-start mb-4">
                    <div className="flex-1 flex items-center">
                      <Gavel fontSize="small" className={`cursor-pointer text-[#828282] mr-2`} />
                      <Typography.Text className="text-[#828282] font-normal">
                        {t('Judge/Court')}
                      </Typography.Text>
                    </div>
                    <div className="flex-[2_2_0%] flex items-center">
                      <div>
                        {detailData?.case_judges.length > 0
                          ? detailData?.case_judges.map((data, index) => {
                            return (
                              <Typography.Text className="text-black font-semibold" key={index}>
                                {index === 0 ? '' : ', '}
                                {data.name}
                              </Typography.Text>
                            )
                          })
                          : '-'}
                      </div>
                    </div>
                  </div>
                )}
                
                {seeMore && (
                  <div className="flex items-start mb-4">
                    <div className="flex-1 flex items-center">
                      <AccountCircle
                        fontSize="small"
                        className={`cursor-pointer text-[#828282] mr-2`}
                      />
                      <Typography.Text className="text-[#828282] font-normal">
                        Staff
                      </Typography.Text>
                    </div>
                    
                    <div className="flex-[2_2_0%] relative">
                      <div className="flex flex-wrap -space-x-1 ">
                        {detailData?.users.length > 0 ? (
                          detailData?.users.map((data, index) => {
                            const isError = imageStatus[data.avatar_url]
                            const additionalClass = index === 0 ? 'ml-[-3px]' : ''
                            return (
                              <Tooltip key={index} arrow title={`${data.name} ${data.surname}`}>
                                {data.avatar_url && !isError ? (
                                  <img
                                    src={data.avatar_url}
                                    className={`w-6 h-6 rounded-full border-[1px] border-solid border-[white] ${additionalClass}`}
                                    alt={`Avatar ${index}`}
                                  />
                                ) : (
                                  <div
                                    className={`w-6 h-6 rounded-full bg-[#BDBDBD] border-[1px] border-solid border-[white] flex items-center justify-center text-white font-normal text-xs ${additionalClass}`}>
                                    {data.name?.[0]}
                                    {data.surname?.[0]}
                                  </div>
                                )}
                              </Tooltip>
                            )
                          })
                        ) : (
                          <Typography.Text className="text-black font-semibold">-</Typography.Text>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="flex items-center pl-6">
              <Typography.Text
                className="text-[#828282] font-medium mr-4 hover:pointer"
                onClick={() => setSeeMore((prevState) => !prevState)}>
                {seeMore ? t('see less') : t('see more')}
              </Typography.Text>
              <div className="h-[0.6px] flex-1 bg-[#828282]" />
            </div>
          </div>
          
          <div className="px-6 mt-4">
            <div className="flex items-center justify-between mb-2">
              <Tabs
                textColor="inherit"
                sx={{ minHeight: 0 }}
                TabIndicatorProps={{
                  style: { borderRadius: 20, height: 5, backgroundColor: '#0049EE' }
                }}
                value={tabTable}
                onChange={(_, newValue) => {
                  handleChangeTab(newValue)
                }}>
                <Tab
                  disableRipple
                  fullWidth={false}
                  sx={{
                    alignItems: 'start',
                    minWidth: 0,
                    fontSize: 24,
                    fontFamily: 'Inter',
                    fontWeight: 400,
                    textTransform: 'none',
                    marginRight: 2,
                    padding: 0
                  }}
                  label="Chat"
                  value={0}
                />
                <Tab
                  disableRipple
                  fullWidth={false}
                  sx={{
                    alignItems: 'start',
                    minWidth: 0,
                    fontSize: 24,
                    fontFamily: 'Inter',
                    fontWeight: 400,
                    textTransform: 'none',
                    padding: 0,
                    marginRight: 2
                  }}
                  label={t('breadcrumb.documents')}
                  value={1}
                />
                <Tab
                  disableRipple
                  fullWidth={false}
                  sx={{
                    alignItems: 'start',
                    minWidth: 0,
                    fontSize: 24,
                    fontFamily: 'Inter',
                    fontWeight: 400,
                    textTransform: 'none',
                    padding: 0
                  }}
                  label={t('Processed documents')}
                  value={2}
                />
              </Tabs>
              
              {tabTable === 0 && (
                <div className="flex items-center">
                  {openSearch ? (
                    <ClickAwayListener onClickAway={() => {
                      if (tableState.search.length === 0) {
                        setOpenSearch(false)
                      }
                    }}>
                      <div>
                        <SearchInput
                          variant="outlined"
                          onSearch={debouncedResults}
                          onClear={() => {
                            onSearch({ target: { value: '' } })
                            setOpenSearch(false)
                          }}
                        />
                      </div>
                    </ClickAwayListener>
                  ) : (
                    <Search
                      className={`cursor-pointer hover:text-[#0049EE]`}
                      onClick={openSearchBox}
                    />
                  )}
                  
                  <div onClick={openFilterChatModal} className="flex items-center">
                    <FilterListOutlined
                      className={`cursor-pointer hover:text-[#0049EE] ml-4 ${
                        !filterChatModal.data.fromDate && 'mr-4'
                      }`}
                    />
                    {filterChatModal.data.fromDate && (
                      <div
                        className="text-[12px] font-semibold text-white w-[20px] h-[20px] rounded-full bg-[#0049EE] flex items-center justify-center ml-1.5 mr-4">
                        1
                      </div>
                    )}
                  </div>
                  
                  {(chatList.length > 0 || tableState.search.length > 0) && (
                    <CustomButton onClick={openNewChatModal} className="w-[190px]">
                      <div className="font-normal flex items-center ">
                        {t('New Chat')}
                        <Add className="ml-1" />
                      </div>
                    </CustomButton>
                  )}
                </div>
              )}
              
              {tabTable === 1 && (
                <div className="flex items-center">
                  {openSearch ? (
                    <ClickAwayListener onClickAway={() => {
                      if (tableState.search.length === 0) {
                        setOpenSearch(false)
                      }
                    }}>
                      <div>
                        <SearchInput
                          variant="outlined"
                          onSearch={debouncedResults}
                          onClear={() => {
                            onSearch({ target: { value: '' } })
                            setOpenSearch(false)
                          }}
                        />
                      </div>
                    </ClickAwayListener>
                    ) : (
                    <Search
                      className={`cursor-pointer hover:text-[#0049EE]`}
                      onClick={openSearchBox}
                    />
                  )}
                  <div onClick={openFilterChatModal} className="flex items-center">
                    <FilterListOutlined
                      className={`cursor-pointer hover:text-[#0049EE] ml-4 ${
                        !filterChatModal.data.fromDate && 'mr-4'
                      }`}
                    />
                    {filterChatModal.data.fromDate && (
                      <div
                        className="text-[12px] font-semibold text-white w-[20px] h-[20px] rounded-full bg-[#0049EE] flex items-center justify-center ml-1.5 mr-4">
                        1
                      </div>
                    )}
                  </div>
                  
                  {(documentList.length > 0 || tableState.search.length > 0) && (
                    <CustomButton onClick={() => uploadRef.current?.click()} className="w-[250px]">
                      <div className="font-normal flex items-center">
                        {t('Add document')}
                        <Add className="ml-1" />
                      </div>
                    </CustomButton>
                  )}
                </div>
              )}
              
              {tabTable === 2 && (
                <div className="flex items-center">
                  {openSearch ? (
                    <ClickAwayListener onClickAway={() => {
                      if (tableState.search.length === 0) {
                        setOpenSearch(false)
                      }
                    }}>
                      <div>
                        <SearchInput
                          variant="outlined"
                          onSearch={debouncedResults}
                          onClear={() => {
                            onSearch({ target: { value: '' } })
                            setOpenSearch(false)
                          }}
                        />
                      </div>
                    </ClickAwayListener>
                  ) : (
                    <Search
                      className={`cursor-pointer hover:text-[#0049EE]`}
                      onClick={openSearchBox}
                    />
                  )}
                  <div onClick={openFilterChatModal} className="flex items-center">
                    <FilterListOutlined
                      className={`cursor-pointer hover:text-[#0049EE] ml-4 ${
                        !filterChatModal.data.fromDate && 'mr-4'
                      }`}
                    />
                    {filterChatModal.data.fromDate && (
                      <div
                        className="text-[12px] font-semibold text-white w-[20px] h-[20px] rounded-full bg-[#0049EE] flex items-center justify-center ml-1.5 mr-4">
                        1
                      </div>
                    )}
                  </div>
                  
                  {(processedList.length > 0 || tableState.search.length > 0) && (
                    <CustomButton onClick={() => openNewProcessedModal(true)} className="w-[250px]">
                      <div className="font-normal flex items-center">
                        {t('Create processed document')}
                        <Add className="ml-1" />
                      </div>
                    </CustomButton>
                  )}
                </div>
              )}
            </div>
            <CustomTabPanel value={tabTable} index={0}>
              <ListChat
                isLoading={listData.isLoading && !chatList.length}
                height={layoutInfo - infoHeaderLayout}
                data={chatList}
                isSearching={tableState.search.length > 0}
                onClickRow={navigateToChat}
                onRename={openRenameChatModal}
                rowsPerPage={tableState.limit}
                page={tableState.page}
                total={tableState.total}
                onRowsPerPageChange={onRowsPerPageChange}
                onPageChange={onPageChange}
                handleSort={handleChangeSort}
                onDelete={handleDeleteChat}
                openNewChatModal={openNewChatModal}
              />
            </CustomTabPanel>
            <CustomTabPanel value={tabTable} index={1}>
              <ListDocument
                isLoading={listData.isLoading && !documentList.length}
                isSearching={tableState.search.length > 0}
                height={layoutInfo - infoHeaderLayout}
                data={documentList}
                rowsPerPage={tableState.limit}
                page={tableState.page}
                total={tableState.total}
                navigateToDoc={navigateToDoc}
                onRowsPerPageChange={onRowsPerPageChange}
                onPageChange={onPageChange}
                handleSort={handleChangeSort}
                onRename={openRenameDocumentModal}
                goToTheChatFromDocument={goToTheChatFromDocument}
                onDelete={handleDeleteDocument}
                onDownload={handleDownloadDocument}
                onExport={handleExportDpcument}
                uploadNewDocument={() => uploadRef.current?.click()}
              />
            </CustomTabPanel>
            <CustomTabPanel value={tabTable} index={2}>
              <ListProcessed
                isLoading={listData.isLoading && !processedList.length}
                isSearching={tableState.search.length > 0}
                height={layoutInfo - infoHeaderLayout}
                data={processedList}
                rowsPerPage={tableState.limit}
                page={tableState.page}
                total={tableState.total}
                navigateToDoc={navigateToProcessedDoc}
                onRowsPerPageChange={onRowsPerPageChange}
                onPageChange={onPageChange}
                handleSort={handleChangeSort}
                onRename={openRenameProcessedDocumentModal}
                onDelete={handleDeleteProcessedDocument}
                onDownload={handleDownloadProcessedDocument}
                onExport={handleExportProcessedDpcument}
                uploadNewDocument={openNewProcessedModal}
              />
            </CustomTabPanel>
          </div>
        </div>
        <NotesContainer
          setTabNote={setTabNote}
          tabNote={tabNote}
          height={layoutInfo - 20}
          caseId={caseId}
          caseName={detailData?.name}
        />
      </div>
      {filterChatModal.show && (
        <ChatFilterModal
          tabTable={tabTable}
          show={filterChatModal.show}
          data={filterChatModal.data}
          onClose={closeFilterChatModal}
          onSubmit={handleSubmitFilter}
          cancelFilter={cancelFilter}
        />
      )}
      {modalCase.show && (
        <CaseModal
          show={modalCase.show}
          data={detailData ?? {}}
          regulations={regulationsData?.data}
          casesStatus={casesStatus?.data?.resData?.data}
          type={modalCase.type}
          onClose={() =>
            setModalCase((prev) => {
              return {
                ...prev,
                show: false
              }
            })
          }
          onSubmit={handleSubmitCaseModal}
        />
      )}
      {renameModal.show && (
        <RenameModal
          title={renameModal.title}
          onClose={closeRenameModal}
          show={renameModal.show}
          data={dataRename}
          onSubmit={renameModal.onClick}
        />
      )}
      {exportNotesModal.show && (
        <ExportNotesModal
          show={exportNotesModal.show}
          onExport={handleExportNotes}
          closeModal={closeExportNotesModal}
          isLoading={exportNotesMutation.isLoading}
        />
      )}
      {deleteCaseModal.show && (
        <DeleteCaseModal
          show={deleteCaseModal.show}
          onDelete={handleDeleteCase}
          closeModal={closeDeleteCaseModal}
          isLoading={deleteCaseMutation.isLoading}
        />
      )}
      {newChatModal && (
        <NewChatModal
          data={{ caseName: detailData?.name, caseId: detailData?.id }}
          show={newChatModal}
          closeModal={closeNewChatModal}
        />
      )}
      {newProcessedModal && (
        <NewProcessedModal
          handleProcessDocument={(type, id) => {
            handleProcessDocument(type, id)
            closeNewProcessedModal()
          }}
          handleProcessAllDocument={(id) => {
            handleProcessAllDocument(id)
            closeNewProcessedModal()
          }}
          data={{ caseName: detailData?.name, caseId: detailData?.id }}
          show={newProcessedModal}
          closeModal={closeNewProcessedModal}
        />
      )}
      
      <AddDocumentModal
        data={{ caseName: detailData?.name, caseId: detailData?.id }}
        files={documentModal.files}
        show={documentModal.show}
        refetch={listData.refetch}
        closeModal={() =>
          setDocumentModal((prev) => {
            return {
              ...prev,
              show: false,
              files: []
            }
          })
        }
      />
      <input
        className={`hidden`}
        type="file"
        id="file"
        accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/jpeg,image/png,.pdf,text/plain"
        ref={uploadRef}
        style={{ display: 'none' }}
        multiple
        onChange={(e) => {
          if (e.target.files.length > 0) {
            const validatedFiles = []
            for (let i = 0; i < e.target.files.length; i++) {
              if (e.target.files[i].size > 20971520) {
                showErrorMessage(`File ${e.target.files[i].name} supera i 20MB`)
              } else {
                validatedFiles.push(e.target.files[i])
              }
            }
            const newFiles = [...validatedFiles]
            
            setDocumentModal({
              show: true,
              files: newFiles
            })
          }
        }}
      />
    </div>
  )
}

export default CasesPage
