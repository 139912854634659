import { deleteCaseById } from 'api'
import { useState } from 'react'
import { useMutation } from 'react-query'
import { showErrorMessage, showSuccessMessage } from 'utils/showMessage'
import useAuth from './useAuth'
import { useTranslation } from 'react-i18next'

const useDeleteCase = (refetch) => {
  const { t } = useTranslation();
  const { getAccessToken } = useAuth()
  const [deleteCaseModal, setDeleteCaseModal] = useState({ show: false, caseDetail: null })
  const deleteCaseMutation = useMutation({
    mutationKey: ['deleteCase'],
    mutationFn: (caseId) => deleteCaseById(getAccessToken(), caseId),
    onSuccess: () => {
      showSuccessMessage(t('success messages.Case deleted'))
      refetch()
      closeDeleteCaseModal()
    },
    onError: () => {
      showErrorMessage(t('error messages.The case could not be deleted'))
    }
  })

  function openDeleteCaseModal(caseDetail) {
    setDeleteCaseModal((prev) => ({ ...prev, caseDetail: caseDetail, show: true }))
  }
  function closeDeleteCaseModal() {
    setDeleteCaseModal((prev) => ({ ...prev, caseDetail: null, show: false }))
  }
  function handleDeleteCase() {
    deleteCaseMutation.mutate(deleteCaseModal.caseDetail?.id)
  }
  return {
    deleteCaseMutation,
    deleteCaseModal,
    openDeleteCaseModal,
    closeDeleteCaseModal,
    handleDeleteCase
  }
}

export default useDeleteCase
