import ModalUser from 'components/molecules/user/ModalUser'
import React, { useEffect, useMemo, useRef, useState } from 'react'

import { Breadcrumb } from 'antd'
import SearchInput from 'components/atoms/SearchInput'
import { debounce } from 'lodash'
import { FilterListOutlined } from '@mui/icons-material'
import NewChatFilterModal from './NewChatFilterModal'
import { useAuth } from 'hooks'
import { useNavigate } from 'react-router-dom'
import OtherCasesFilterModal from './OtherCasesFilterModal'

import useSearchOtherCases from 'hooks/new-chat/useSearchOtherCases'
import SelectProcessedDocsNewChatTable from './newChatModalComponent/SelectProcessedDocsNewChatTable'

import useUploadDocsNewChat from 'hooks/new-chat/useUploadDocsNewChat'
import UploadDocsNewChatFilterModal from './UploadDocsNewChatFilterModal'
import { useTranslation } from 'react-i18next'
import useCreateNewProcessedDocument from 'hooks/new-chat/useCreateNewProcessedDocument'
import { Tooltip } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { getDocumentListNewChat } from 'api'
import { showErrorMessage } from 'utils/showMessage'
import _ from 'lodash'
const INITIAL_DOCS_TABLE_STATE = {
  sort: '-created_at',
  sort_order: 'desc',
  search: ''
}
const INITIAL_OTHER_CASES_TABLE_STATE = {
  sort: '-updated_at',
  sort_order: 'desc',
  search: ''
}
const CONST_CASES = 'CONST_CASES'
const NewProcessedModal = ({ show, data, closeModal, handleProcessDocument = () => { },handleProcessAllDocument= () => { } }) => {
  const { t } = useTranslation();
  const { getAccessToken } = useAuth()
  const [currentContent, setCurrentContent] = useState({ ...data })
  const [tableState, setTableState] = useState(INITIAL_DOCS_TABLE_STATE)
  const intervalRef = useRef(null);

  const {
    documentModal,
    uploadDocFilterState,
    openUploadFilterModal,
    closeUploadFilterModal,
    handleResetUploadFilter,
    handleSubmitUploadFilter,
  } = useUploadDocsNewChat(data, tableState)


  const { documentListByCase,
    selectedDocuments,
    isFetchingDocumentByCase,
    filterModal,
    filterState,
    openFilterModal,
    closeFilterModal,
    handleSubmitFilter,
    handleResetFilterState,
    handleChangeSelectedDocuments,
    refetchingDocumentByCase,
    resetSelectedDocument } = useCreateNewProcessedDocument(currentContent, isContentSpecificCase, documentModal.show, tableState,show)


  const {
    otherCasesFilterState,
    casesStatus,
    regulationsData,
    openFilterOtherCases,
    closeFilterOtherCases,
    handleSubmitFilterOtherCases,
    handleOtherCasesResetFilterState
  } = useSearchOtherCases(tableState, isContentSearchOtherCases)

  function isContentSpecificCase() {
    return currentContent !== CONST_CASES
  }

  function isContentSearchOtherCases() {
    return currentContent === CONST_CASES
  }

  // Breadcrumbs
  const [breadcrumbItems, setBreadcrumbItems] = useState([
    {
      title: t('breadcrumb.cases')
    },
    {
      title: currentContent?.caseName
    }
  ])

  function handleBreadcrumbNavigation(value) {
    let newbreadItems = []
    if (value === CONST_CASES) {
      newbreadItems.push({
        title: t('breadcrumb.cases')
      })
    } else {
      newbreadItems = [...breadcrumbItems]
      newbreadItems.push({
        // Title Specific Case
        title: value?.caseName
      })
    }
    setTableState(
      isContentSearchOtherCases() ? INITIAL_OTHER_CASES_TABLE_STATE : INITIAL_DOCS_TABLE_STATE
    )
    resetSelectedDocument()
    handleResetFilterState()
    setCurrentContent(value)
    setBreadcrumbItems(newbreadItems)
  }

  const handleSort = (column, sortDirection) => {
    setTableState((prevState) => ({
      ...prevState,
      sort: `${sortDirection === 'desc' ? '-' : ''}${column?.id}`,
      sort_order: sortDirection
    }))
  }

  //Search
  const handleSearch = (e) => {
    setTableState((prev) => ({ ...prev, search: e.target.value }))
  }

  useEffect(() => {
    return () => {
      debouncedResults.cancel()
    }
  })

  const debouncedResults = useMemo(() => {
    return debounce(handleSearch, 300)
  }, [])

  const totalFilterActive = Object?.entries(
    isContentSearchOtherCases()
      ? otherCasesFilterState.data
      : documentModal.show
        ? uploadDocFilterState.data
        : filterState
  )?.filter(([, { value }]) => value !== null)?.length

  function itemRenderBreadcrumb(currentRoute, _, items) {
    const isLast = currentRoute?.title === items[items.length - 1]?.title
    return isLast ? (
      <span>{currentRoute.title}</span>
    ) : (
      <span
        className={`${selectedDocuments ? 'cursor-default' : 'cursor-pointer'}`}
        onClick={() => {
          return selectedDocuments > 0 ? false : handleBreadcrumbNavigation(CONST_CASES)
        }}>
        {currentRoute.title}
      </span>
    )
  }

  const documentListByCaseRef = useRef(documentListByCase);

  useEffect(() => {
    documentListByCaseRef.current = documentListByCase;
  }, [documentListByCase]);


  const fetchDocuments = () => {
    getDocumentListNewChat(getAccessToken(), currentContent.caseId, filterState, tableState).then(data => {
      if(!arraysAreEqual(data?.resData?.data || [], documentListByCaseRef.current || [])){
        refetchingDocumentByCase();
      }
    }, err => {
      showErrorMessage(t('error messages.Failed fetch document list, Please refresh the page'));
    })
  };
  
  function arraysAreEqual(array1, array2) {
    const statusArray1 = array1.map(item => ({ id: item.id, status: item.status, sub_status: item.sub_status,is_eligible:item.is_eligible }));
    const statusArray2 = array2.map(item => ({ id: item.id, status: item.statuss, sub_status: item.sub_status,is_eligible:item.is_eligible }));
    return _.isEqual(statusArray1, statusArray2);
  }

  useEffect(() => {

    if (show) {
      fetchDocuments();
      intervalRef.current = setInterval(fetchDocuments, 30000);
    }


    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [show, refetchingDocumentByCase]);

  return (
    <ModalUser
      titleComponent={
        <div className="flex items-center pb-2">
          <span className='mr-2'>{t("New processed document")}</span>
          <Tooltip title={'testo??'}>
            <InfoOutlinedIcon className='mt-1' />
          </Tooltip>


        </div>
      }
      show={show}
      width={900}
      isCloseIconVisible={false}
      onClose={closeModal}
      closable={false}>
      {/* Breadcrumb */}
      <div className="flex justify-between items-center">
        <Breadcrumb
          itemRender={itemRenderBreadcrumb}
          items={documentModal.show ? [{ title: currentContent.caseName }] : breadcrumbItems}
        />
        {/* Filter & Search */}
        <div className="flex items-center gap-4">
          {/* Search Input */}
          <SearchInput variant="outlined" onSearch={debouncedResults} />
          {/* Filter Wrapper */}
          <div
            className="flex gap-1 justify-center items-center"
            onClick={
              isContentSearchOtherCases()
                ? openFilterOtherCases
                : documentModal.show
                  ? openUploadFilterModal
                  : openFilterModal
            }>
            <FilterListOutlined
              className={`cursor-pointer hover:text-[#0049EE] ${totalFilterActive > 0 && 'text-[#0049EE]'
                }`}
            />
            {/* Badge Total Count */}
            {totalFilterActive > 0 && (
              <div className="w-5 h-5 bg-[#0049EE] px-[6px] flex justify-center items-center rounded-full text-white">
                {totalFilterActive}
              </div>
            )}
          </div>
        </div>
      </div>

      <SelectProcessedDocsNewChatTable
        isFetchingDocumentByCase={isFetchingDocumentByCase}
        handleSort={handleSort}
        totalFilterActive={totalFilterActive}
        tableState={tableState}
        handleChangeSelectedDocuments={handleChangeSelectedDocuments}
        documentListByCase={documentListByCase}
        selectedDocuments={selectedDocuments}
        closeModal={closeModal}
        handleProcessDocument={(type,docId) => {
          handleProcessDocument(type,docId)
        }}
        handleProcessAllDocument={(docId) => {
          handleProcessAllDocument(docId)
        }}
      />

      {filterModal && (
        <NewChatFilterModal
          onClose={closeFilterModal}
          data={filterState}
          show={filterModal}
          resetFilter={handleResetFilterState}
          onSubmit={handleSubmitFilter}
        />
      )}

      {isContentSearchOtherCases() && otherCasesFilterState.show ? (
        <OtherCasesFilterModal
          closeModal={closeFilterOtherCases}
          data={otherCasesFilterState.data}
          show={otherCasesFilterState.show}
          onSubmit={handleSubmitFilterOtherCases}
          resetFilter={handleOtherCasesResetFilterState}
          regulations={regulationsData?.data}
          casesStatus={casesStatus?.data?.resData?.data}
        />
      ) : null}

      {uploadDocFilterState.show ? (
        <UploadDocsNewChatFilterModal
          data={uploadDocFilterState.data}
          show={uploadDocFilterState.show}
          onSubmit={handleSubmitUploadFilter}
          resetFilter={handleResetUploadFilter}
          closeModal={closeUploadFilterModal}
        />
      ) : null}


    </ModalUser>
  )
}

export default NewProcessedModal
