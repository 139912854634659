import { Button } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import FilterListIcon from '@mui/icons-material/FilterList'
const FilterButton = ({ onClick,filterCount }) => {
  const { t, i18n } = useTranslation();
  return (
    <Button
      ghost
      className="flex justify-between items-center min-w-[200px] min-h-[39.65px] text-sm text-[#666666] bg-[#FFFFFF0F] hover:"
      onClick={onClick}>
      <span className="font-manrope">{t("Filters")}</span>
      {filterCount ? <div className='filter-count'>{filterCount}</div>  : <FilterListIcon />}
    </Button>
  )
}

export default FilterButton
