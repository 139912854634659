import React, { useEffect, useRef } from 'react'
import { IcChatEmpty } from 'assets/icons'
import UserResponseItem from 'components/molecules/user/chat/UserResponseItem'
import RegulationBotResponseItem from 'components/molecules/user/regulation/RegulationBotResponseItem'
import { useTranslation } from 'react-i18next'

const RegulationChatHistoryList = ({ chatHistory = [], onRegenerateMessage, isLoading }) => {
  const bottomRef = useRef(null)
  const { t } = useTranslation()
  useEffect(() => {
    bottomRef?.current?.scrollIntoView({ behavior: 'smooth' })
  }, [chatHistory.length, chatHistory])

  return (
    <>
      {chatHistory && chatHistory.length > 0 ? (
        <div className="flex flex-col w-full h-screen">
          {chatHistory.map((chatItem, index) => {
            if (chatItem?.role == 'user') {
              return (
                <UserResponseItem
                  type="regulation"
                  key={`user-response-${index}`}
                  text={chatItem?.content}
                />
              )
            }
            if (chatItem?.role == 'assistant') {
              return (
                <RegulationBotResponseItem
                  isLoading={isLoading}
                  onRegenerate={onRegenerateMessage}
                  isLastIndex={index == chatHistory.length - 1}
                  key={`bot-response-${index}`}
                  text={chatItem?.content}
                  item={chatItem}
                />
              )
            }
          })}
        </div>
      ) : (
        <div className="flex flex-col w-full h-screen justify-center items-center">
          <IcChatEmpty className={'drop-shadow-lg'} width={'150px'} />
          <span className="text-2xl font-normal -mt-14 text-black">{t('How can I help you?')}</span>
          
        </div>
      )}
      <div ref={bottomRef}></div>
    </>
  )
}

export default RegulationChatHistoryList
