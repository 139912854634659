import { IcChatEmpty } from 'assets/icons'
import BotResponseItem from 'components/molecules/user/chat/BotResponseItem'
import UserResponseItem from 'components/molecules/user/chat/UserResponseItem'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

const ChatHistoryList = ({
  chatHistory = [],
  onRegenerateMessage,
  isLoading,
  onAddNote = () => {}
}) => {
  const { state } = useLocation()
  const { t } = useTranslation()
  const bottomRef = useRef(null)
  useEffect(() => {
    bottomRef?.current?.scrollIntoView({ behavior: 'smooth' })
  }, [chatHistory.length, chatHistory])
  return (
    <>
      {chatHistory && chatHistory.length > 0 ? (
        <>
          {chatHistory.map((chatItem, index) => {
            if (chatItem?.role == 'user') {
              return (
                <UserResponseItem
                  key={`user-response-${index}`}
                  type="chat"
                  text={chatItem?.content}
                  chatItem={chatItem}
                  chatData={state.chatData}
                />
              )
            }
            if (chatItem?.role == 'assistant') {
              return (
                <BotResponseItem
                  key={`bot-response-${index}`}
                  chatData={state.chatData}
                  chatItem={chatItem}
                  onAddNote={onAddNote}
                  isLoading={isLoading}
                  onRegenerate={onRegenerateMessage}
                  isLastIndex={index == chatHistory.length - 1}
                  text={chatItem?.content}
                />
              )
            }
          })}
        </>
      ) : (
        <div className="flex flex-col w-full h-screen justify-center items-center">
          <IcChatEmpty className={'drop-shadow-lg'} />
          <span className="text-2xl font-medium -mt-10 text-black">{t('How can I help you?')}</span>
          <span className="text-sm font-medium w-[350px] mt-2 text-center text-[#595961] font-normal">{t('How can i help you subtitle')}</span>
        </div>
      )}
      <div ref={bottomRef}></div>
    </>
  )
}

export default ChatHistoryList
