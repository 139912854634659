import { apiGet } from 'api/api-get'
import { apiPost } from 'api/api-post'
import { urls } from 'api/api-url'

export const getDetailDocument = (token, documentId) => {
  return apiGet({ token: token, url: `${urls.documents.documents}/${documentId}` })
}

export const getDetailProcessedDocument = (token, documentId) => {
  return apiGet({ token: token, url: `${urls.detailCase.processedList}/documents/${documentId}` })
}

export const confirmDocument = (token, data) => {
  return apiPost({ token: token, url: `${urls.documents.documents}/${urls.documents.uploadConfirm}`, data: data })
}

export const processDocumentApi = (token, caseId, document_type,document_id) => {
  
  
  return apiPost({
    token: token,
    url: `${urls.documents.documents}/${document_id}/generate/processed/document`,
    data: {
      case_id: caseId,
      document_type: document_type
    }
  })
}