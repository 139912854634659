import { zodResolver } from '@hookform/resolvers/zod'
import { Box } from '@mui/material'
import CustomButton from 'components/atoms/CustomButton'
import CountrySelectFields from 'components/molecules/CountrySelectFields'
import PhoneNumberFields from 'components/molecules/PhoneNumberFields'
import TextFields from 'components/molecules/TextFields'
import i18next from 'i18next'
import { phoneNumberObject } from 'lib/schema'
import React from 'react'
import { useForm } from 'react-hook-form'
import { checkPhoneNumber } from 'utils/checkPhoneNumber'
import { z } from 'zod'
import { useTranslation } from 'react-i18next'

const schema = z.object({
  email: z.string(),
  phoneNumber: phoneNumberObject
    .refine((data) => data.nationalNumber !== '', {
      message: 'Telephone Number is required'
    })
    .or(z.string().min(1, { message: 'Telephone Number is required' })),
  address: z.string(),
  country: z
    .object({
      id: z.number(),
      name: z.string()
    })
    .optional()
    .or(z.undefined())
    .or(z.null()),
  municipality: z.string(),
  zip_code: z.string(),
  province: z.string()
})
const ContactsForm = ({ data, countries, onUpdate }) => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    formState: { errors, isValid },
    control
  } = useForm({
    defaultValues: {
      email: data?.email ?? '',
      phoneNumber: data?.phoneNumber ?? '',
      country: data?.country ?? undefined,
      address: data?.address ?? '',
      municipality: data?.municipality ?? '',
      zip_code: data?.zip_code ?? '',
      province: data?.province ?? ''
    },
    values: {
      email: data?.email ?? '',
      phoneNumber: data?.phoneNumber ?? '',
      country: data?.country ?? undefined,
      address: data?.address ?? '',
      municipality: data?.municipality ?? '',
      zip_code: data?.zip_code ?? '',
      province: data?.province ?? ''
    },
    resolver: zodResolver(schema)
  })

  const processForm = async (form) => {
    let newForms = checkPhoneNumber(form)
    if (form?.country) {
      newForms = {
        ...newForms,
        country: form?.country?.id
      }
    }
    onUpdate(newForms)
  }

  return (
    <Box
      noValidate
      component="form"
      className="flex flex-col gap-6 max-w-72"
      onSubmit={handleSubmit(processForm)}>
      <TextFields
        control={control}
        theme="admin"
        label={'Email'}
        placeholder={'input Email'}
        errors={errors}
        name={'email'}
      />
      <PhoneNumberFields
        control={control}
        theme="admin"
        label={t('Telephone Number')}
        placeholder={'input phone number'}
        errors={errors}
        name={'phoneNumber'}
      />
      <CountrySelectFields
        control={control}
        countries={countries ?? []}
        fromBE={true}
        theme="admin"
        label={t('Country')}
        errors={errors}
        name={'country'}
      />
      <TextFields
        control={control}
        theme="admin"
        label={t('Address')}
        errors={errors}
        name={'address'}
      />
      <TextFields
        control={control}
        theme="admin"
        label={t('Municipality')}
        errors={errors}
        name={'municipality'}
      />
      <div className="flex gap-4">
        <TextFields
          type="number"
          control={control}
          theme="admin"
          label={t('ZIP CODE')}
          errors={errors}
          name={'zip_code'}
        />
        <TextFields
          control={control}
          theme="admin"
          label={t('Province')}
          errors={errors}
          name={'province'}
        />
      </div>
      <CustomButton className={'!max-w-40'} disabled={!isValid} type="submit" buttonColor="primary">
      {t('Save Changes')}
      </CustomButton>
    </Box>
  )
}

export default ContactsForm
