import React, { useState, useRef, useEffect } from 'react'
import {
  ChatBubbleOutline,
  InsertDriveFileOutlined,
  StickyNote2Outlined
} from '@mui/icons-material'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import CloseIcon from '@mui/icons-material/Close'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { Typography, Input } from 'antd'
import { useMutation, useQueryClient } from 'react-query'
import { deleteNote, updateDataNote, createNote } from 'api'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { showSuccessMessage, showErrorMessage } from 'utils/showMessage'
import { getNext5Minute } from 'utils/date'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import { errorObj } from 'utils/handleError'
import { useTranslation } from 'react-i18next'
import { NOTE_TYPE } from 'constant'

const NoteCard = ({
  text,
  note_type,
  created_at,
  chat,
  document,
  edit,
  todo_date,
  setNoteData,
  id,
  isNewData,
  caseId,
  needToBeSaved
}) => {
  const { t } = useTranslation()
  const { TextArea } = Input
  const [idValue, setIdValue] = useState(null)
  const [height, setHeight] = useState(0)
  const ref = useRef(null)

  const queryClient = useQueryClient()
  const token = useSelector((state) => state.user.access_token)

  useEffect(() => {
    if (needToBeSaved) {
      handleSave()
    }
  }, [needToBeSaved])

  const clickToEdit = (id) => {
    setIdValue(id)
    ref?.current?.clientHeight && setHeight(ref?.current?.clientHeight)
    setNoteData((prev) => {
      return prev.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            edit: true,
            previousText: item.edit ? item.previousText : item.text
          }
        } else {
          return {
            ...item,
            edit: false
          }
        }
      })
    })
  }

  const onChangeText = (id, e) => {
    setNoteData((prev) => {
      return prev.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            text: e.target.value,
            isTouched: item.previousText !== e.target.value
          }
        } else {
          return item
        }
      })
    })
  }

  const onChangetodoDate = (value) => {
    setNoteData((prev) => {
      return prev.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            todo_date: value
          }
        } else {
          return item
        }
      })
    })
  }

  const onChangeTimePicker = (value) => {
    const newTime = moment(value).format('HH:mm')
    const timeZone = moment(value).format('Z')
    setNoteData((prev) => {
      return prev.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            todo_date: moment(item.todo_date).format('YYYY-MM-DD') + 'T' + newTime + timeZone
          }
        } else {
          return item
        }
      })
    })
  }

  const deletingNote = useMutation({
    mutationKey: ['delete-note'],
    mutationFn: (id) => deleteNote(token, id),
    onSuccess: () => {
      showSuccessMessage(t('success messages.note deleted success'))
      queryClient.invalidateQueries(['note-list-insight'])
      queryClient.invalidateQueries(['note-list-todo'])
    },
    onError: (err) => {
      const obj = errorObj(err)
      showErrorMessage(obj.resData.errors[0].message)
    }
  })

  const onClickDelete = (id) => {
    if (isNewData) {
      setNoteData((prev) => {
        return prev.filter((item) => item.id !== id)
      })
    } else {
      deletingNote.mutate(id)
    }
  }
  const updatingDataNote = useMutation({
    mutationKey: ['update-data-note'],
    mutationFn: (data) => updateDataNote(token, idValue, data),
    onSuccess: () => {
      showSuccessMessage(t('success messages.note updated success'))
      queryClient.invalidateQueries(['note-list-insight'])
      queryClient.invalidateQueries(['note-list-todo'])
    },
    onError: async (err) => {
      const obj = errorObj(err)
      showErrorMessage(obj.resData.errors[0].message)

      queryClient.invalidateQueries(['note-list-insight'])
      queryClient.invalidateQueries(['note-list-todo'])
    }
  })

  const createDataNote = useMutation({
    mutationKey: ['create-data-note'],
    mutationFn: (data) => createNote(token, { case_id: caseId, ...data }),
    onSuccess: () => {
      showSuccessMessage(t('success messages.note created success'))
      queryClient.invalidateQueries(['note-list-insight'])
      queryClient.invalidateQueries(['note-list-todo'])
    },
    onError: (err) => {
      const obj = errorObj(err)
      showErrorMessage(obj.resData.errors[0].message)

      if (isNewData) {
        setNoteData((prev) => {
          return prev.filter((item) => item.id !== idValue)
        })
      }
    }
  })

  const handleSave = () => {
    
    
    const todo_date_formatted =
      note_type === NOTE_TYPE.TODO && todo_date
        ? moment(todo_date).format('YYYY-MM-DDTHH:mm:ssZ')
        : undefined

    if (!isNewData) {
      if (text && text.length > 0) {
        updatingDataNote.mutate({
          text,
          todo_date: todo_date_formatted
        })
      } else {
        deletingNote.mutate(id)
      }
    } else {
      
      if(text && text.length > 0){
        createDataNote.mutate({
          text,
          todo_date: todo_date_formatted,
          note_type,
          chat_id: chat?.id,
          document_id: document?.id
        })
      }
    
    }

    setNoteData((prev) => {
      return prev.map((item) => {
        if (item.id === idValue) {
          return {
            ...item,
            edit: false,
            needToBeSaved: false,
            isTouched: false,
            previousText: undefined
          }
        } else {
          return item
        }
      })
    })
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSave()
    }
  }

  return (
    <div
      style={{
        minHeight: '105px',
      }}
      className={` w-full ${edit ? 'bg-white' : 'bg-[#EDEDEF]'} p-4 rounded-lg mb-3 flex flex-col justify-between
       ${edit
          ? ''
          : 'group/item border-solid border-[#EDEDEF] border hover:border-[#0049EE] hover:bg-blue-100'
        }`}
      onClick={() => clickToEdit(id)}>
      <div className="flex justify-between">
        {edit && idValue === id && (
          <TextArea
            rows={4}
            className="border-0 outline-0 focus:outline-0 focus:outline-none focus:border-0 focus:ring-0 "
            value={text}
            onChange={(e) => onChangeText(id, e)}
            onKeyDown={handleKeyDown}
            style={{
              resize: 'none',
              height: '50px',
              
            }}
          />
        )}
        {!edit && (
          <Typography.Text className={`mb-4 ${text ? 'text-black' : 'text-[#828282]'}  block text-[13px] font-normal`} ref={ref}>
            {!text && note_type === NOTE_TYPE.FREE ? t('New note') :
            !text && note_type === NOTE_TYPE.TODO ? t('New todo') : text}
          </Typography.Text>
        )}
        <CloseIcon
          className={edit ? 'cursor-pointer' : 'cursor-pointer invisible group-hover/item:visible'}
          onClick={() => onClickDelete(id)}
        />
      </div>

      <div className="flex items-center justify-between">
        <div className="flex items-center">
          {note_type === NOTE_TYPE.CHAT && (
            <ChatBubbleOutline
              fontSize="small"
              className="text-[#0049EE] bg-[#C2D4FF] text-[11px] mr-1 rounded-[2px] p-0.5"
            />
          )}
          {note_type === NOTE_TYPE.DOCUMENT && (
            <InsertDriveFileOutlined
              fontSize="small"
              className="text-[#9100BC] bg-[#EAC2F6] font-normal text-[11px] mr-1 rounded-[2px] p-0.5"
            />
          )}
          {note_type === NOTE_TYPE.FREE && (
            <StickyNote2Outlined
              fontSize="small"
              className="text-[#CA8E00] bg-[#EDD4A3] font-normal text-[11px] mr-1 rounded-[2px] p-0.5"
            />
          )}
          {note_type === NOTE_TYPE.TODO && (
            <CalendarTodayIcon
              fontSize="small"
              className="text-[#828282] text-[11px] font-normal mr-1"
            />
          )}
          {note_type === NOTE_TYPE.FREE && (
            <Typography.Text className="text-[#CA8E00] text-[11px] font-normal">
              {t('note.freeInsight')}
            </Typography.Text>
          )}
          {note_type === NOTE_TYPE.CHAT && (
            <Typography.Text className="text-[#0049EE] text-[11px] font-normal">
              {chat?.name}
            </Typography.Text>
          )}
          {note_type === NOTE_TYPE.DOCUMENT && (
            <Typography.Text className="text-[#9100BC] text-[11px] font-normal">
              {document?.filename}
            </Typography.Text>
          )}
          {note_type === NOTE_TYPE.TODO && edit && (
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <MobileDatePicker
                onChange={(value) => onChangetodoDate(value)}
                value={todo_date ? moment(todo_date) : null}
                format="DD/MM/YYYY"
                defaultValue={null}
                slotProps={{
                  textField: {
                    size: 'small',
                    onKeyDown: handleKeyDown
                  }
                }}
              />
            </LocalizationProvider>
          )}

          {note_type === NOTE_TYPE.TODO && !edit && (
            <Typography.Text className="text-[#828282] text-[11px] font-normal group-hover/item:text-[#0049EE]">
              {moment(todo_date).format('DD/MM/yyyy')}
            </Typography.Text>
          )}
        </div>
        {note_type === NOTE_TYPE.TODO ? (
          !edit ? (
            <div className="flex items-center">
              <AccessTimeIcon
                fontSize="small"
                className="text-[#828282] mr-1 group-hover/item:text-[#0049EE]"
              />
              <Typography.Text className="text-[#828282] text-[11px] font-normal group-hover/item:text-[#0049EE]">
                {moment(todo_date).format('HH:mm')}
              </Typography.Text>
            </div>
          ) : (
            <div className="flex items-center">
              <AccessTimeIcon
                fontSize="small"
                className="text-[#828282] text-[11px] font-normal mr-1 group-hover/item:text-[#0049EE]"
              />
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <MobileTimePicker
                  value={moment(todo_date)}
                  onChange={(value) => onChangeTimePicker(value)}
                  format="HH:mm"
                  minutesStep={5}
                  slotProps={{
                    textField: {
                      size: 'small',
                      onKeyDown: handleKeyDown
                    }
                  }}
                />
              </LocalizationProvider>
            </div>
          )
        ) : (
          <Typography.Text className="text-[#828282] text-[11px] font-normal group-hover/item:text-[#0049EE]">
            {moment(created_at).format('DD/MM/yyyy')}
          </Typography.Text>
        )}
      </div>
    </div>
  )
}

export default NoteCard
