import React from 'react'
import { Controller } from 'react-hook-form'
import ErrorMessage from '../atoms/ErrorMessage'
import { TextField } from '@mui/material'
import { isAdminPage } from 'utils/isAdminPage'

const TextFields = ({
  control,
  label,
  name,
  errors,
  isRequired = false,
  inArray = false,
  inputStyles = {},
  ...inputProps
}) => {
  return (
    <div className="w-full flex flex-col gap-2">
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <>
            {isAdminPage() ? (
              <span
                className={`${
                  inArray
                    ? errors
                      ? '!text-red-500'
                      : 'text-[#FFFFFFA6]'
                    : errors[name]
                    ? '!text-red-500'
                    : 'text-[#FFFFFFA6]'
                } }`}>
                {label}
              </span>
            ) : null}
            <TextField
              {...field}
              sx={{...inputStyles,
                '& .MuiInput-underline:before': {
                  borderBottomColor: 'rgba(0, 0, 0, 0.12)',
                },
                '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                  borderBottomColor: '#0049EE',
                },
                '& .MuiInput-underline:after': {
                  borderBottomColor: '#0049EE',
                },
              }}
              required={isRequired}
              label={
                isAdminPage() ? null : (
                  <span
                    className={`${
                      inArray
                        ? errors
                          ? '!text-red-500'
                          : ''
                        : errors[name]
                        ? '!text-red-500'
                        : ''
                    } }`}>
                    {label}
                  </span>
                )
              }
              {...inputProps}
            />
          </>
        )}
      />
      {inArray && errors ? <ErrorMessage errorMessage={errors.message} /> : null}
      {!inArray && errors[name] ? <ErrorMessage errorMessage={errors[name].message} /> : null}
    </div>
  )
}

export default TextFields
