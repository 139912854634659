import React from 'react'
import { isAdminPage } from 'utils/isAdminPage'
import logo from 'assets/images/logo.svg'
import logoAdmin from 'assets/images/logoAdmin.svg'

const LoginFormTemplate = ({ children }) => {
  return (
    <>
      <div className={`${isAdminPage() ? 'bg-black' : ''} grid grid-cols-2 h-screen w-screen`}>
        <div className="flex justify-center items-center w-full h-full">
          {/* Logo */}

          <div className="h-18">
            <img
              src={isAdminPage() ? logoAdmin : logo}
              alt="logo"
              className="object-contain w-full h-full"
            />
          </div>
        </div>
        {/* Form */}
        <div className="w-full h-full flex justify-center items-center p-2">
          <div
            className={`${
              isAdminPage() ? 'bg-[#FFFFFF0F]' : 'bg-[rgb(237,237,239)]'
            }  w-full h-full rounded-lg flex justify-center items-center `}>
            {children}
          </div>
        </div>
      </div>
    </>
  )
}

export default LoginFormTemplate
