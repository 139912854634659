import './App.css'
import { BrowserRouter } from 'react-router-dom'
import Router from './routes'
import { QueryClient, QueryClientProvider } from 'react-query'
// import RouterWithGrowthbook from "./growthbook"
import { Bounce, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ConfigProvider } from 'antd'
import { ThemeProvider, createTheme } from '@mui/material'
import { store, persistor } from 'store'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import translationEN from './language/en.json'
import translationIT from './language/it.json'

const themeAntd = {
  color: {
    primary: '#999999',
    secondary: '#666666',
    accent: '#FFFFFF0F'
  }
}

const resources = {
  en: {
    translation: translationEN
  },
  it: {
    translation: translationIT
  }
}

i18n.use(initReactI18next)
.init({
  resources,
  lng: 'it',
  fallbackLng: 'it',
  supportedLngs: ['en', 'it'],
  interpolation: {
    escapeValue: false
  }
})

const themeMuiAdmin = createTheme({
  components: {
    MuiInput: {
      styleOverrides: {
        root: {
          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            display: 'none'
          },
          '& input[type=number]': {
            MozAppearance: 'textfield'
          }
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: '0px',
          paddingTop: '16px'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {}
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&:has(> input:-webkit-autofill)': {
            backgroundColor: 'blue'
          },
          // paddingRight: "0px !important",
          color: 'white !important',
          backgroundColor: 'rgba(255, 255, 255, 0.06) !important'
          // "&MuiFilledInput-root": {
          // 	paddingRight: "0px",
          // },
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        // props default overrides
        variant: 'filled',
        size: 'small',
        hiddenLabel: true
      },
      styleOverrides: {
        root: {
          // css style overrides
          color: '#666666',
          paddingRight: '0px'
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: 'white !important',
          '&:active': {
            color: 'white !important'
          },
          '&:hover': {
            color: 'white !important'
          },
          '&:checked': {
            color: 'white !important'
          }
        }
      }
    }
  }
})
const themeMuiUsers = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&:has(> input:-webkit-autofill)': {
            backgroundColor: 'blue'
          },
          // paddingRight: "0px !important",
          // color: 'white !important',
          backgroundColor: 'rgba(255, 255, 255, 0.06) !important'
          // "&MuiFilledInput-root": {
          // 	paddingRight: "0px",
          // },
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        // props default overrides
        variant: 'standard',
        size: 'small'
      },
      styleOverrides: {
        root: {
          // css style overrides
          color: '#666666',
          paddingRight: '0px'
        }
      }
    }
  }
})

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: 'always',
        refetchOnReconnect: false,
        retry: false
      }
    }
  })

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <ConfigProvider
            theme={
              window.location.pathname.split('/')[1] === 'admin'
                ? {
                    token: {
                      colorText: themeAntd.color.primary,
                      colorPrimary: themeAntd.color.primary,
                      colorTextQuaternary: themeAntd.color.primary
                    },
                    components: {
                      Typography: {
                        titleMarginBottom: 0
                      },
                      Button: {
                        // defaultActiveBorderColor:,
                        defaultGhostBorderColor: themeAntd.color.accent,
                        ghostBg: themeAntd.color.accent,
                        defaultGhostColor: themeAntd.color.primary,
                        defaultHoverColor: themeAntd.color.primary,
                        defaultHoverBorderColor: 'rgba(255, 255, 255, 0.40)'
                      },
                      Input: {
                        colorTextPlaceholder: themeAntd.color.primary,
                        colorText: 'white',
                        activeBg: 'rgba(255, 255, 255, 0.40)'
                      },
                      Checkbox: {
                        colorText: 'white'
                      },
                      Select: {
                        colorTextPlaceholder: themeAntd.color.primary,
                        selectorBg: themeAntd.color.accent,
                        colorText: 'white',
                        colorIcon: themeAntd.color.primary,
                        fontFamily: "'Inter',sans-serif",
                        optionActiveBg: themeAntd.color.accent,
                        colorPrimary: themeAntd.color.primary,
                        optionSelectedColor: themeAntd.color.primary
                      }
                    }
                  }
                : {
                    // User Theme Antd
                  }
            }>
            <ThemeProvider
              theme={
                window.location.pathname.split('/')[1] === 'admin' ? themeMuiAdmin : themeMuiUsers
              }>
              <BrowserRouter>
                <Router />
                {/* <RouterWithGrowthbook/> */}
              </BrowserRouter>
            </ThemeProvider>
            <ToastContainer
              position="bottom-center"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="colored"
              transition={Bounce}
            />
          </ConfigProvider>
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  )
}

export default App
