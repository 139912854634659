import React from 'react'
import { useState, useRef, useEffect } from 'react'
import DataTable, { createTheme } from 'react-data-table-component'
import { Button, Select, Spin, Typography } from 'antd'
import { IcChevronLeft, IcChevronRight } from 'assets/icons'
import { isAdminPage } from 'utils/isAdminPage'

const Loading = () => {
  return (
    <div className={'flex flex-col items-center justify-center px-4 py-8'}>
      <Spin />
      <Typography.Text className="text-grey-400 text-lg text-primary-500 ml-2">
        Loading ...
      </Typography.Text>
    </div>
  )
}

createTheme('solarized', {
  text: {
    primary: '#268bd2',
    secondary: '#2aa198'
  },
  background: {
    default: 'transparent'
  },
  context: {
    background: 'transparent',
    text: '#FFFFFF'
  },
  divider: {
    default: 'rgba(255, 255, 255, 0.06)'
  }
})

createTheme('platform', {
  background: {
    default: 'transparent'
  },
  context: {
    background: 'transparent',
    text: '#000000'
  },
  divider: {
    default: '#cfcfcf'
  }
})

const Table = ({
  className,
  backgroundColorHeadCells,
  conditionalRowStyles,
  noDataComponent,
  columns,
  data,
  pending,
  onChangeRowsPerPage,
  totalRows,
  selectableRows,
  expandableRows,
  expandedComponent,
  noTableHead,
  selectableRowsComponent,
  onSelectedRowsChange,
  clearSelectedRows,
  selectableRowsHighlight,
  fixedHeader,
  style,
  onSort,
  sortServer,
  onRowClicked,
  pagination,
  selectableRowDisabled,
  currentPage,
  page,
  totalPerPage,
  lengthAllData,
  dropDownDataFilter,
  onChangeDropDown,
  onChangeNextPage,
  onChangePrevPage,
  pageSize,
  hidePagination,
  handlePerRowsChange,
  useFooter = true,
  isStyleHistory = false,
  stripped = true,
  rowsStyles,
  headStyles,
  keyField = 'id',
  cellStyles,
  tableStyles,
  sortIcon,
  selectableRowsNoSelectAll = false,
  key,
  // noStripped = false,
  ...tableProps
}) => {
  const [sortedColumn, setSortedColumn] = useState(null)
  const [sortDirection, setSortDirection] = useState(null)
  const tableRef = useRef() // Create a ref for the DataTable

  const handleSort = (column, sortDirection) => {
    setSortedColumn(column.name) // Track sorted column
    setSortDirection(sortDirection) // Track sort direction (optional)

    onSort?.(column, sortDirection)
  }

  useEffect(() => {
    const headers = tableRef.current.querySelectorAll(
      '.rdt_TableHead .rdt_TableHeadRow .rdt_TableCol'
    )

    headers.forEach((header) => {
      header.classList.remove('active-header')
    })

    if (sortedColumn) {
      const sortedHeader = [...headers].find((header) => header.textContent === sortedColumn)

      if (sortedHeader) {
        sortedHeader.classList.add('active-header')
      }
    }
  }, [sortedColumn, sortDirection])

  return (
    <div ref={tableRef}>
      <DataTable
        key={key}
        selectableRowsNoSelectAll={selectableRowsNoSelectAll}
        theme={isAdminPage() ? 'solarized' : 'platform'}
        keyField={keyField}
        className={`${className}`}
        style={style}
        dense={!isAdminPage()}
        selectableRowDisabled={selectableRowDisabled}
        fixedHeader={fixedHeader}
        progressPending={pending}
        progressComponent={<Loading />}
        persistTableHead
        clearSelectedRows={clearSelectedRows}
        selectableRowsHighlight={selectableRowsHighlight}
        columns={columns}
        onRowClicked={onRowClicked}
        {...tableProps}
        customStyles={
          isStyleHistory
            ? {
                table: {
                  style: {
                    borderWidth: '0px',
                    padding: '2px'
                  }
                },
                rows: {
                  style: {
                    color: 'white',
                    // backgroundColor: 'rgba(0 0 0 / 0.90)',
                    marginTop: '10px',
                    marginBottom: '10px',
                    // borderRadius: "12px",
                    padding: '24px',
                    // borderBottomWidth: 0,
                    borderWidth: 1,
                    '&:not(:last-of-type)': {
                      borderBottomWidth: '0px '
                    },
                    ...rowsStyles
                  },
                  highlightOnHoverStyle: null
                }
              }
            : isAdminPage()
            ? {
                // ADMIN PAGE THEME
                table: {
                  style: {
                    // border: "1px solid white",
                    // borderRadius: "12px",
                    borderWidth: '0px',
                    ...tableStyles
                  }
                },
                headCells: {
                  style: {
                    fontWeight: 600,
                    fontSize: 12,
                    color: 'white',
                    // backgroundColor: 'rgba(255, 255, 255, 0.06)',
                    ...headStyles
                  }
                },
                rows: {
                  style: {
                    // borderBottomWidth: 1,
                    // borderBottomColor: "rgba(0 0 0 / 0.06)",
                    // borderWidth: 1,
                    // backgroundColor: 'black',
                    cursor: 'pointer',
                    fontSize: 12,
                    ...rowsStyles
                  },
                  highlightOnHoverStyle: {
                    backgroundColor: 'rgba(255, 255, 255, 0.06)'
                  },
                  stripedStyle: {
                    backgroundColor: 'none'
                  }
                },
                cells: {
                  style: {
                    color: '#999999',
                    ...cellStyles
                  }
                }
              }
            : {
                table: {
                  style: {
                    // border: "1px solid white",
                    // borderRadius: "12px",
                    borderWidth: '0px',
                    ...tableStyles
                  }
                },
                headCells: {
                  style: {
                    backgroundColor: backgroundColorHeadCells,
                    fontWeight: 400,
                    fontSize: 12,
                    height: '30px',
                    color: '#828282',
                    opacity: 1,
                    // backgroundColor: 'rgba(255, 255, 255, 0.06)',
                    ...headStyles
                  }
                },
                rows: {
                  style: {
                    // borderBottomWidth: 1,
                    // borderBottomColor: "rgba(0 0 0 / 0.06)",
                    // borderWidth: 1,
                    // backgroundColor: 'black',
                    fontSize: 14,
                    ...rowsStyles
                  },
                  highlightOnHoverStyle: {
                    backgroundColor: 'rgba(25, 118, 210, 0.1)'
                  },
                  stripedStyle: {
                    backgroundColor: 'none'
                  }
                },
                cells: {
                  style: {
                    // color: '#999999',
                    height: '52px',
                    ...cellStyles
                  }
                }
              }
        }
        striped={stripped}
        paginationServer
        highlightOnHover
        data={data}
        pagination={pagination}
        noTableHead={noTableHead}
        onSelectedRowsChange={onSelectedRowsChange}
        selectableRowsComponent={selectableRowsComponent}
        responsive
        selectableRows={selectableRows}
        paginationTotalRows={totalRows}
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
        paginationComponentOptions={{
          rowsPerPageText: 'Data per page',
          rangeSeparatorText: 'of',
          selectAllRowsItem: true,
          selectAllRowsItemText: 'All'
        }}
        noDataComponent={noDataComponent}
        onChangeRowsPerPage={onChangeRowsPerPage}
        expandableRows={expandableRows}
        expandableRowsComponent={expandedComponent}
        onSort={handleSort}
        sortIcon={sortIcon}
        sortServer={sortServer}
        conditionalRowStyles={conditionalRowStyles}
      />
      {!hidePagination && data && data.length !== 0 && useFooter && (
        <div className="flex w-full flex-row items-center justify-end space-x-4 mt-4 pb-8">
          <div className={`${dropDownDataFilter ? 'flex flex-row items-center' : ''} mr-4`}>
            <label className="text-[#999999] mr-4">Show</label>
            {dropDownDataFilter ? (
              <Select
                className="custom-table-limit bg-[#FFFFFF0F]"
                popupClassName={'bg-[#292929]'}
                style={{ width: '70px' }}
                value={dropDownDataFilter.find((item) => item.selected === true)?.value ?? 5}
                onChange={(value) => onChangeDropDown(value)}>
                {dropDownDataFilter?.map((item, idx) => (
                  <Select.Option key={idx} value={item?.value}>
                    {item?.name}
                  </Select.Option>
                ))}
              </Select>
            ) : (
              <Select
                className=" bg-[#FFFFFF0F]"
                popupClassName={isAdminPage() ? 'bg-[#292929]' : ''}
                style={{ width: '70px' }}
                value={pageSize ?? 5}
                onChange={handlePerRowsChange}>
                <Select.Option value={5}>5</Select.Option>
                <Select.Option value={10}>10</Select.Option>
                <Select.Option value={25}>25</Select.Option>
                <Select.Option value={50}>50</Select.Option>
                <Select.Option value={100}>100</Select.Option>
              </Select>
            )}
          </div>
          <div className="flex flex-row">
            {isNaN(page) && (
              <span className="font-medium text-[#999999]">
                {`${currentPage ?? '1'} - ${totalPerPage ?? data?.length} of ${
                  lengthAllData ?? data?.length
                }`}
              </span>
            )}
            {!isNaN(page) && (
              <span className="font-medium text-[#999999]">
                {`${page + 1} - ${
                  page + pageSize >= totalRows ? totalRows : page + pageSize
                } of ${totalRows}`}
              </span>
            )}
          </div>
          <div className="flex flex-row space-x-3">
            <Button
              className="group p-0 flex border  bg-[#FFFFFF0F] justify-center items-center rounded-md w-9 h-9 hover:border-primary-500"
              onClick={onChangePrevPage ? () => onChangePrevPage() : () => {}}>
              <IcChevronLeft className="text-[#999999] group-hover:text-[#999999]" />
            </Button>
            <Button
              className="group p-0 flex border  bg-[#FFFFFF0F] justify-center items-center rounded-md w-9 h-9 hover:border-primary-500"
              onClick={onChangeNextPage ? () => onChangeNextPage() : () => {}}>
              <IcChevronRight className="text-[#999999] group-hover:text-[#999999]" />
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default Table
