import React, { useEffect, useRef, useState } from 'react'
import { Add, ChatBubbleOutline, MoreVertOutlined, StickyNote2Outlined } from '@mui/icons-material'
import { TablePagination, Tooltip } from '@mui/material'
import { Popover } from 'antd'
import Table from 'components/atoms/Table'
import { useTranslation } from 'react-i18next'

import moment from 'moment'
import { IcDocumentEmpty } from 'assets/icons'
import CustomButton from 'components/atoms/CustomButton'

const ListDocument = ({
                        isLoading,
                        height,
                        data = [],
                        onEdit = () => {
                        },
                        onExport = () => {
                        },
                        onDelete = () => {
                        },
                        goToTheChatFromDocument = () => {
                        },
                        onRowsPerPageChange = () => {
                        },
                        page = 1,
                        rowsPerPage = 9,
                        total = 0,
                        navigateToDoc = () => {
                        },
                        onPageChange = () => {
                        },
                        handleSort = () => {
                        },
                        onRename = () => {
                        },
                        onDownload = () => {
                        },
                        uploadNewDocument = () => {
                        },
                        isSearching = false
                      }) => {
  const { t } = useTranslation()
  
  const paginationRef = useRef()
  const [tableHeight, setTableHeight] = useState(height ?? 0)
  
  useEffect(() => {
    if (paginationRef?.current) {
      setTableHeight(height - paginationRef?.current?.clientHeight)
    }
  }, [paginationRef?.current !== null, height])
  
  const optionsPopover = [
    {
      title: t('document.rename_'),
      callback: onRename,
      role: ['admin', 'editor'],
      ext: ['pdf', 'txt', 'jpg', 'jpeg', 'png', 'docx', 'doc'],
      documentStatus: ['SUCCEEDED', 'FAILED']
    },
    {
      title: 'Download',
      callback: onDownload,
      role: ['admin', 'editor'],
      ext: ['pdf', 'txt', 'jpg', 'jpeg', 'png', 'docx', 'doc'],
      documentStatus: ['SUCCEEDED', 'FAILED']
    },
    {
      title: `${t('export')} (.dox)`,
      callback: onExport,
      role: ['admin', 'editor'],
      ext: ['pdf'],
      documentStatus: ['SUCCEEDED', 'FAILED']
    },
    {
      title: t('document.openAsChat'),
      callback: goToTheChatFromDocument,
      role: ['admin', 'editor'],
      ext: ['pdf'],
      isEligible: true,
      documentStatus: ['SUCCEEDED']
    },
    {
      title: t('document.delete'),
      callback: onDelete,
      role: ['admin', 'editor'],
      ext: ['pdf', 'txt', 'jpg', 'jpeg', 'png', 'docx', 'doc'],
      documentStatus: ['SUCCEEDED', 'FAILED']
    }
  ]
  
  function handleClickMenu(callback, row) {
    return callback(row)
  }
  
  const columns = [
    {
      name: t('Name'),
      cell: (row) => (
        <div className="flex gap-2 items-center  w-full h-full" onClick={() => navigateToDoc(row)}>
          {row?.status === 'success' && <div className="w-2 h-2 bg-[#00B47E] rounded-full" />}
          {row?.status === 'error' && <div className="w-2 h-2 bg-[#E53E3E] rounded-full" />}
          <Tooltip title={row?.filename}>
            <span
              className={`line-clamp-1 font-medium text-sm ${
                row.status === 'IN_PROGRESS' ? 'text-[#828282]' : 'text-black'
              }`}>
              {row?.filename}
            </span>
          </Tooltip>
        </div>
      ),
      selector: (row) => row?.name,
      sortable: true,
      sortField: 'filename'
    },
    {
      name: t('case.openedChats'),
      cell: (row) => (
        <div
          className="flex gap-2 items-center mr-5 w-full justify-end text-sm w-full h-full "
          onClick={() => navigateToDoc(row)}>
          <span className={`${row.status === 'IN_PROGRESS' ? 'text-[#828282]' : 'text-black'}`}>
            {row?.chat_total}
          </span>{' '}
          <ChatBubbleOutline
            className={`!w-4 !h-[18px] ${
              row.status === 'IN_PROGRESS' ? 'text-[#828282]' : 'text-black'
            }`}
          />
        </div>
      ),
      selector: (row) => row?.chat_total,
      sortable: false,
      width: '10%'
    },
    {
      name: t('case.openedNotes'),
      cell: (row) => (
        <div
          className="flex gap-2 items-center mr-5 w-full justify-end text-sm w-full h-full "
          onClick={() => navigateToDoc(row)}>
          <span className={`${row.status === 'IN_PROGRESS' ? 'text-[#828282]' : 'text-black'}`}>
            {row?.note_total}
          </span>{' '}
          <StickyNote2Outlined
            className={`!w-4 !h-[18px] ${
              row.status === 'IN_PROGRESS' ? 'text-[#828282]' : 'text-black'
            }`}
          />
        </div>
      ),
      selector: (row) => row?.note_total,
      sortable: false,
      width: '10%'
    },
    {
      name: t('Loading date'),
      selector: (row) => moment(row?.created_at).format('DD/MM/yyyy'),
      sortable: true,
      sortField: 'created_at',
      width: '20%',
      cell: (row) => (
        <div
          className=" w-full h-full flex items-center justify-start"
          onClick={() => navigateToDoc(row)}>
          <span className={`${row.status === 'IN_PROGRESS' ? 'text-[#828282]' : 'text-black'}`}>
            {moment(row?.created_at).format('DD/MM/yyyy')}
          </span>
        </div>
      )
    },
    {
      name: t('document scan'),
      cell: (row) => (
        <div className="flex items-center  w-full h-full" onClick={() => navigateToDoc(row)}>
          <div
            className={`w-2 h-2 mr-2 rounded-full ${
              row?.status === 'SUCCEEDED'
                ? 'bg-[#00B47E]'
                : row?.status === 'IN_PROGRESS'
                  ? 'bg-[#FD7A3A]'
                  : 'bg-[#E53E3E]'
            }`}></div>
          <span className="text-sm font-normal">
            {row?.status === 'IN_PROGRESS'
              ? 'In elaborazione'
              : row.status === 'SUCCEEDED'
                ? 'Analizzabile'
                : 'Non analizzabile'}
          </span>
        </div>
      ),
      selector: (row) => row?.status,
      sortable: true,
      sortField: 'status',
      width: '20%'
    },
    /* {
      name: '',
      cell: (row) => (
        <div className='flex items-center w-full justify-end'>
          {((row.status === "SUCCEEDED" && row.sub_status === "SUCCEEDED") ||
            (row.status === "FAILED" && row.sub_status === "FAILED")) && (
              <div
                className="flex items-center w-full justify-end"
                onClick={() => navigateToDoc(row)}
              >
                <ArrowForward className="cursor-pointer hover:text-[#0049EE]" />
              </div>
            )}
        </div>

      ),

      sortable: false,
      width: '5%'
    }, */
    
    {
      name: '',
      cell: (row) => (
        <Popover
          content={
            <div className="w-[158px] h-full flex flex-col text-black">
              {optionsPopover.filter((opt) => {
                return opt.documentStatus.includes(row.status)
              }).map(
                (option, index) =>
                  option.role.includes('admin') &&
                  (!option.isEligible || (option.isEligible && row.is_eligible)) &&
                  option.ext.includes(row.ext) && (
                    <div
                      key={index}
                      onClick={() => {
                        handleClickMenu(option.callback, row)
                      }}
                      className="px-2 py-1 cursor-pointer hover:bg-[#0000000A] text-black rounded text-sm font-normal">
                      {option.title}
                    </div>
                  )
              )}
            </div>
          }
          trigger="click"
          placement="leftTop"
          arrow={false}>
          <div className="flex items-center w-full justify-end">
            {((row.status === 'SUCCEEDED' && row.sub_status === 'SUCCEEDED') ||
              (row.status === 'FAILED' && row.sub_status === 'FAILED')) && (
              <MoreVertOutlined className={`cursor-pointer hover:text-[#0049EE]`} />
            )}
          </div>
        </Popover>
      ),
      
      sortable: false,
      width: '5%'
    }
  ]
  
  return (
    <div className="w-full">
      <div>
        <Table
          pending={isLoading}
          noDataComponent={
            <>
              {isSearching ? (
                <div className="flex w-full justify-start items-center text-[#828282] p-4">
                  <div className="flex flex-col w-full justify-center items-center">
                    <IcDocumentEmpty className={'drop-shadow-lg'} />
                    <span className="text-2xl font-normal -mt-4 text-black">
                  {t('No document found')}
                </span>
                  </div>
                </div>
              ) : (
                <div className="flex w-full justify-start items-center text-[#828282] p-4">
                  <div className="flex flex-col w-full justify-center items-center">
                    <IcDocumentEmpty className={'drop-shadow-lg'} />
                    <span className="text-2xl font-normal -mt-4 text-black">
                  {t('Upload first document')}
                </span>
                    <span className="text-sm font-normal mt-2 mb-4 text-black">
                  {t('Upload new documents disclaimer')}
                </span>
                    <CustomButton onClick={uploadNewDocument} className="w-[250px]">
                      <div className="font-normal flex items-center">
                        {t('Add document')}
                        <Add className="ml-1" />
                      </div>
                    </CustomButton>
                  </div>
                </div>
              )}
            </>
          }
          tableStyles={{ height: tableHeight, overflowY: 'scroll' }}
          hidePagination
          columns={data.length > 0 ? columns : []}
          data={data}
          headStyles={{ backgroundColor: 'white' }}
          onSort={(e) => handleSort(e)}
          sortServer={true}
          sortIcon={
            <div className="text-[5px] ml-1">
              <div>▲</div>
              <div className="transform rotate-180">▲</div>
            </div>
          }
          fixedHeader
        />
      </div>
      <div className="pr-6 mt-1" ref={paginationRef}>
        <TablePagination
          component="div"
          count={total}
          rowsPerPageOptions={[20]}
          page={page - 1}
          onPageChange={onPageChange}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      </div>
    </div>
  )
}

export default ListDocument
