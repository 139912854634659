import { Close, East, KeyboardBackspaceOutlined } from '@mui/icons-material'
import { Breadcrumbs, Tooltip } from '@mui/material'
import { Spin, Typography } from 'antd'
import {
  getChatHistory,
  getDetailDocument,
  getReferenceRegulationChat,
  postChatMessage,
  postRegenerateMessageChat
} from 'api'
import ReferenceRegulationModal from 'components/organisms/users/ReferenceRegulationModal'
import NotesContainer from 'components/organisms/users/cases/NotesContainer'
import ChatContainer from 'components/organisms/users/chat/ChatContainer'
import DocumentComparison from 'components/organisms/users/chat/DocumentComparison'
import { useAuth } from 'hooks'
import React, { useEffect, useRef, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom'
import { showErrorMessage } from 'utils/showMessage'
import { useTranslation } from 'react-i18next'
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation'
import { BookmarksList } from 'components/atoms/Bookmarks'

const INITIAL_REFERENCE_REGULATION_MODAL = { show: false, number: '', reference: '' }

const ChatPage = () => {
  
  const { t } = useTranslation()
  const [detailChat, setDetailChat] = useState([])
  const { getAccessToken,platformData } = useAuth()
  const { state } = useLocation()
  const [docsWindow, setDocsWindow] = useState(false)
  const [showIndexDocument, setShowIndexDocument] = useState(false)
  const [selectedDocument, setSelectedDocument] = useState(null)
  console.log('selectedDocument', selectedDocument)
  const [referenceRegulationState, setReferenceRegulationState] = useState(
    INITIAL_REFERENCE_REGULATION_MODAL
  )
  const pageNavigationInstance = pageNavigationPlugin()
  const { jumpToPage } = pageNavigationInstance
  const caseId = state?.caseId
  const caseName = state?.caseName
  const navigate = useNavigate()
  useEffect(() => {
    if (!state?.caseId && !state?.chatData) {
      showErrorMessage(t('error messages.Chat is invalid'))
      return navigate('/cases', { replace: true })
    }
  }, [])

  const [layoutInfo] = useOutletContext()
  const [tabNote, setTabNote] = useState(0)
  const chatHistoryRef = useRef(null)
  const noteRef = useRef()
  useEffect(() => {
    const node = chatHistoryRef.current
    if (node) {
      node.addEventListener('click', handleReferenceRegulation)
    }
    return () => {
      if (node) {
        node.removeEventListener('click', handleReferenceRegulation)
      }
    }
  }, [])

  function handleReferenceRegulation(e) {
    if (e.target.tagName.toLowerCase() === 'a') {
      const hasNumber = e.target.hasAttribute('number');
      const hasReference = e.target.hasAttribute('reference');
      if (hasNumber && hasReference) {
        const number = e.target.getAttribute('number')
        const reference = e.target.getAttribute('reference')
        openReferenceRegulationModal(number, reference)
      }
    }
  }

  const { data: dataReferenceRegulation, isFetching: isReferenceFetching } = useQuery({
    queryKey: ['getReferenceRegulation', referenceRegulationState.keyword],
    queryFn: () => getReferenceRegulationChat(getAccessToken(), referenceRegulationState.number, referenceRegulationState.reference),
    onError: () => {},
    enabled: referenceRegulationState.number !== '' &&  referenceRegulationState.reference !== '' && referenceRegulationState.show
  })

  function openReferenceRegulationModal(number, reference) {
    setReferenceRegulationState((prev) => ({ ...prev, show: true, number: number, reference: reference }))
  }
  
  function closeReferenceRegulationModal() {
    setReferenceRegulationState({ ...INITIAL_REFERENCE_REGULATION_MODAL })
  }

  function handleNavigation(value, state = {}, isReplace = false) {
    return navigate(value, { state: state, replace: isReplace })
  }

  function toggleDocsWindow() {
    setDocsWindow((prev) => {
      if (prev) {
        closeShowIndexDocument()
      }
      return !prev
    })
  }

  function openShowIndexDocument() {
    setShowIndexDocument(true)
  }

  function closeShowIndexDocument() {
    setShowIndexDocument(false)
  }

  // Document
  const { data: dataDocument, isFetching } = useQuery({
    queryKey: ['getDocument', selectedDocument?.id],
    refetchOnWindowFocus: false,
    queryFn: () => getDetailDocument(getAccessToken(), selectedDocument?.id),
    enabled: selectedDocument !== null && docsWindow
  })

  function handleChangeSelectedDocument(value) {
    setSelectedDocument(value)
  }

  const { refetch } = useQuery({
    queryKey: 'getChatHistory',
    queryFn: () => getChatHistory(getAccessToken(), state?.chatData?.id),
    onSuccess: (res) => {
      setDetailChat(res.resData?.data)
      if (!selectedDocument) {
        if (res?.resData?.data?.documents?.length > 0) {
          handleChangeSelectedDocument(res?.resData?.data?.documents[0])
        }
      }
    },
    onError: (err) => {
      console.log(err)
    }
  })
  const postChatMessageMutation = useMutation({
    mutationKey: 'postChatMessageMutation',
    mutationFn: (data) => postChatMessage(getAccessToken(), state?.chatData?.id, data),
    onSuccess: (res) => {
      setTimeout(() => {
        refetch()
      }, 1000);
     
      //setDetailChat((prev) => ({ ...prev, messages: res.resData?.data?.messages }))
    }
  })
  const postRegenerateMessageMutation = useMutation({
    mutationKey: 'postRegenerateMessageChat',
    mutationFn: (messageId) =>
      postRegenerateMessageChat(getAccessToken(), state?.chatData?.id, messageId),
    onSuccess: (res) => {
      setDetailChat((prev) => ({ ...prev, messages: res.resData?.data?.messages }))
    }
  })

  function handleSubmitChat(data) {
    const newMessages = detailChat.messages ?? []
   const profileName = `${platformData?.profile?.name} ${platformData?.profile?.surname}`
    
    newMessages.push({ role: 'user', content: data?.message, choices: null,user:{name: profileName, avatar_url:""} })
    newMessages.push({ role: 'assistant', content: '', choices: null })
  
    setDetailChat((prev) => ({ ...prev, messages: newMessages }))
    postChatMessageMutation.mutate(data)
  }



  function handleRegenerateMessage(messageId) {
    const messageIndex = detailChat.messages.findIndex((m) => m.id === messageId)
    const messages = detailChat.messages.slice(0, messageIndex + 1)
    setDetailChat((prev) => ({ ...prev, messages }))
    postRegenerateMessageMutation.mutate(messageId)
  }

  return (
    <div className="grid grid-cols-12 gap-4 w-full h-full overflow-hidden">
      {/* Wrapper White Content */}
      <div
        className={`grid col-span-9 grid-cols-12 overflow-hidden rounded-3xl border border-solid border-[#E3E3E8] bg-white `}>
        {/* Chat Wrapper */}
        <div className={`pt-8 w-full h-full relative ${docsWindow ? 'col-span-6' : 'col-span-11'}`}>
          {/* Index / Bookmark Wrapper */}
          {showIndexDocument ? (
            <div className="absolute top-0 z-30 w-full h-full backdrop-blur rounded-xl">
              <div className="w-full h-full relative p-8 pr-1">
                <div className="flex w-full justify-between mb-8">
                  <span className="font-bold text-xl">{t('document.index')}</span>
                  <div
                    className="flex justify-center items-center cursor-pointer pr-4"
                    onClick={closeShowIndexDocument}>
                    <Close />
                  </div>
                </div>
                <div className="h-full max-h-[calc(100vh-190px)] overflow-y-auto">
                  <div className="w-full px-4">
                    <BookmarksList
                      items={dataDocument?.resData?.data?.bookmarks}
                      jumpToPage={jumpToPage}
                    />
                  </div>
                </div>
                <div className="absolute h-7 w-7 backdrop-blur bg-white border-[#E3E3E8] opacity-80 border-2 border-solid border-l-0 border-b-0 bottom-12 rotate-45 -right-4"></div>
              </div>
            </div>
          ) : null}
          <div className="flex flex-col gap-2 pl-8 pb-6 border-0 border-solid border-b border-[#E3E3E8]">
            {/* Breadcrumbs */}
            <Breadcrumbs>
              <Typography.Text
                className="text-[#828282] hover:text-black font-normal text-[14px] cursor-pointer"
                onClick={() => {
                  handleNavigation('/cases')
                }}>
                {t('breadcrumb.cases')}
              </Typography.Text>
              <Typography.Text
                className="text-[#828282] hover:text-black font-normal text-[14px] cursor-pointer"
                onClick={handleNavigation.bind(
                  this,
                  '/cases/detail',
                  {
                    data: { id: state?.caseId }
                  },
                  true
                )}>
                {state?.caseName}
              </Typography.Text>
              <Typography.Text className="text-black font-normal text-[14px]">
                {t('breadcrumb.chat')}
              </Typography.Text>
            </Breadcrumbs>
            {/* Title Chat */}
            <Typography.Text className="text-black font-semibold text-[30px] text-ellipsis line-clamp-1">
              {state?.chatData?.name}
            </Typography.Text>
          </div>

          {/* Chat Content Wrapper */}
          <div className="flex px-5 max-h-[calc(100vh-170px)] h-full justify-between flex-col items-center">
            <ChatContainer
              ref={chatHistoryRef}
              onAddNote={(options) => noteRef?.current?.addNewNote(options)}
              onRegenerateMessage={handleRegenerateMessage}
              chatHistory={detailChat?.messages ? detailChat?.messages : []}
              onSubmit={handleSubmitChat}
              isLoading={
                postChatMessageMutation.isLoading || postRegenerateMessageMutation.isLoading
              }
            />
          </div>
        </div>
        {/* Docs Wrapper */}
        <div
          className={` border-0 border-solid border-l border-[#E3E3E8]  ${
            docsWindow ? 'col-span-6' : 'col-span-1'
          }`}>
          {/* Header */}
          <div
            className={`flex gap-5 pt-3 pb-[27px] border-0  ${
              docsWindow ? ' justify-start pl-3' : 'justify-center'
            }`}>
            {/* Button */}
            <div
              onClick={toggleDocsWindow}
              className={`w-16 h-24 shrink-0 cursor-pointer ${
                docsWindow
                  ? 'bg-white text-[#0049EE] border border-solid border-[#E3E3E8] hover:text-[#828282] hover:bg-[#EDEDEF]'
                  : 'bg-[#EDEDEF] text-[#828282] hover:bg-white hover:text-[#0049EE] hover:border hover:border-solid hover:border-[#E3E3E8]'
              } flex flex-col rounded-[10px] justify-center items-center  gap-1 text-xs`}>
              {/* Total Files */}
              <span className="text-lg font-semibold">{detailChat?.documents?.length}</span>
              <span>File</span>
              {docsWindow ? <East /> : <KeyboardBackspaceOutlined />}
            </div>
            {/* docs btn & title */}
            <div
              className={`${
                docsWindow ? 'flex' : 'hidden'
              }  flex-col justify-between overflow-hidden pr-3`}>
              <div className="flex gap-2 overflow-x-auto w-full whitespace-nowrap ">
                <div className="flex gap-2 pt-6">
                  {detailChat?.documents?.map((doc, idx) => (
                    <Tooltip
                      onClick={() => handleChangeSelectedDocument(doc)}
                      placement="bottom-start"
                      key={idx}
                      style={{ flex: '0 0 auto' }}
                      className={`${
                        doc?.id === selectedDocument?.id
                          ? 'bg-[#0049EE] text-white'
                          : 'bg-[#EDEDEF] text-[#828282] hover:bg-[#0049EE] hover:text-white'
                      } w-7 h-7 cursor-pointer text-[10px] flex justify-center items-center rounded `}
                      title={doc?.filename}>
                      <span>{doc?.filename?.slice(0, 2).toUpperCase()}</span>
                    </Tooltip>
                  ))}
                </div>
              </div>
              <Tooltip placement="bottom-start" title={selectedDocument?.filename}>
                <span className="text-[#0F111A] text-3xl font-semibold text-ellipsis line-clamp-1">
                  {selectedDocument?.filename}
                </span>
              </Tooltip>
            </div>
          </div>
          {/* Docs Viewer */}
          <div className={`${docsWindow ? 'flex' : 'hidden'}  overflow-hidden`}>
            {dataDocument?.resData?.data && !isFetching ? (
              <DocumentComparison
                pageNavigationInstance={pageNavigationInstance}
                showIndexDocument={showIndexDocument}
                openShowIndexDocument={openShowIndexDocument}
                onAddNote={(options) => noteRef?.current?.addNewNote(options)}
                fileUrl={dataDocument?.resData?.data?.filepath ?? state?.document?.filepath}
                document={dataDocument?.resData?.data}
              />
            ) : isFetching ? (
              <div className="flex flex-1 w-full h-[calc(100vh-170px)] justify-center items-center my-auto">
                <Spin />
              </div>
            ) : (
              <div className="flex justify-center items-center w-full h-[calc(100vh-170px)]">
                {t('common.error')}
              </div>
            )}
          </div>
        </div>
      </div>
      <NotesContainer
        ref={noteRef}
        setTabNote={setTabNote}
        tabNote={tabNote}
        caseId={caseId}
        height={layoutInfo - 20}
        caseName={caseName}
      />
      {referenceRegulationState.show ? (
        <ReferenceRegulationModal
          data={dataReferenceRegulation?.resData?.data}
          show={referenceRegulationState.show}
          referenceRegulationState={referenceRegulationState}
          isLoading={isReferenceFetching}
          closeModal={closeReferenceRegulationModal}
        />
      ) : null}
    </div>
  )
}

export default ChatPage
