import { zodResolver } from '@hookform/resolvers/zod'
import CustomButton from 'components/atoms/CustomButton'
import DatePickerUserFields from 'components/molecules/user/DatePickerUserFields'
import ModalUser from 'components/molecules/user/ModalUser'
import React from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { useTranslation } from 'react-i18next'

const schema = z.object({
  fromDate: z.date({
    required_error: 'La data di inizio è obbligatoria',
    invalid_type_error: 'La data di inizio deve essere una data valida'
  }),
  toDate: z.date({
    required_error: 'La data di fine è obbligatoria',
    invalid_type_error: 'La data di fine deve essere una data valida'
  })
}).refine(data => data.fromDate <= data.toDate, {
  message: 'La data di inizio non può essere successiva alla data di fine',
  path: ['fromDate'] // Indichiamo che l'errore riguarda `fromDate`
})
const ChatFilterModal = ({ show, onClose, data, onSubmit, onLogout, cancelFilter, tabTable }) => {
  const { t } = useTranslation()
  
  console.log(data)
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset
  } = useForm({
    defaultValues: {
      fromDate: data?.fromDate || new Date(),
      toDate: data?.toDate || new Date()
    },
    resolver: zodResolver(schema)
  })
  
  async function processForm(data) {
    let newDateFilterValue = null
    if (data.fromDate !== '' || data.toDate !== '') {
      newDateFilterValue = Object.fromEntries(
        Object.entries(data).filter(([_, value]) => value !== '')
      )
    }
    
    await onSubmit(newDateFilterValue)
    closeModal()
  }
  
  function closeModal() {
    reset()
    onClose()
  }
  
  return (
    <ModalUser modalClassName={''} onClose={closeModal} title={t('Filters')} show={show}>
      {/* Regulation */}
      <div className="h-full md:h-[528px] pt-3 flex flex-col justify-between">
        <div className="flex flex-col gap-8">
          {/* Proceeding Date */}
          <div className="grid grid-cols-1 gap-1 !text-black">
            <span
              className="text-black font-semibold text-base ">{t('Creation Date')} {tabTable === 0 ? 'chat' : tabTable === 1 ? 'documenti' : 'elaborati'}</span>
            <div className="grid grid-cols-2 gap-8">
              <DatePickerUserFields
                control={control}
                label={t('From')}
                name={'fromDate'}
                errors={errors}
              />
              <DatePickerUserFields
                control={control}
                label={t('To')}
                name={'toDate'}
                errors={errors}
              />
            </div>
          </div>
        </div>
        {/* Button Wrapper */}
        <div className="flex justify-end items-center gap-4">
          <CustomButton buttonColor="secondary" onClick={cancelFilter}>
            {t('cancel filters')}
          </CustomButton>
          <CustomButton onClick={handleSubmit(processForm)}>{t('apply')}</CustomButton>
        </div>
      </div>
    </ModalUser>
  )
}

export default ChatFilterModal
