import React from 'react'

const IcSend = ({ className, size = 20, color = 'white' }) => (
  <svg
    className={className}
    width={size}
    height={size}
    color={color}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g id="send-message 3" clipPath="url(#clip0_5373_33809)">
      <g id="Group">
        <path
          id="Vector"
          d="M18.332 1.66797L8.33203 11.668"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M18.3327 1.66797L12.4993 18.3346L8.33268 11.668L1.66602 7.5013L18.3327 1.66797Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_5373_33809">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default IcSend
