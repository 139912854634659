import { AddOutlined } from '@mui/icons-material'
import { FolderNewCase } from 'assets/images'
import CustomButton from 'components/atoms/CustomButton'
import React from 'react'
import { useTranslation } from 'react-i18next'

const NullCaseComponent = ({ onAdd = () => {} }) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col w-full h-full justify-center items-center gap-4">
      <img src={FolderNewCase} />
      <span className="font-normal text-2xl">{t("Create your first case")}</span>
      <CustomButton onClick={onAdd}>
        <div className="flex gap-1 justify-center items-center">
          {t("New case")} <AddOutlined />
        </div>
      </CustomButton>
    </div>
  )
}

export default NullCaseComponent
