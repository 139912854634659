const IcDocumentEmpty = ({ className, width = 142, height = 162 }) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 142 162"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_dd_2416_225209)">
      <path
        d="M97 127.5H45C36.9919 127.5 30.5 121.008 30.5 113V41C30.5 32.9919 36.9919 26.5 45 26.5H90.1716C90.5694 26.5 90.9509 26.658 91.2322 26.9393L111.061 46.7678C111.342 47.0491 111.5 47.4306 111.5 47.8284V113C111.5 121.008 105.008 127.5 97 127.5Z"
        stroke="#E3E3E8"
      />
      <rect x="43" y="58" width="56" height="1" fill="#E3E3E8" />
      <rect x="43" y="71" width="56" height="1" fill="#E3E3E8" />
      <rect x="43" y="95" width="56" height="1" fill="#E3E3E8" />
      <rect x="43" y="83" width="56" height="1" fill="#E3E3E8" />
      <rect x="43" y="108" width="47" height="1" fill="#E3E3E8" />
      <path d="M90 27V46C90 47.1046 90.8954 48 92 48H111" stroke="#E3E3E8" />
    </g>
  </svg>
)
export default IcDocumentEmpty
