import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CustomButton from 'components/atoms/CustomButton'
import React, { useState } from 'react'
import DeleteProfileCompanyModal from './DeleteProfileCompanyModal'
import EditCompanyModal from './EditCompanyModal'
import RetrieveProfileCompanyModal from './RetrieveProfileCompanyModal'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

const EDIT_PROFILE = 'EDIT_PROFILE'
const DELETE_PROFILE = 'DELETE_PROFILE'
const RETRIEVE_PROFILE = 'RETRIEVE_PROFILE'

const CompanyDetailHeader = ({
  goBackNav,
  refetch,
  companyName = 'Company name',
  date = '2024-04-17T07:20:32.25919Z',
  onEditProfile,
  onDeleteProfile,
  onRetrieveProfile,
  data,
  countries
}) => {
  const { t, i18n } = useTranslation();

  const [modalEditProfile, setModalEditProfile] = useState(false)
  const [modalDeleteProfile, setModalDeleteProfile] = useState(false)
  const [modalRetrieveProfile, setModalRetrieveProfile] = useState(false)

  function openModal(modal) {
    switch (modal) {
      case EDIT_PROFILE:
        return setModalEditProfile(true)
      case DELETE_PROFILE:
        return setModalDeleteProfile(true)
      case RETRIEVE_PROFILE:
        return setModalRetrieveProfile(true)
      default:
        return false
    }
  }

  function closeModal(modal) {
    switch (modal) {
      case EDIT_PROFILE:
        return setModalEditProfile(false)
      case DELETE_PROFILE:
        return setModalDeleteProfile(false)
      case RETRIEVE_PROFILE:
        return setModalRetrieveProfile(false)
      default:
        return false
    }
  }

  return (
    <>
      <div className="flex w-full justify-between pb-6 border-b border-l-0 border-r-0 border-t-0 border-solid border-[#FFFFFF29]">
        {/* Title */}
        <div className="flex gap-2 justify-center items-start">
          <div
            onClick={goBackNav}
            className="pr-2 py-1 flex justify-center items-center cursor-pointer">
            <ArrowBackIcon className="text-[#FFFFFF7A]" />
          </div>
          <div>
            <span className="text-white font-manrope font-semibold text-2xl block">
              {companyName}
            </span>
            <span className="text-white font-manrope text-md opacity-50">
            {t("DATE")}: {moment(date).format('DD/MM/YYYY')}
            </span>
          </div>
        </div>
        {/* Button Wrapper */}
        <div className="flex gap-2 items-center">
          <CustomButton onClick={openModal.bind(this, EDIT_PROFILE)} buttonColor="secondary">
          {t("Edit Profile")}
          </CustomButton>
          {!data?.is_active_flag ? (
            <CustomButton onClick={openModal.bind(this, RETRIEVE_PROFILE)}>
              {t("Retrieve Profile")}
            </CustomButton>
          ) : (
            <CustomButton onClick={openModal.bind(this, DELETE_PROFILE)} buttonColor="danger">
             {t("Delete Profile")}
            </CustomButton>
          )}
        </div>
      </div>
      {modalDeleteProfile ? (
        <DeleteProfileCompanyModal
          show={modalDeleteProfile}
          onClose={closeModal.bind(this, DELETE_PROFILE)}
          onDeleteProfile={onDeleteProfile}
          data={data}
          refetch={refetch}
        />
      ) : null}
      {modalEditProfile ? (
        <EditCompanyModal
          show={modalEditProfile}
          data={data}
          countries={countries}
          onClose={closeModal.bind(this, EDIT_PROFILE)}
          onSubmit={onEditProfile}
          refetch={refetch}
        />
      ) : null}
      {modalRetrieveProfile ? (
        <RetrieveProfileCompanyModal
          show={modalRetrieveProfile}
          onClose={closeModal.bind(this, RETRIEVE_PROFILE)}
          onRetrieveProfile={onRetrieveProfile}
          data={data}
          refetch={refetch}
        />
      ) : null}
    </>
  )
}

export default CompanyDetailHeader
