import React from 'react'
import { Routes, Route } from 'react-router-dom'
import UsersPage from 'pages/admin/users/UsersPage'
import AdminTemplate from 'components/templates/AdminTemplate'
import ProfilePage from 'pages/admin/profile/ProfilePage'
import ContactsPage from 'pages/admin/contacts/ContactsPage'
import CompaniesPage from 'pages/admin/companies/CompaniesPage'
import UsersDetailPage from 'pages/admin/users/UsersDetailPage'
import CompanyDetailPage from 'pages/admin/companies/CompanyDetailPage'
import PrivacyPolicyPage from 'pages/admin/privacy-policy/PrivacyPolicyPage'
import TermsConditionsPage from 'pages/admin/terms-conditions/TermsConditionsPage'
import UserTemplate from 'components/templates/UserTemplate'
import Workspace from 'pages/user/workspace/Workspace'
import CasesPage from 'pages/user/case/CasesPage'
import ResetPasswordPage from 'pages/auth/ResetPasswordPage'
import RequireAuth from './RequireAuth'
import LoginPage from 'pages/auth/LoginPage'
import ProfilePlatformPage from 'pages/user/profile/ProfilePlatformPage'
import ChatPage from 'pages/user/chats/ChatPage'
import RegulationPage from 'pages/user/regulation/RegulationPage'
import DocumentPage from 'pages/user/documents/DocumentPage'
import ProcessedDocumentPage from 'pages/user/documents/ProcessedDocumentPage'
const Router = () => {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/admin/login" element={<LoginPage />} />
      <Route path="/reset-password/" element={<ResetPasswordPage />} />
      <Route path="/admin/reset-password" element={<ResetPasswordPage />} />
      <Route
        path="/admin/"
        element={
          <RequireAuth isBackOffice={true}>
            <AdminTemplate />
          </RequireAuth>
        }>
        <Route index element={<CompaniesPage />} />
        <Route path="users" element={<UsersPage />} />
        <Route path="users/:id" element={<UsersDetailPage />} />
        <Route path="profile" element={<ProfilePage />} />
        <Route path="contacts" element={<ContactsPage />} />
        <Route path="companies" element={<CompaniesPage />} />
        <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="terms-conditions" element={<TermsConditionsPage />} />
        <Route path="companies/detail" element={<CompanyDetailPage />} />
      </Route>
      <Route
        path="/"
        element={
          <RequireAuth isBackOffice={false}>
            <UserTemplate />
          </RequireAuth>
        }>
        <Route index element={<Workspace />} />
        <Route path="cases" element={<Workspace />} />
        <Route path="cases/:id" element={<CasesPage />} />
        <Route path="chat" element={<ChatPage />} />
        <Route path="document" element={<DocumentPage />} />
        <Route path="processed" element={<ProcessedDocumentPage />} />
        <Route path="profile" element={<ProfilePlatformPage />} />
        <Route path="security" element={<ProfilePlatformPage />} />
        <Route path="contacts" element={<ProfilePlatformPage />} />
        <Route path="regulations" element={<RegulationPage />} />
      </Route>
    </Routes>
  )
}

export default Router
