import i18next from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'

const titleClassName = 'text-[#FFFFFF7A] font-manrope text-xs'
const descriptionClassName = 'font-manrope text-lg text-white'

const ContactInfoUser = ({ email = '', phoneNumber = '' }) => {
  const { t } = useTranslation();

  return (
    <div className="grid grid-cols-4  pb-10 border border-solid border-b border-t-0 border-l-0 border-r-0 border-[#FFFFFF29]">
      <span className="text-white font-manrope text-2xl">{t('Contact Info')} </span>
      <div className="flex flex-col gap-1 items-start">
        <span className={titleClassName}>EMAIL</span>
        <span className={descriptionClassName}>{email}</span>
      </div>
      <div className="flex flex-col gap-1 items-start">
        <span className={titleClassName}>{t('TELEPHONE NUMBER')}</span>
        <span className={descriptionClassName}>{phoneNumber}</span>
      </div>
    </div>
  )
}

export default ContactInfoUser
