import React, { useEffect, useRef, useState } from 'react'
import { Add, InsertDriveFileOutlined, MoreVertOutlined, StickyNote2Outlined } from '@mui/icons-material'
import { TablePagination, Tooltip } from '@mui/material'
import { Popover } from 'antd'
import Table from 'components/atoms/Table'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { IcChatEmpty } from 'assets/icons'
import CustomButton from 'components/atoms/CustomButton'

const ListChat = ({
                    isLoading, height, data = [], onRename = () => {
  }, onDelete = () => {
  }, onRowsPerPageChange = () => {
  }, page = 1, rowsPerPage = 9, total = 0, onPageChange = () => {
  }, handleSort = () => {
  }, openNewChatModal = () => {
  }, onClickRow, isSearching = false
                  }) => {
  const paginationRef = useRef()
  const [tableHeight, setTableHeight] = useState(height ?? 0)
  const { t } = useTranslation()
  
  const optionsPopover = [{
    title: t('document.rename_'), callback: onRename, role: ['admin', 'editor']
  }, {
    title: t('document.delete'), callback: onDelete, role: ['admin']
  }]
  
  function handleClickMenu(callback, row) {
    return callback(row)
  }
  
  useEffect(() => {
    if (paginationRef?.current) {
      setTableHeight(height - paginationRef?.current?.clientHeight)
    }
  }, [paginationRef?.current !== null, height])
  
  const columns = [{
    name: t('Name'), cell: (row) => (<div
      className="flex gap-2 items-center  w-full h-full"
      onClick={onClickRow.bind(this, row)}>
      <Tooltip title={row?.name}>
        <span className="line-clamp-1 font-medium text-sm text-black">{row?.name}</span>
      </Tooltip>
    </div>), selector: (row) => row?.name, sortable: true, sortField: 'name', width: '38%'
  }, {
    name: t('breadcrumb.documents'), cell: (row) => (<div
      className="flex gap-2 items-center mr-5 w-full justify-end w-full h-full"
      onClick={onClickRow.bind(this, row)}>
      {row?.document_total}{' '}
      <InsertDriveFileOutlined className="!w-4 !h-[18px] !text-[#828282]" />
    </div>), selector: (row) => row?.document_total, sortable: false, width: '120px'
  }, {
    name: t('Created Notes'), cell: (row) => (<div
      className="flex gap-2 items-center mr-5 w-full justify-end w-full h-full"
      onClick={onClickRow.bind(this, row)}>
      {row?.note_total} <StickyNote2Outlined className="!w-4 !h-[18px] !text-[#828282]" />{' '}
    </div>), width: '150px',
    
    selector: (row) => row?.note_total, sortable: false
  }, {
    name: t('Creation Date'),
    sortField: 'created_at',
    selector: (row) => moment(row?.created_at).format('DD/MM/yyyy'),
    sortable: true,
    
    cell: (row) => (<div
      className="flex gap-2 items-center mr-5 w-full justify-left w-full h-full "
      onClick={onClickRow.bind(this, row)}>
      {moment(row?.created_at).format('DD/MM/yyyy')}
    </div>)
  }, {
    name: '', width: '100px', cell: (row) => (<div className="flex w-full items-center justify-end  w-full h-full">
      {/* <div className="flex justify-center items-center mr-8" onClick={onClickRow.bind(this, row)}>
            <ArrowForward className="!text-[#595961] cursor-pointer" />
          </div> */}
      <Popover
        content={<div className="w-[158px] h-full flex flex-col text-black">
          {optionsPopover.map((option, index) => option.role.includes('admin') && (<div
            key={index}
            onClick={() => {
              handleClickMenu(option.callback, row)
            }}
            className="px-2 py-1 cursor-pointer hover:bg-[#0000000A] text-black rounded text-sm font-normal">
            {option.title}
          </div>))}
        </div>}
        trigger="click"
        placement="leftTop"
        arrow={false}>
        <MoreVertOutlined className={`cursor-pointer hover:text-[#0049EE] `} />
      </Popover>
    </div>),
    
    sortable: false
  }]
  
  return (<div className="w-full">
      <div>
        <Table
          pending={isLoading}
          noDataComponent={<>
            {isSearching ? (<div className="flex w-full justify-start items-center text-[#828282] p-4">
              <div className="flex flex-col w-full justify-center items-center">
                <IcChatEmpty className={'drop-shadow-lg'} width={200} height={150} />
                <span className="text-2xl font-normal -mt-4 text-black">
                  {t('No chat found')}
                </span>
              </div>
            </div>) : (<div className="flex w-full justify-start items-center text-[#828282] p-4">
              <div className="flex flex-col w-full justify-center items-center">
                <IcChatEmpty className={'drop-shadow-lg'} width={200} height={150} />
                <span className="text-2xl font-normal -mt-4 text-black">
                  {t('Upload new chat')}
                </span>
                <span className="text-sm font-normal mt-2 mb-4 text-black">
                  {t('Upload new chat disclaimer')}
                </span>
                <CustomButton onClick={openNewChatModal} className="w-[190px]">
                  <div className="font-normal flex items-center ">
                    {t('New Chat')}
                    <Add className="ml-1" />
                  </div>
                </CustomButton>
              </div>
            </div>)}
          
          </>
          
          }
          tableStyles={{ height: tableHeight, overflowY: 'scroll' }}
          hidePagination
          columns={data.length > 0 ? columns : []}
          data={data}
          headStyles={{ backgroundColor: 'white' }}
          fixedHeader
          onSort={(e) => handleSort(e)}
          sortServer={true}
          sortIcon={<div className="text-[5px] ml-1">
            <div>▲</div>
            <div className="transform rotate-180">▲</div>
          </div>}
        />
      </div>
      <div className="pr-6 mt-1 " ref={paginationRef}>
        <TablePagination
          component="div"
          count={total}
          rowsPerPageOptions={[20]}
          page={page - 1}
          onPageChange={onPageChange}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      </div>
    </div>
  )
}

export default ListChat
