import { Typography } from 'antd'
import ChangeEmailProfileForm from 'components/organisms/admin/ProfileForm/ChangeEmailProfileForm'
import ChangePasswordProfileForm from 'components/organisms/admin/ProfileForm/ChangePasswordProfileForm'
import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const ProfilePage = () => {
  const email = useSelector(state => state?.admin?.profile?.email)
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-6 ">
      <Typography.Title className="!text-3xl !font-manrope !text-white" style={{fontFamily:"Inter,sans-serif"}}>{t('Profile')}</Typography.Title>
      <div className="flex flex-col gap-10">
        <ChangeEmailProfileForm
          email={email}
        />
        <ChangePasswordProfileForm />
      </div>
    </div>
  )
}

export default ProfilePage
