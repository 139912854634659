import CustomButton from 'components/atoms/CustomButton'
import ModalUser from 'components/molecules/user/ModalUser'
import React, { createRef, useEffect, useMemo, useRef, useState } from 'react'
import { Tooltip, Typography } from 'antd'
import SearchInput from 'components/atoms/SearchInput'
import { debounce } from 'lodash'
import { Clear, FilterListOutlined } from '@mui/icons-material'
import Table from 'components/atoms/Table'
import FilterDocumentModal from './FilterDocumentModal'
import { useAuth } from 'hooks'
import humanFileSize from 'lib/getHumanFileSize'
import ProgressBarDocument from './ProgressBarDocument'
import _ from 'lodash'
import { showErrorMessage } from 'utils/showMessage'
import { v4 as uuidv4 } from 'uuid'
import { useTranslation } from 'react-i18next'
import AddIcon from '@mui/icons-material/Add'
const INITIAL_FILTER_NEW_DOCUMENT = {
  eligibility: { value: null },
  status: { value: null },
}

const AddDocumentModal = ({ show, data, files = [], closeModal, refetch }) => {
  const { t } = useTranslation();

  const { getAccessToken } = useAuth()
  const [documentListDocs, setDocumentListDocs] = useState([])
  const [progresses, setProgresses] = useState({})
  const [updates, setUpdates] = useState({})
  const [filterModal, setFilterModal] = useState(false)
  const [filterState, setFilterState] = useState(INITIAL_FILTER_NEW_DOCUMENT)
  const [isUploadingDocuments, setIsUploadingDocuments] = useState(false)
  const [search, setSearch] = useState('')
  const refs = useRef({});
  const test = createRef();
  const uploadRef = useRef()
  const filteredItems = useMemo(() => {
    return documentListDocs?.filter(item => {
      let check = true;
      check = check && item?.file?.name?.toLowerCase().includes(search?.toLowerCase())
      if (filterState?.eligibility?.value === null && filterState?.status?.value === null) {
        return check
      } else {
        if (filterState?.eligibility?.value === null) {
          check = check && (
            (progresses?.[item?.idx]?.isError === filterState?.status?.value?.unloaded) ||
            (!progresses?.[item?.idx]?.isError === filterState?.status?.value?.uploaded)
          )
        }
        if (filterState?.status?.value === null) {
          check = check && (
            (updates?.[item?.idx]?.isEligible === filterState?.eligibility?.value?.eligible) ||
            (!updates?.[item?.idx]?.isEligible === filterState?.eligibility?.value?.ineligible)
          )
        }
        if (filterState?.status?.value !== null && filterState?.eligibility?.value !== null) {
          check = check && (
            (progresses?.[item?.idx]?.isError === filterState?.status?.value?.unloaded) ||
            (!progresses?.[item?.idx]?.isError === filterState?.status?.value?.uploaded)
          )
          check = check && (
            (updates?.[item?.idx]?.isEligible === filterState?.eligibility?.value?.eligible) ||
            (!updates?.[item?.idx]?.isEligible === filterState?.eligibility?.value?.ineligible)
          )
        }
      }
      return check
    })
  }, [updates, progresses, documentListDocs, filterState, search]);

  const columnsUploadDocs = [
    {
      id: 'filename',
      name: t("Name"),
      cell: (row) => (
        <div className="flex gap-2 items-center">
          <Tooltip title={row?.file?.name}>
            <span className={`line-clamp-1 ${progresses[row?.idx]?.isError && 'text-[#828282]'}`}>{row?.file?.name}</span>
          </Tooltip>
        </div>
      ),
      width: '30%',
      selector: (row) => row?.file?.name,
      sortable: true
    },
    {
      id: 'loading',
      name: t('Loading'),
      selector: (row) => progresses[row?.idx]?.progress,
      cell: (row) => (
        <div>
          {updates[row?.idx] &&
            <ProgressBarDocument
              isUploadingDocuments={isUploadingDocuments}
              ref={(el) => (refs.current = {
                ...refs.current,
                [row?.idx]: el
              })}
              state={updates[row?.idx]}
              progress={progresses[row?.idx]}
              setProgress={(data) => {
                setProgresses(prev => {
                  return {
                    ...prev,
                    [row?.idx]: {
                      ...prev[row?.idx],
                      ...data
                    }
                  }
                })
              }}
              file={row?.file}
              caseId={data?.caseId}
              token={getAccessToken()}
              onUpdate={(data) => {
                const newUpdates = {
                  [row?.idx]: {
                    ...updates[row?.idx],
                    ...data,
                  }
                }
                setUpdates({
                  ...updates,
                  ...newUpdates
                })
              }}
            />
          }
        </div>
      ),
      width: '200px',
      sortable: true
    },
    {
      id: 'size',
      name: t('size'),
      selector: (row) => humanFileSize(row?.file?.size),
      cell: (row) => (
        <Typography.Text className={`${updates[row?.idx]?.isError && 'text-[#828282]'}`}>
          {humanFileSize(row?.file?.size)}
        </Typography.Text>
      ),
      sortable: true
    },
    {
      id: '',
      name: '',
      selector: (row) => progresses[row?.idx]?.isError,
      cell: (row) => (
        <div className="flex gap-2">
          {!isUploadingDocuments && (
            <div
              className="hover:cursor-pointer"
              onClick={() => {
                deleteDocument(row?.idx)
              }}>
              <Clear className="cursor-pointer text-[#595961] hover:text-[#0049EE]" />
            </div>
          )}

        </div>
      ),
      width: '80px'
    }

  ]

  useEffect(() => {
    if (show && files?.length > 0) {
      let documents = []
      let newProgresses = {}
      let newUpdates = {}
      for (let i = 0; i < files?.length; i++) {

        let generateRandomId = uuidv4()
        do {
          generateRandomId = uuidv4()
        } while (typeof progresses[generateRandomId] !== 'undefined')

        documents?.push({
          size: files[i]?.size,
          file: files[i],
          idx: generateRandomId
        })
        newUpdates = {
          ...newUpdates,
          [generateRandomId]: {
            isFirstDownloaded: false,
            isEligible: false,
            docId: null
          }
        }
        newProgresses = {
          ...newProgresses,
          [generateRandomId]: {
            progress: 0,
            isError: false,
            isSuccess: false,
          }
        }
      }

      setDocumentListDocs((prev) => {
        return [...prev, ...documents]
      })
      setProgresses((prev) => ({ ...prev, ...newProgresses }))
      setUpdates((prev) => ({ ...prev, ...newUpdates }))
    }
  }, [show, files])

  useEffect(() => {
   
    if (Object.keys(progresses).length > 0) {
     
      const allConditionsMet = Object.values(progresses).every(item =>
        (item.isSuccess || item.isError) &&
        item.progress === 100 &&
        item.readyToUpload === false
      );
  
      if (allConditionsMet) {
       
        
        setIsUploadingDocuments(false)
        setDocumentListDocs([])
        refetch()
        closeModal()
      }
    }


  }, [progresses])

  function onConfirmDocument() {
    setIsUploadingDocuments(true)
  }

  /*   async function updateAndClose() {
      const data = []
      Object.keys(updates).map(key => {
        if (updates[key]?.docId !== null) {
          data.push(updates[key]?.docId)
        }
      })
      try {
        await confirmDocument(getAccessToken(), {
          document_ids: data
        })
        showSuccessMessage('Document Uploaded!')
        refetch()
        closeModal()
      } catch (error) {
        const obj = errorObj(error)
        showErrorMessage(obj.resData.errors[0].message)
      }
    } */



  function deleteDocument(selectedIdx) {
    const newDocumentList = documentListDocs.filter((row) => row?.idx !== selectedIdx)
    let newUpdates = { ...updates }
    let newProgresses = { ...progresses }
    delete newUpdates[selectedIdx]
    delete newProgresses[selectedIdx]
    // delete refs.current[selectedIdx]
    setUpdates(() => {
      setDocumentListDocs(newDocumentList)
      setProgresses(newProgresses)
      return newUpdates
    })
  }

  //Search
  const handleSearch = (e) => {
    setSearch(e.target.value)
  }

  useEffect(() => {
    return () => {
      debouncedResults.cancel()
    }
  })

  const debouncedResults = useMemo(() => {
    return debounce(handleSearch, 300)
  }, [])

  // FILTER
  function openFilterModal() {
    setFilterModal(true)
  }
  function closeFilterModal() {
    setFilterModal(false)
  }
  function handleSubmitFilter(data) {
    setFilterState((prev) => ({ ...prev, ...data }))
    closeFilterModal()
  }
  function handleResetFilterState() {
    setFilterState(INITIAL_FILTER_NEW_DOCUMENT)
    closeFilterModal()
  }

  const totalFilterActive = Object?.entries(filterState)?.filter(
    ([, { value }]) => value !== null
  )?.length

  return (
    <ModalUser
      titleComponent={
        <div className="flex justify-between items-center pb-2">
          <span>{t('Upload documents')}</span>
          <CustomButton
            buttonColor={isUploadingDocuments ? 'secondary' : 'primary'}
            disabled={isUploadingDocuments ? true : false}
            onClick={() => uploadRef.current?.click()}>
            <div className="flex gap-1">
              {t('Upload')}
              <AddIcon sx={{ width: '20px', height: '20px' }} />
            </div>
          </CustomButton>
        </div>
      }
      show={show}
      width={900}
      isCloseIconVisible={false}
      onClose={closeModal}
      closable={false}>
      {/* Breadcrumb */}
      <div className="flex justify-between items-center">
        <Typography.Text>{data?.caseName}</Typography.Text>
        {/* Filter & Search */}
        <div className="flex items-center gap-4">
          {/* Search Input */}
          <SearchInput variant="outlined" onSearch={debouncedResults} />
          {/* Filter Wrapper */}
          <div className="flex gap-1 justify-center items-center" onClick={openFilterModal}>
            <FilterListOutlined
              className={`cursor-pointer hover:text-[#0049EE] ${totalFilterActive > 0 && 'text-[#0049EE]'
                }`}
            />
            {/* Badge Total Count */}
            {totalFilterActive > 0 && (
              <div className="w-5 h-5 bg-[#0049EE] px-[6px] flex justify-center items-center rounded-full text-white">
                {totalFilterActive}
              </div>
            )}
          </div>
        </div>
      </div>
      <Table
        backgroundColorHeadCells="#F7F7F8"
        defaultSortFieldId={'created_at'}
        defaultSortAsc={false}
        noDataComponent={
          <div className="flex w-full justify-start items-center text-[#828282] p-4">
            {t('There are no documents uploaded in this folder')}
          </div>
        }
        fixedHeader
        className={'flex-auto h-[300px] overflow-y-auto pr-6'}
        hidePagination
        columns={columnsUploadDocs}
        data={filteredItems}
      />
      {/* Button Wrapper */}
      <div className="flex justify-end items-center gap-4">
        <CustomButton
          disabled={isUploadingDocuments}
          buttonColor="secondary" onClick={closeModal}>
          {t('Cancel')}
        </CustomButton>
        <CustomButton
          disabled={isUploadingDocuments}
          // buttonColor={Object.keys(progresses).filter(key => progresses[key]?.progress < 100).length > 0 ? 'secondary' : 'primary'}
          // disabled={Object.keys(progresses).filter(key => progresses[key]?.progress < 100).length > 0 ? true : false}
          onClick={onConfirmDocument}
        >
          {t('Confirm')}
        </CustomButton>
      </div>

      {filterModal && (
        <FilterDocumentModal
          onClose={closeFilterModal}
          data={filterState}
          show={filterModal}
          resetFilter={handleResetFilterState}
          onSubmit={handleSubmitFilter}
        />
      )}

      <input
        className={`hidden`}
        type="file"
        id="file"
        accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/jpeg,image/png,.pdf,text/plain"
        ref={uploadRef}
        style={{ display: 'none' }}
        multiple
        onClick={(event) => {
          event.target.value = ''
        }}
        onChange={(e) => {
          if (e.target.files.length > 0) {


            setDocumentListDocs([])
            setProgresses([])
            setUpdates([])
            const validatedFiles = []
            for (let i = 0; i < e.target.files.length; i++) {
              if (e.target.files[i].size > 20971520) {
                showErrorMessage(`File ${e.target.files[i].name} supera i 20MB`)
              } else {
                validatedFiles.push(e.target.files[i])
              }
            }


            const newFiles = [...files, ...validatedFiles]

            let documents = []
            let newProgresses = {}
            let newUpdates = {}
            for (let i = 0; i < newFiles?.length; i++) {

              let generateRandomId = uuidv4()
              do {
                generateRandomId = uuidv4()
              } while (typeof progresses[generateRandomId] !== 'undefined')

              documents?.push({
                size: newFiles[i]?.size,
                file: newFiles[i],
                idx: generateRandomId
              })
              newUpdates = {
                ...newUpdates,
                [generateRandomId]: {
                  isFirstDownloaded: false,
                  isEligible: false,
                  docId: null
                }
              }
              newProgresses = {
                ...newProgresses,
                [generateRandomId]: {
                  progress: 0,
                  isError: false,
                  isSuccess: false,
                }
              }
            }

            setDocumentListDocs((prev) => {
              return [...prev, ...documents]
            })
            setProgresses((prev) => ({ ...prev, ...newProgresses }))
            setUpdates((prev) => ({ ...prev, ...newUpdates }))


            handleResetFilterState()

          }
        }}
      />
    </ModalUser>
  )
}

export default AddDocumentModal
