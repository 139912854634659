import React from 'react'
import { Close } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

const FilterSidebar = ({ show, onClose, children }) => {
  const { t } = useTranslation();

  return (
    <>
      <div
        className={`top-0 right-0 w-[25vw] overflow-y-auto bg-[#1A1A1A] px-4 py-6 fixed h-screen z-40 ease-in-out duration-300 ${
          show ? 'translate-x-0 ' : 'translate-x-full'
        }`}>
        {/* WRAPPER */}
        <div className="h-full flex flex-col justify-between">
          {/* HEADER */}
          <div className="flex justify-between items-center pb-6">
            <label className="text-white text-2xl">{t('Filters')}</label>
            <div onClick={onClose} className="p-2">
              <Close className={'text-white float-end hover:cursor-pointer'} size={12} />
            </div>
          </div>
          {/* MAIN CONTENT */}
          {children}
        </div>
      </div>
      <div
        className={`flex fixed top-0 right-0 bottom-0 left-0 bg-black/50 z-30 ${
          show ? 'flex' : 'hidden'
        }`}
        onClick={onClose}
      />
    </>
  )
}

export default FilterSidebar
