import { Box, Button } from '@mui/material'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import TextFields from 'components/molecules/TextFields'
import ErrorMessage from 'components/atoms/ErrorMessage'
import { Typography } from 'antd'
import { isAdminPage } from 'utils/isAdminPage'
import { useMutation } from 'react-query'
import { postForgotPassword } from 'api'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { errorObj } from 'utils/handleError'
import { showErrorMessage } from 'utils/showMessage'
import useNavigatorOnLine from 'hooks/useNavigatorOnline'
import { BACK_OFFICE, PLATFORM } from 'constant'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const schema = z.object({
  email: z.string().email({ message: 'Invalid email address' })
})
const ResetPasswordForm = () => {
  const { t } = useTranslation();

  const statusConnection = useNavigatorOnLine()
  const navigate = useNavigate()
  const {
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
    setError,
    control
  } = useForm({
    defaultValues: {
      email: ''
    },
    resolver: zodResolver(schema)
  })
  const forgotPassword = useMutation({
    mutationKey: 'forgotPassword',
    mutationFn: (email) => postForgotPassword(email, isAdminPage() ? BACK_OFFICE : PLATFORM),
    onSuccess: (res) => {
      localStorage.setItem('tokenOtp', res.resData.data)
      localStorage.setItem('emailReset', getValues('email'))
      navigate(
        {
          pathname: isAdminPage() ? '/admin/reset-password' : '/reset-password',
          search: createSearchParams({
            token: res.resData.data,
            email: getValues('email')
          }).toString()
        },
        {
          replace: true
        }
      )
    },
    onError: (err) => {
      const errObj = errorObj(err)
      if (errObj.resStatus === 422) {
        const token = localStorage.getItem('tokenOtp')
        navigate(
          {
            pathname: isAdminPage() ? '/admin/reset-password' : '/reset-password',
            search: createSearchParams({
              token: token,
              email: getValues('email')
            }).toString()
          },
          {
            replace: true
          }
        )
      } else if (
        errObj.resStatus === 400 &&
        errObj.resData.errors[0].message == 'Deleted Account'
      ) {
        showErrorMessage(t('error messages.Deleted account'))
      } else {
        setError('email', { message: 'No account is registered with this email' })
      }
    }
  })

  const processForm = (data) => {
    if (!statusConnection) return showErrorMessage(t('error messages.Internet connection error'))
    return !forgotPassword.isLoading ? forgotPassword.mutate(data?.email) : false
  }
  return (
    <>
      {/* Typography */}
      <div className="flex flex-col justify-center items-center gap-2 ">
        <span
          className={`${isAdminPage() ? '!text-white' : 'text-black'} font-bold text-lg text-h4`}>
          {isAdminPage() ? 'Forgot password?' : 'Reset Password'}
        </span>
        <Typography.Paragraph className="text-[#595961] text-body1">
          {isAdminPage()
            ? t("auth.resetPswAdmin")
            : t("auth.resetPswUser")}
        </Typography.Paragraph>
      </div>
      <Box
        noValidate
        component="form"
        onSubmit={handleSubmit(processForm)}
        className="flex flex-col  w-full">
        {/* Form */}
        <div className="flex flex-col gap-4 justify-center items-center">
          <TextFields control={control} label={'Email'} name={'email'} errors={errors} />
        </div>

        {/* Submit Button */}
        <Button
          disabled={isSubmitting}
          type="submit"
          fullWidth
          variant="contained"
          sx={{
            py: 1,
            mt: 4,
            backgroundColor: '#0049EE',
            fontWeight: 600,
            fontSize: '16px'
          }}>
          {isAdminPage() ? t("auth.sendLink") : t("auth.sendRecoveryLink")}
        </Button>
        {errors['root'] ? (
          <ErrorMessage className={'mt-2'} errorMessage={errors['root'].message} />
        ) : null}
      </Box>
    </>
  )
}

export default ResetPasswordForm
