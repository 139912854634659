import React from 'react'
import { Controller } from 'react-hook-form'
import { Checkbox, ListItemText, MenuItem, TextField } from '@mui/material'
import ErrorMessage from 'components/atoms/ErrorMessage'

const SelectCheckboxCaseFields = ({
  control,
  label,
  name,
  errors,
  theme = 'users',
  options = [],
  isRequired = false,
  ...selectProps
}) => {
  return (
    <div className="w-full flex flex-col gap-2">
      <Controller
        name={name}
        control={control}
        required={isRequired}
        render={({ field }) => (
          <>
            <TextField
            sx={{
              
              '& .MuiInput-underline:before': {
                borderBottomColor: 'rgba(0, 0, 0, 0.12)',
              },
              '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                borderBottomColor: '#0049EE',
              },
              '& .MuiInput-underline:after': {
                borderBottomColor: '#0049EE',
              },
            }}
              required={isRequired}
              select
              label={
                theme === 'admin' ? null : (
                  <span className={`${errors[name] ? '!text-red-500' : ''} }`}>{label}</span>
                )
              }
              {...field}
              SelectProps={{
                multiple: true,
                value: field?.value,
                renderValue: (value) => {
                  const result = value?.map(item => {
                    let idx = options?.findIndex(option => option?.value === item)
                    return options[idx]?.label
                  })
                  return result.join(", ")
                },
                ...selectProps
              }}
            >
              {options.map((option, index) => (
                <MenuItem
                  key={`select-checkbox-fields-${index}`}
                  value={option?.value}
                >
                  <Checkbox style={{ height: 15 }} checked={field?.value?.includes(option?.value)} />
                  <ListItemText primary={option?.label} />
                </MenuItem>              
              ))}
            </TextField>
          </>
        )}
      />
      {errors[name] ? <ErrorMessage errorMessage={errors[name].message} /> : null}
    </div>
  )
}
export default SelectCheckboxCaseFields
