import { zodResolver } from '@hookform/resolvers/zod'
import { TextField } from '@mui/material'
import { getUserProfile, putUpdateEmailPlatform, putUploadAvatar } from 'api'
import { NullAvatar } from 'assets/images'
import CustomButton from 'components/atoms/CustomButton'
import TextFields from 'components/molecules/TextFields'
import useAuth from 'hooks/useAuth'
import { capitalize } from 'lodash'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import styled from 'styled-components'
import { showErrorMessage, showSuccessMessage } from 'utils/showMessage'
import { z } from 'zod'
import { useTranslation } from 'react-i18next'

const styleTextField =
  'flex flex-col gap-1 w-full max-w-[340px] border-solid border-0 border-b border-[#E3E3E8] text-lg text-[#828282] font-normal'
const schema = z.object({
  email: z.string().email({ message: 'Invalid email address' })
})

const VisuallyHiddenInput = styled(TextField)({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
})

const ProfilePlatformContent = () => {
  const { t } = useTranslation()

  const { getAccessToken, platformData, handleSetProfile,onLogout } = useAuth()
  const {
    handleSubmit,
    formState: { errors, isDirty, isValid },
    control
  } = useForm({
    defaultValues: {
      email: platformData?.profile?.email ? platformData?.profile?.email : ''
    },
    values: {
      email: platformData?.profile?.email ? platformData?.profile?.email : ''
    },
    resolver: zodResolver(schema)
  })
  const { refetch } = useQuery({
    queryKey: 'getProfile',
    queryFn: () => getUserProfile(getAccessToken()),
    onSuccess: (res) => {
      handleSetProfile(res?.resData?.data, res?.resData?.data?.role)
    },
    onError: (err) => {
      showErrorMessage(t('error messages.Could not update your profile, please reload the page'))
      if(err.response.status === 403){
        onLogout()
      }
    },
    
    enabled: true
  })

  const putUploadAvatarMutation = useMutation({
    mutationKey: 'putUploadAvatar',
    mutationFn: (file) => putUploadAvatar(getAccessToken(), file),
    onSuccess: () => {
      refetch()
      showSuccessMessage(t('success messages.Avatar has been uploaded'))
    },
    onError: () => {
      showErrorMessage(t('error messages.Avatar was not possible to upload'))
    }
  })
  const putEditEmailMutation = useMutation({
    mutationKey: 'putEditEmail',
    mutationFn: (email) => putUpdateEmailPlatform(getAccessToken(), email),
    onSuccess: () => {
      refetch()
      showSuccessMessage(t('success messages.Email changes saved'))
    },
    onError: () => {
      showErrorMessage(t('error messages.Email was not possible to save the changes'))
    }
  })

  function handleUploadAvatar(e) {
    if (e.target.files[0].type.includes('image')) {
      let formData = new FormData()
      formData.append('file', e.target.files[0])
      putUploadAvatarMutation.mutate(formData)
    } else {
      showErrorMessage(t('error messages.Only Accept Image File'))
    }
  }

  function handleSubmitEmail(data) {
    putEditEmailMutation.mutate(data?.email)
  }

  return (
    <div className="flex flex-col flex-grow gap-12">
      {/* Title */}
      <h1 className="text-black text-2xl font-normal">{t('Profile')}</h1>
      {/* Content */}
      <div className="grid grid-cols-[40%_60%] h-full w-full gap-8">
        {/* Left */}
        <div className="flex flex-grow flex-col border-solid border-0 border-r border-[#E3E3E8] gap-8 items-center">
          {/* title */}
          <div className="w-full">
            <h2 className="font-semibold text-base text-left ">{t('profile.Profile picture')}</h2>
          </div>
          {/* Avatar */}
          <div className="flex flex-col items-center leading-3 gap-4 w-52 justify-center">
            <div className="rounded-full overflow-hidden flex justify-center mx-auto items-center w-52 h-52 bg-[#F7F7F8] border border-solid border-[#E3E3E8]">
              <img
                src={platformData?.profile?.avatar_url || NullAvatar}
                className={`${
                  platformData?.profile?.avatar_url
                    ? 'object-cover w-full h-full'
                    : 'object-contain'
                } object-center`}
                alt="avatar"
                aria-label="avatar profile"
              />
            </div>
            <span className="text-[#595961] text-center text-sm font-normal ">
              {t('profile.Upload your profile picture')}
            </span>
          </div>
          <div className="flex justify-center items-center pt-2 w-full">
            <CustomButton
              component="label"
              isLoading={putUploadAvatarMutation.isLoading}
              role={undefined}
              tabIndex={-1}>
              {t('profile.Upload Avatar')}
              <VisuallyHiddenInput
                type="file"
                onChange={putUploadAvatarMutation.isLoading ? () => {} : handleUploadAvatar}
                inputProps={{ accept: 'image/*' }}
              />
            </CustomButton>
          </div>
        </div>
        {/* Right */}
        <div className="flex flex-col flex-grow gap-8">
          {/* title */}
          <h2 className="font-semibold text-base">{t('profile.General Information')}</h2>

          {/* Content */}
          <div className="flex flex-col flex-grow gap-16">
            {/* Information */}
            <div className="flex gap-4 flex-col">
              <div className={styleTextField}>
                <span className="text-sm">{t('Name')}</span>
                <span>
                  {platformData?.profile?.name && platformData?.profile?.surname
                    ? `${platformData?.profile?.name} ${platformData?.profile?.surname}`
                    : '-'}
                </span>
              </div>
              <div className={styleTextField}>
                <span className="text-sm">{t('Role')}</span>
                <span>{capitalize(platformData?.role) || '-'}</span>
              </div>
              <div className={styleTextField}>
                <span className="text-sm">{t('Company')}</span>
                <span>{platformData?.profile?.company?.name || '-'}</span>
              </div>
            </div>
            {/* Edit Email */}
            <div className="flex flex-col gap-8 max-w-[340px]">
              {/* title */}
              <h2 className="font-semibold text-base">{t('profile.Email')}</h2>
              <div className="flex flex-col gap-4">
                <TextFields
                  theme="admin"
                  control={control}
                  autoComplete="off"
                  name={'email'}
                  errors={errors}
                  label={t('Email')}
                  sx={{
                    '& .MuiInput-root': {
                      marginTop: '26px'
                    },
                    '& .MuiInputLabel-root': {
                      fontSize: '14px'
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                      fontSize: '14px'
                    },
                    '& .MuiInputLabel-shrink': {
                      transform: 'none' // Prevent label from shrinking
                    }
                  }}
                />
                <div>
                  <CustomButton
                    buttonColor={isDirty && isValid ? 'primary' : 'secondary'}
                    onClick={
                      putEditEmailMutation.isLoading ? () => {} : handleSubmit(handleSubmitEmail)
                    }
                    isLoading={putEditEmailMutation.isLoading}
                    disabled={!isDirty || !isValid}>
                    {t('Save Changes')}
                  </CustomButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfilePlatformContent
