import { Typography } from 'antd'
import { useTranslation } from 'react-i18next'

export const BookmarksList = ({ items = [], jumpToPage }) => {
  const { t } = useTranslation()

  return (
    <div>
      <div className="font-semibold text-xl text-black text-center my-5">{t('document.index')}</div>
      {items?.length < 1 ? (
        <Typography.Text>{t('document.noIndex')}</Typography.Text>
      ) : (
        items?.map((bookmark, index) => {
          return (
            <div
              key={`bookmark-doc-${index}`}
              className=" hover:cursor-pointer hover:bg-[#DEDEDE] p-1 rounded-sm"
              onClick={() => jumpToPage(bookmark.page_from - 1)}>
              <>
                {items?.[index - 1]?.title !== bookmark?.title ? (
                  <li>
                    <Typography.Text className="font-bold bookmark_text">{bookmark?.title}</Typography.Text>
                    <br />
                  </li>
                ) : (
                  <></>
                )}
               {/*  <Typography.Text className="text-xs font-bold text-gray-500">
                  {bookmark?.subtitle || '-'}
                </Typography.Text>
                <br />
                <Typography.Text className="text-xs">{bookmark?.excerpt}</Typography.Text> */}
              </>
            </div>
          )
        })
      )}
    </div>
  )
}
