import React, { useEffect } from 'react'
import { Controller } from 'react-hook-form'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import ErrorMessage from 'components/atoms/ErrorMessage'
import moment from 'moment'
import { it } from 'date-fns/locale/it';
import { enGB } from 'date-fns/locale/en-GB';
import i18next from 'i18next'
const DatePickerUserFields = ({
  control,
  label,
  name,
  errors,
  theme = 'users',
  options = [],
  isRequired = false,
  defaultValue,
  ...datePickerProps
}) => {


  const currentLang = i18next.languages[0]


  return (
    <div className="w-full flex flex-col gap-2">
      <Controller
        name={name}
        control={control}
        required={isRequired}
        render={({ field }) => (


          <>
            
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={currentLang === 'it' ? it : enGB}>
              <DatePicker
                sx={{

                  '& .MuiInput-underline:before': {
                    borderBottomColor: 'rgba(0, 0, 0, 0.12)',
                  },
                  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                    borderBottomColor: '#0049EE',
                  },
                  '& .MuiInput-underline:after': {
                    borderBottomColor: '#0049EE',
                  },
                }}
                {...field}
                onChange={(v) => {
                  
                  field.onChange(v)
                }}
                value={field.value ? field.value : new Date()}
                label={
                  theme === 'admin' ? null : (
                    <span className={`${errors[name] ? '!text-red-500' : ''} }`}>{label}</span>
                  )
                }
                slotProps={{ textField: { size: 'small' } }}
                defaultValue={null}
                disableFuture
              />
            </LocalizationProvider>
          </>
        )}
      />
      {errors[name] ? <ErrorMessage errorMessage={errors[name].message} /> : null}
    </div>
  )
}
export default DatePickerUserFields
