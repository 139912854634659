import { TablePagination } from '@mui/material'
import CaseCard from 'components/molecules/user/cases/CaseCard'
import moment from 'moment'
import React from 'react'

const GridCases = ({
  data = [],
  total = 0,
  onViewDetail = () => {},
  onEdit = () => {},
  onExport = () => {},
  onDelete = () => {}
}) => {
  return (
    <div className="h-full w-full flex flex-col justify-between">
      <div className="w-full overflow-visible	pb-9 pr-6 grid gap-x-9 gap-y-12 grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 min-[2200px]:grid-cols-6 min-[2400px]:grid-cols-7">
        {data?.map((caseItem, index) => (
          <CaseCard
            key={index}
            onDeleteCase={onDelete.bind(this, caseItem)}
            onEdit={() => onEdit(caseItem)}
            onExport={onExport.bind(this, caseItem)}
            onViewDetail={() => onViewDetail(caseItem)}
            titleCase={caseItem?.name}
            statusCase={caseItem?.case_status}
            date={moment(caseItem?.updated_at).format('DD/MM/YYYY')}
            totalChat={caseItem?.chat_total}
            totalDocs={caseItem?.document_total}
            totalNotes={caseItem?.note_total}
          />
        ))}
      </div>
      {/* <div className="pr-6 flex justify-end items-center mt-3"> */}
      <div className="pr-6">
        {/* Count == TOTAL_ITEMS, rowsPerPage === ITEMS_PER_PAGE */}
        <TablePagination
          labelRowsPerPage={null}
          slotProps={{
            select: {
              style: {
                display: 'none'
              }
            }
          }}
          component="div"
          count={total}
          page={0}
          onPageChange={() => {}}
          rowsPerPage={40}
        />
      </div>
    </div>
  )
}

export default GridCases
