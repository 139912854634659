import React, { useRef, useState, forwardRef, useImperativeHandle } from 'react'
import { Add } from '@mui/icons-material'
import { Tabs, Tab } from '@mui/material'
import { Typography } from 'antd'
import { IcSplate } from 'assets/icons'
import ListNotes from 'components/molecules/user/cases/ListNotes'
import { useQuery } from 'react-query'
import { getNoteListDetailCase } from 'api'
import { errorObj } from 'utils/handleError'
import { showErrorMessage } from 'utils/showMessage'
import { getNext5Minute } from 'utils/date'
import { useSelector } from 'react-redux'
import Slide from '@mui/material/Slide'
import DialogExpandNote from './DialogExpandNote'
import { NOTE_CATEGORY, NOTE_TYPE } from '../../../../constant'

import { Popover } from 'antd'
import { INITIAL_NOTES_INSIGHT_FILTER, INITIAL_NOTES_TODO_FILTER } from 'constant'
import { useTranslation } from 'react-i18next'

import { ZoomOutMapOutlined } from '@mui/icons-material'
const initialNoteState = {
  total: 0,
  page: 1,
  limit: 0,
  search: '',
  sort: ''
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const NotesContainer = ({ setTabNote, tabNote, height, caseId, caseName }, ref) => {
  const noteHeadRef = useRef()
  const { t } = useTranslation()
  const token = useSelector((state) => state.user.access_token)
  const [noteStateInsight, setNoteStateInsight] = useState(initialNoteState)
  const [noteStateTodo, setNoteStateTodo] = useState(initialNoteState)
  const [filterNoteInsight, setFilterNoteInsight] = useState(INITIAL_NOTES_INSIGHT_FILTER)
  const [filterNoteTodo, setFilterNoteTodo] = useState(INITIAL_NOTES_TODO_FILTER)
  const [noteDataInsight, setNoteDataInsight] = useState([])
  const [noteDataToDo, setNoteDataTodo] = useState([])
  const [openExpandNote, setOpenExpandNote] = useState(false)
  const [totalNoteInsight, setTotalNoteInsight] = useState(0)
  const [totalNoteTodo, setTotalNoteTodo] = useState(0)

  useQuery({
    queryKey: ['note-list-insight', filterNoteInsight.data, noteStateInsight],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      let note_category = {
        Free: NOTE_CATEGORY.INSIGHT
      }
      if (filterNoteInsight.data.topology?.value) {
        note_category = filterNoteInsight.data.topology?.value
      }
      return await getNoteListDetailCase(
        token,
        filterNoteInsight.data,
        noteStateInsight,
        caseId,
        note_category
      )
    },
    onSuccess: (data) => {
      const newValue = data.resData.data.map((note) => ({
        ...note,
        edit: false,
        needToBeSaved: false
      }))
      setNoteDataInsight(newValue)
      setNoteStateInsight((prev) => ({
        ...prev,
        total: data.resData.meta.total,
        limit: data.resData.meta.limit,
        page: data.resData.meta.page
      }))
      setTotalNoteInsight(data.resData.meta.total)
    },

    onError: (err) => {
      const obj = errorObj(err)
      showErrorMessage(obj.resData.errors[0]?.message)
    },
    enabled: tabNote === 0 && !filterNoteInsight.show
  })
  useQuery({
    queryKey: ['note-list-todo', filterNoteTodo.data, noteStateTodo],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      let note_type = {
        Todo: 'TODO'
      }
      return await getNoteListDetailCase(
        token,
        filterNoteTodo.data,
        noteStateTodo,
        caseId,
        note_type
      )
    },
    onSuccess: (data) => {
      const newValue = data.resData.data.map((note) => ({
        ...note,
        edit: false,
        needToBeSaved: false
      }))
      setNoteDataTodo(newValue)
      setNoteStateTodo((prev) => ({
        ...prev,
        total: data.resData.meta.total,
        limit: data.resData.meta.limit,
        page: data.resData.meta.page
      }))
      setTotalNoteTodo(data.resData.meta.total)
    },
    onError: (err) => {
      const obj = errorObj(err)
      showErrorMessage(obj.resData.errors[0]?.message)
    },
    enabled: tabNote === 1 && !filterNoteTodo.show
  })

  const handleOpenExpandNote = () => {
    setOpenExpandNote(true)
  }
  const handleCloseExpandNote = () => {
    setOpenExpandNote(() => {
      setFilterNoteInsight(INITIAL_NOTES_INSIGHT_FILTER)
      setFilterNoteTodo(INITIAL_NOTES_TODO_FILTER)
      return false
    })
  }
  useImperativeHandle(ref, () => ({
    addNewNote
  }))

  const addNewNote = (options) => {
    // console.log('addNewNote', options)
    if (options.type === NOTE_TYPE.TODO) {
      setTabNote(1)
      setNoteDataTodo((prev) => [
        {
          id: Math.random(),
          note_type: options.type ?? NOTE_TYPE.TODO,
          title: '',
          description: '',
          todo_date: getNext5Minute().toISOString(),
          created_at: new Date().toISOString(),
          edit: false,
          isNewData: true
        },
        ...prev
      ])
    } else {
      setTabNote(0)

      setNoteDataInsight((prev) => [
        {
          id: Math.random(),
          note_type: options.type ?? NOTE_TYPE.FREE,
          title: '',
          description: '',
          created_at: new Date().toISOString(),
          edit: false,
          isNewData: true,
          needToBeSaved: options.type === NOTE_TYPE.CHAT,
          ...options
        },
        ...prev
      ])
    }
  }

  const optionsPopover = [
    {
      title: t('note.insight'),
      type: NOTE_TYPE.FREE,
      callback: addNewNote,
      role: ['admin', 'editor']
    },
    {
      title: t('note.todo'),
      type: NOTE_TYPE.TODO,
      callback: addNewNote,
      role: ['admin']
    }
  ]

  return (
    <div className="w-full col-span-3">
      <div className="flex justify-between items-center" ref={noteHeadRef}>
        <div>
          <Typography.Text className="text-[24px] flex items-center">
            {t('note.notes')}
            <Popover
              content={
                <div className="w-[158px] h-full flex flex-col text-black">
                  {optionsPopover.map(
                    (option, index) =>
                      option.role.includes('admin') && (
                        <div
                          key={index}
                          onClick={() => {
                            option.callback({ title: option.title, type: option.type })
                          }}
                          className="px-2 py-1 cursor-pointer hover:bg-[#0000000A] text-black rounded text-sm font-normal">
                          {option.title}
                        </div>
                      )
                  )}
                </div>
              }
              trigger="click"
              placement="leftTop"
              arrow={false}>
              <Add className="ml-3 cursor-pointer" />
            </Popover>
          </Typography.Text>
          <Tabs
            textColor="inherit"
            sx={{
              minHeight: 0,
              height: '42px'
            }}
            TabIndicatorProps={{
              style: {
                borderRadius: 20,
                height: 5,
                margin: 0,
                marginTop: 0,
                backgroundColor: '#0049EE'
              }
            }}
            value={tabNote}
            onChange={(_, tabName) => {
              setTabNote(tabName)
            }}>
            <Tab
              disableRipple
              fullWidth={false}
              sx={{
                alignItems: 'start',
                minWidth: 0,
                fontFamily: 'Inter',
                fontWeight: 500,
                textTransform: 'none',
                padding: 0,
                marginRight: 2
              }}
              label={t('note.insight')}
              value={0}
            />
            <Tab
              disableRipple
              fullWidth={false}
              sx={{
                alignItems: 'start',
                minWidth: 0,
                fontFamily: 'Inter',
                fontWeight: 500,
                textTransform: 'none',
                padding: 0
              }}
              label={t('note.todo')}
              value={1}
            />
          </Tabs>
        </div>

        <div
          className={`mt-3 w-16 h-24 mr-2 shrink-0 cursor-pointer bg-white text-[#828282] border border-solid border-[#E3E3E8] hover:text-[#0049EE] hover:bg-[#EDEDEF] flex flex-col rounded-[10px] justify-center items-center  gap-1 text-xs`}
          onClick={handleOpenExpandNote}>
          <span className="text-lg font-semibold">
            {tabNote === 0 ? totalNoteInsight : totalNoteTodo}
          </span>
          <span>Note</span>
          <ZoomOutMapOutlined style={{ fontSize: '18px' }} />
        </div>
      </div>
      <ListNotes
        height={height}
        dataInsight={noteDataInsight}
        dataTodo={noteDataToDo}
        value={tabNote}
        setNoteDataInsight={setNoteDataInsight}
        noteDataInsight={noteDataInsight}
        setNoteDataTodo={setNoteDataTodo}
        caseId={caseId}
        onAdd={(title, type) => {
          addNewNote({ title: title, type: type })
        }}
      />
      <DialogExpandNote
        openExpandNote={openExpandNote}
        handleCloseExpandNote={handleCloseExpandNote}
        caseName={caseName}
        setTabNote={setTabNote}
        tabNote={tabNote}
        height={height}
        filterNoteInsight={filterNoteInsight}
        filterNoteTodo={filterNoteTodo}
        setFilterNoteInsight={setFilterNoteInsight}
        setFilterNoteTodo={setFilterNoteTodo}
        dataInsight={noteDataInsight}
        dataTodo={noteDataToDo}
        setNoteDataInsight={setNoteDataInsight}
        setNoteDataTodo={setNoteDataTodo}
        caseId={caseId}
        handleSearch={(obj) => {
          if (obj.tabNote === 0) {
            setNoteStateInsight((prev) => ({ ...prev, search: obj.value }))
          } else {
            setNoteStateTodo((prev) => ({ ...prev, search: obj.value }))
          }
        }}
      />
    </div>
  )
}

export default forwardRef(NotesContainer)
