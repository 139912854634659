import { zodResolver } from '@hookform/resolvers/zod'
import CustomButton from 'components/atoms/CustomButton'
import DatePickerFields from 'components/molecules/DatePickerFields'
import FilterSidebar from 'components/molecules/FilterSidebar'
import SelectFields from 'components/molecules/SelectFields'
import TextFields from 'components/molecules/TextFields'
import moment from 'moment'
import React from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { useTranslation } from 'react-i18next'

const schema = z.object({
  typeCountUsers: z.string().optional(),
  startUserNumbers: z.string().optional(),
  endUserNumbers: z.string().optional(),
  typeDate: z.string().optional(),
  startDate: z.instanceof(moment).optional(),
  endDate: z.instanceof(moment).optional()
})

const FilterCompaniesModal = ({ show, onClose, onSubmit = () => {}, resetFilter, filterState }) => {
  const { t } = useTranslation()

  const staticDate = [
    {
      label: t('Less than'),
      value: 'less_than'
    },
    {
      label: t('Greater than'),
      value: 'greater_than'
    },
    {
      label: t('Between'),
      value: 'between'
    }
  ]

  const {
    handleSubmit,
    reset,
    formState: { errors },
    control,
    setError,
    watch,
    getValues,
    setValue
  } = useForm({
    defaultValues: {
      typeCountUsers: filterState.typeCountUsers || undefined,
      startUserNumbers: filterState.startUserNumbers || '',
      endUserNumbers: filterState.endUserNumbers || '',
      typeDate: filterState.typeDate || undefined,
      startDate: filterState.startDate || undefined,
      endDate: filterState.endDate || undefined
    },
    resolver: zodResolver(schema)
  })
  const watchTypeDate = watch('typeDate')
  const watchFilterUsers = watch('typeCountUsers')

  function closeModal() {
    reset()
    onClose()
  }

  async function processForm(data) {
    let newData = data
    let valid = true
    // IF typeCountUsers not selected while startUserNumbers has value
    if (watchFilterUsers == undefined && data.startUserNumbers) {
      return setError('typeCountUsers', { message: 'Required' })
    }
    // if typeCountUsers not between but endUserNumbers has value
    if (watchFilterUsers !== 'between' && getValues('endUserNumbers')) {
      newData = { ...newData, endUserNumbers: undefined }
    }
    // if typeCountUsers has value but startUserNumbers n endUserNumbersdon't have value
    if (watchFilterUsers && !data.startUserNumbers && !data.endUserNumbers) {
      newData = { ...newData, endUserNumbers: undefined }
    }
    // if typeCountUsers value is between but endUserNumbers or startUserNumbers doesn't has value
    if (watchFilterUsers === 'between') {
      if (!data.startUserNumbers) {
        valid = false
        setError('startUserNumbers', { message: 'Required' })
      }
      if (!data.endUserNumbers) {
        valid = false
        setError('endUserNumbers', { message: 'Required' })
      }
    }

    // IF typedate not selected while startdate has value
    if (watchTypeDate == undefined && data.startDate) {
      return setError('typeDate', { message: 'Required' })
    }
    // if typedate not between but enddate has value
    if (watchTypeDate !== 'between' && getValues('endDate')) {
      newData = { ...newData, endDate: undefined }
    }
    // if typedate has value but start date n end date don't have value
    if (watchTypeDate && !data.startDate && !data.endDate) {
      newData = { ...newData, typeDate: undefined }
    }
    // if typedate value is between but enddate or start date doesn't has value
    if (watchTypeDate === 'between') {
      if (!data.startDate) {
        valid = false
        setError('startDate', { message: 'Required' })
      }
      if (!data.endDate) {
        valid = false
        setError('endDate', { message: 'Required' })
      }
    }

    if (valid) {
      onSubmit(newData)
      onClose()
    }
  }

  function resetForm() {
    reset()
    setValue('startUserNumbers', '')
    setValue('endUserNumbers', '')
    resetFilter()
    onClose()
  }

  return (
    <FilterSidebar show={show} onClose={closeModal}>
      <div className="flex flex-col justify-between h-full">
        <div className="flex flex-col gap-6">
          {/* Date Select */}
          <SelectFields
            labelClassName={'!text-white'}
            control={control}
            label={t('Users Numbers')}
            placeholder={t('choose user numbers')}
            errors={errors}
            name={'typeCountUsers'}
            options={staticDate}
          />
          <TextFields
            theme="admin"
            control={control}
            name={'startUserNumbers'}
            placeholder={t('Enter value')}
            variant="filled"
            type="number"
            errors={errors}
          />
          {watchFilterUsers === 'between' ? (
            <TextFields
              theme="admin"
              type="number"
              placeholder={t('Enter value')}
              control={control}
              name={'endUserNumbers'}
              variant="filled"
              errors={errors}
            />
          ) : null}

          {/* Date Select */}
          <SelectFields
            labelClassName={'!text-white'}
            control={control}
            label={t('Date')}
            placeholder={t('Choose Date')}
            errors={errors}
            name={'typeDate'}
            options={staticDate}
          />

          <DatePickerFields
            labelClassName={'!text-white'}
            control={control}
            name={'startDate'}
            placeholder={t('Choose Date')}
            errors={errors}
          />
          {watchTypeDate === 'between' && (
            <DatePickerFields
              labelClassName={'!text-white'}
              control={control}
              name={'endDate'}
              placeholder={t('Choose Date')}
              errors={errors}
            />
          )}
        </div>
        <div className="flex gap-2 justify-end items-center">
          <CustomButton buttonColor="secondary" onClick={resetForm}>
            {t('cancel filters')}
          </CustomButton>
          <CustomButton onClick={handleSubmit(processForm)}>{t('apply')}</CustomButton>
        </div>
      </div>
    </FilterSidebar>
  )
}

export default FilterCompaniesModal
