import { Radio } from '@mui/material'
import { Tooltip } from 'antd'
import CustomButton from 'components/atoms/CustomButton'
import Table from 'components/atoms/Table'
import humanFileSize from 'lib/getHumanFileSize'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { IcDocumentEmpty } from 'assets/icons'
import { DOCUMENT_TYPE } from 'constant'


const rowDisabled = (row) => !row.is_eligible || row.status != "SUCCEEDED" || row.sub_status != "SUCCEEDED"

const SelectProcessedDocsNewChatTable = ({
  isFetchingDocumentByCase,
  handleSort,
  totalFilterActive,
  tableState,
  handleChangeSelectedDocuments,
  documentListByCase,
  selectedDocuments,
  closeModal,
  handleProcessDocument,
  handleProcessAllDocument
}) => {
  const { t } = useTranslation();

  const columnsSpecificCases = [
    {
      id: 'filename',
      name: t('Name'),
      cell: (row) => (
        <div className="flex gap-2 items-center">
          <Tooltip title={row?.filename}>
            <span
              className={`line-clamp-1 ${!row.is_eligible || row.status != "SUCCEEDED" || row.sub_status != "SUCCEEDED" ? "text-[#828282]" : "text-black"}`}>{row?.filename}</span>
          </Tooltip>
        </div>
      ),
      //width: '20%',
      selector: (row) => row?.filename,
      sortable: true
    },
    {
      id: 'created_at',
      name: t('Upload Date'),
      selector: (row) => moment(row?.created_at).format('DD/MM/YYYY'),
      cell: (row) => (
        <div>
          <Tooltip title={moment(row?.created_at).format('DD/MM/YYYY')}>
            <span className={`line-clamp-1 ${!row.is_eligible || row.status != "SUCCEEDED" || row.sub_status != "SUCCEEDED" ? "text-[#828282]" : "text-black"}`}>{moment(row?.created_at).format('DD/MM/YYYY')}</span>
          </Tooltip>
        </div>
      ),
      sortable: true
    },
    {
      id: 'size',
      name: t('size'),
      selector: (row) => humanFileSize(row?.size),
      sortable: true,
      cell: (row) => (
        <div>
          <Tooltip title={humanFileSize(row?.size)}>
            <span className={`line-clamp-1 ${!row.is_eligible || row.status != "SUCCEEDED" || row.sub_status != "SUCCEEDED" ? "text-[#828282]" : "text-black"}`}>{humanFileSize(row?.size)}</span>
          </Tooltip>
        </div>
      ),
    },

    {
      id: 'status',
      name: t('document scan'),
      selector: (row) => row?.status,
      cell: (row) => (
        <div className='flex items-center'>
          <div
            className={`w-2 h-2 mr-2 rounded-full ${row?.status === 'SUCCEEDED' ? 'bg-[#00B47E]' : row?.status === 'IN_PROGRESS' ? 'bg-[#FD7A3A]' : 'bg-[#E53E3E]'}`}>
          </div>

          <span>  {row?.status === 'IN_PROGRESS' ? 'In elaborazione' : row.status === 'SUCCEEDED' ? 'Analizzabile' : 'Non analizzabile'}</span>

        </div>


      ),
      sortable: true
    },
    /* {
      id: 'is_eligible',
      name: t('Eligiblity'),
      selector: (row) => row?.is_eligible,
      cell: (row) => (
        <div className='flex items-center'>
          <div
            className={`w-2 h-2 mr-2 rounded-full ${row?.is_eligible ? 'bg-[#00B47E]' : 'bg-[#E53E3E]'}`}>
          </div>
          {row?.is_eligible ? t('Eligible'): t('Ineligible')}
        </div>


      ),
      sortable: true
    } */
  ]



  return (
    <>
      <Table
        key={JSON.stringify(documentListByCase)}
        pending={isFetchingDocumentByCase}
        highlightOnHover
        backgroundColorHeadCells="#F7F7F8"
        onSort={handleSort}
        noDataComponent={
          <div className="flex w-full justify-start items-center text-[#828282] p-4">
            {totalFilterActive > 0 || tableState.search
              ? t('No documents available')
              :
              (<div className="flex flex-col w-full justify-center items-center">
                <IcDocumentEmpty className={'drop-shadow-lg'} />
                <span className="text-2xl font-normal -mt-4 text-black">{t('Upload new documents')}</span>
                <span className="text-sm font-normal mt-2 text-black">{t('Upload new documents disclaimer')}</span>
              </div>)
            }
          </div>
        }
        defaultSortFieldId={'created_at'}
        defaultSortAsc={false}
        sortServer
        selectableRows={true}
        selectableRowsNoSelectAll={true}
        selectableRowsComponent={Radio}
        selectableRowsComponentProps={{ type: 'radio' }}
        selectableRowSelected={row => selectedDocuments && selectedDocuments.id === row.id}
        onSelectedRowsChange={handleChangeSelectedDocuments}
        selectableRowDisabled={rowDisabled}
        selectableRowsHighlight={true}
        fixedHeader
        className={'flex-auto h-[300px] overflow-y-auto pr-6'}
        hidePagination
        columns={columnsSpecificCases}
        data={documentListByCase}
      />

      <div className="flex justify-between items-center gap-4 mt-4">
        <CustomButton
          buttonColor="secondary"

          onClick={closeModal}>
          {t('Cancel')}
        </CustomButton>

        <div className='flex justify-end items-center gap-2'>
          <CustomButton
            buttonColor={selectedDocuments ? 'bordered' : 'secondary'}

            disabled={selectedDocuments ? false : true}
            onClick={() => { handleProcessDocument(DOCUMENT_TYPE.SUMMARY, selectedDocuments.id) }}>
            {t('processing.summary')}
          </CustomButton>

          <CustomButton
            buttonColor={selectedDocuments ? 'bordered' : 'secondary'}
            disabled={selectedDocuments ? false : true}
            onClick={() => { handleProcessDocument(DOCUMENT_TYPE.TIMELINE, selectedDocuments.id) }}>
            {t('processing.history')}
          </CustomButton>
          <CustomButton
            buttonColor={selectedDocuments ? 'bordered' : 'secondary'}
            disabled={selectedDocuments ? false : true}

            onClick={() => { handleProcessDocument(DOCUMENT_TYPE.SPECIFICATIONS, selectedDocuments.id) }}>
            {t('processing.specifications')}
          </CustomButton>

          <CustomButton
            buttonColor={selectedDocuments ? 'bordered' : 'secondary'}
            disabled={selectedDocuments ? false : true}

            onClick={() => { handleProcessDocument(DOCUMENT_TYPE.REGULATION, selectedDocuments.id) }}>
            {t('processing.regulation')}
          </CustomButton>

          <CustomButton
            buttonColor={selectedDocuments ? 'primary' : 'secondary'}
            disabled={selectedDocuments ? false : true}
            onClick={() => { handleProcessAllDocument(selectedDocuments.id) }}>
            {t('processing.all')}
          </CustomButton>
        </div>

      </div>
    </>
  )
}

export default SelectProcessedDocsNewChatTable
