import React from 'react'
import NoteCard from 'components/atoms/user/cases/NoteCard'
import NotePanel from 'components/atoms/user/cases/NotePanel'
import AddIcon from '@mui/icons-material/Add'
import { Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { NOTE_TYPE } from 'constant'
const ListNotes = ({
  value,
  dataInsight,
  dataTodo,
  height,
  setNoteDataInsight,
  setNoteDataTodo,
  noteDataInsight,
  noteDataTodo,
  caseId,
  onAdd = (title, type) => { },
}) => {
  const { t } = useTranslation()
  const onClickOutsideListener = () => {
    document.removeEventListener('click', onClickOutsideListener)
    // console.log('onClickOutsideListener', noteDataInsight)
    setNoteDataInsight((prev) => {
      return prev.map((item) => {
        // console.log('onClickOutsideListener' + item.id, item)
        return {
          ...item,
          needToBeSaved: !!item.isTouched
        }
      })
    })
  }

  const onMouseLeaveHandler = () => {
    document.addEventListener('click', onClickOutsideListener)
    // console.log('onMouseLeaveHandler', noteDataInsight)
    setNoteDataInsight((prev) => {
      return prev.map((item) => {
        // console.log('onMouseLeaveHandler' + item.id, item)
        return {
          ...item,
          edit: false
        }
      })
    })
    setNoteDataTodo((prev) => {
      return prev.map((item) => {
        return {
          ...item,
          edit: false,
          needToBeSaved: !!item.isTouched
        }
      })
    })
  }



  return (
    <div
      className="w-full overflow-y-scroll py-2 "
      style={{ height: height }}
      onMouseLeave={() => onMouseLeaveHandler()}>
      <NotePanel value={value} index={0}>

        {dataInsight.length === 0 && (
          <div style={{ height: '105px' }} className='w-full bg-[#EDEDEF] p-3 rounded-lg cursor-pointer' onClick={() => {
            onAdd(t('note.insight'), NOTE_TYPE.FREE)

          }}>
            <div  className='flex justify-between items-center mb'>

              <span className=" text-black font-semibold text-xs">{t('New note')}</span>

              <div  >
                <AddIcon className='text-black font-semibold text-xs' />
              </div>



            </div>

            <span className="leading-3 text-[#595961] font-normal text-xs">{t('New note disclaimer')}</span>
          </div>
        )
        }


        {
          dataInsight.map((note, idx) => (
            <NoteCard
              key={`note-${idx}`}
              className="last:mb-10"
              setNoteData={setNoteDataInsight}
              noteData={noteDataInsight}
              tabNote={value}
              caseId={caseId}
              index={idx}
              {...note}
            />
          ))
        }
      </NotePanel >
      <NotePanel value={value} index={1}>
        {dataTodo.length === 0 && (
          <div style={{ height: '105px' }} className='w-full bg-[#EDEDEF] p-3 rounded-lg cursor-pointer' onClick={() => {

            onAdd(t('note.todo'), NOTE_TYPE.TODO)
          }}>
            <div className='flex justify-between items-center mb'>

              <span className=" text-black font-semibold text-xs">{t('New todo')}</span>

              <div  >
                <AddIcon className='text-black font-semibold text-xs' />
              </div>

            </div>

            <span className="leading-3 text-[#595961] font-normal text-xs">{t('New note disclaimer')}</span>
          </div>
        )}

        {dataTodo.map((note, idx) => (
          <NoteCard
            key={`note-${idx}`}
            className="last:mb-10"
            setNoteData={setNoteDataTodo}
            noteData={noteDataTodo}
            tabNote={value}
            caseId={caseId}
            index={idx}
            {...note}
          />
        ))}
      </NotePanel>
    </div >
  )
}

export default ListNotes
