import React, { useRef, useState } from 'react'
import { useLocation, useOutletContext } from 'react-router-dom'
import { useQuery } from 'react-query'
import NotesContainer from 'components/organisms/users/cases/NotesContainer'
import DocumentPdf from 'components/organisms/users/document/DocumentPdf'
import { getDetailDocument, updateDocumentNameDetailCase } from 'api'
import { useAuth } from 'hooks'
import RenameModal from 'components/organisms/users/RenameModal'
import { errorObj } from 'utils/handleError'
import { showErrorMessage, showSuccessMessage } from 'utils/showMessage'
import { useTranslation } from 'react-i18next'
import DocumentViewer from 'components/organisms/users/document/DocumentViewer'

const DocumentPage = () => {
  const { t } = useTranslation()
  const { state } = useLocation()
  const { getAccessToken } = useAuth()
  const [layoutInfo] = useOutletContext()
  const noteRef = useRef()

  const [tabNote, setTabNote] = useState(0)
  const [docModal, setDocModal] = useState({
    type: '',
    show: false,
    data: null
  })

  const { data: dataDocument, refetch } = useQuery({
    queryKey: ['getDocument', state?.document?.id],
    refetchOnWindowFocus: false,
    queryFn: () => getDetailDocument(getAccessToken(), state?.document?.id),
    enabled: state?.document?.id !== undefined
  })

  const onRename = async (data) => {
    try {
      await updateDocumentNameDetailCase(getAccessToken(), state?.document?.id, data.renameValue)
      refetch()
      showSuccessMessage(t('document.renamed'))
    } catch (error) {
      const obj = errorObj(error)
      showErrorMessage(obj.resData.errors[0].message)
    }
  }


  return (
    <div className="overflow-hidden flex h-full bg-[#EDEDEF]">
      {dataDocument?.resData?.data.ext === 'pdf' ? (
        
        <DocumentPdf
          document={dataDocument?.resData?.data}
          caseName={state?.caseName}
          caseId={state?.caseId}
          bookmarks={dataDocument?.resData?.data?.bookmarks ?? []}
          filename={dataDocument?.resData?.data?.filename ?? state?.document?.filename}
          fileUrl={dataDocument?.resData?.data?.filepath ?? state?.document?.filepath}
          onAddNote={(options) => noteRef?.current?.addNewNote(options)}
          // fileUrl={require('assets/files/file-sample_100kB.doc')}
          isEligible={state?.document?.is_eligible}
          onRename={() => {
            const file = dataDocument?.resData?.data?.filename ?? state?.document?.filename
            const filename = file.split('.')
            let name = filename.join('.')
            if (filename.length > 1) {
              name = filename.slice(0, filename.length - 1).join('.')
            }
            setDocModal({
              show: true,
              type: 'rename',
              data: {
                ...(dataDocument?.resData?.data ?? state?.document),
                name
              }
            })
          }}
        />
      ) :
        (<DocumentViewer
          document={dataDocument?.resData?.data}
          caseName={state?.caseName}
          caseId={state?.caseId}
          bookmarks={dataDocument?.resData?.data?.bookmarks ?? []}
          filename={dataDocument?.resData?.data?.filename ?? state?.document?.filename}
          fileUrl={dataDocument?.resData?.data?.filepath ?? state?.document?.filepath}
          onAddNote={(options) => noteRef?.current?.addNewNote(options)}
          // fileUrl={require('assets/files/file-sample_100kB.doc')}
          isEligible={state?.document?.is_eligible}
          onRename={() => {
            const file = dataDocument?.resData?.data?.filename ?? state?.document?.filename
            const filename = file.split('.')
            let name = filename.join('.')
            if (filename.length > 1) {
              name = filename.slice(0, filename.length - 1).join('.')
            }
            setDocModal({
              show: true,
              type: 'rename',
              data: {
                ...(dataDocument?.resData?.data ?? state?.document),
                name
              }
            })
          }} />)}

      <div className=" w-[400px] h-full pt-4 pl-6 pr-3 bg-[#F7F7F8]">
        <NotesContainer
          ref={noteRef}
          setTabNote={setTabNote}
          height={layoutInfo - 20}
          tabNote={tabNote}
          caseId={state?.caseId}
        />
      </div>
      {docModal?.type === 'rename' && (
        <RenameModal
          title={t('document.rename')}
          onClose={() =>
            setDocModal((prev) => {
              return {
                ...prev,
                show: false
              }
            })
          }
          show={docModal.show}
          data={docModal.data}
          onSubmit={(data) => onRename(data)}
        />
      )}
    </div>
  )
}

export default DocumentPage
