import { Input } from 'antd'
import React, { useState } from 'react'
import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close';
import { isAdminPage } from 'utils/isAdminPage'
import { useTranslation } from 'react-i18next'
const SearchInput = ({ onSearch, onClear = () => {}, variant = 'borderless', ...inputProps }) => {
  const { t, i18n } = useTranslation();
  const [isFocused, setIsFocused] = useState(false);
  const [searching, setIsSearching] = useState(false);
  const [value, setValue] = useState('')
  return (
    <Input
      id='searchInput'
      {...inputProps}
      onFocus={() => {
        setIsFocused(true);

      }}
      onBlur={() => {
        setIsFocused(false);
        if (value.length === 0) {
          onClear()
        }
      }}
      variant={variant}
      placeholder={t("Search")}
      className={`w-52 ${isAdminPage() ? `bg-[#FFFFFF0F] rounded-md
        ${isFocused ?
          "focused border-[1px] border-solid border-[var(--Light-Primary-Blu-Legal-AI,#0049EE)] rounded-[6px]"
          : "border-[1px] border-solid border-[#FFFFFF0F]"}`
        :
        `rounded-md ${isFocused && "focused border-[1px] border-solid border-[var(--Light-Primary-Blu-Legal-AI,#0049EE)] rounded-[6px]"}`}`}


      onChange={(e) => {
        
        e.target.value.length > 0 ? setIsSearching(true) : setIsSearching(false)
        onSearch(e)
        setValue(e.target.value)
      }}
      onClear={(e) => {
        if (onClear){
          onClear()
        } else {
          onSearch({ target: { value: '' }})
        }
      }}
      allowClear={{ clearIcon: <CloseIcon fontSize='medium' style={{ color: "#999",paddingTop:4 }} /> }}
      suffix={!searching && <SearchIcon fontSize='medium' style={{ color: "#999" }} />}
    />
  )
}

export default SearchInput
