import { apiGet } from 'api/api-get'
import { apiPost } from 'api/api-post'
import { apiPut } from 'api/api-put'
import { urls } from 'api/api-url'
import moment from 'moment'

export const getCompanyList = (token, filterState, tableState) => {
  let params = {
    page: tableState?.page ?? 1,
    limit: tableState?.limit ?? undefined,
    search: tableState?.search ?? '',
    sort: tableState?.sort,
    filter_user_count_type: filterState?.typeCountUsers ?? undefined,
    first_user_count: filterState?.startUserNumbers ?? undefined,
    second_user_count: filterState?.endUserNumbers ?? undefined,
    filter_date_type: filterState?.typeDate ?? undefined,
    first_date: filterState?.startDate
      ? moment(filterState?.startDate).format('DD/MM/YYYY')
      : undefined,
    second_date: filterState?.endDate
      ? moment(filterState?.endDate).format('DD/MM/YYYY')
      : undefined
  }
  params = Object.fromEntries(Object.entries(params).filter(([, v]) => v != undefined))
  // return apiGet({ url: urls.company.companies, token: token })
  return apiGet({ url: urls.company.companies, token, data: params })
}

export const addCompany = (token, data) => {
  return apiPost({ url: urls.company.companies, token: token, data: data })
}

export const validateGptKey = (token, data) => {
  return apiPost({ url: urls.company.validateGptKey, token: token, data: data })
}

export const validateCompanyEmail = (token, data) => {
  return apiPost({ url: urls.company.validateEmail, token: token, data: data })
}

export const validateCompanyPhone = (token, data) => {
  return apiPost({ url: urls.company.validatePhone, token: token, data: data })
}

export const getCompanySearchByName = (token, name) => {
  return apiGet({ url: `${urls.company.searchByName}/${name}`, token: token })
}

export const getCountryList = (token) => {
  return apiGet({ url: urls.company.countryList, token: token })
}

export const getCompanyById = (token, id) => {
  return apiGet({ url: `${urls.company.companies}/${id}`, token: token })
}

export const updateCompany = (token, id, data) => {
  return apiPut({ url: `${urls.company.companies}/${id}`, token: token, data: data })
}
