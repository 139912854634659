import ChangePasswordProfileForm from 'components/organisms/admin/ProfileForm/ChangePasswordProfileForm'
import i18next from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'

const SecurityPlatformContent = () => {
  const { t } = useTranslation()
  return (
    <div className="flex flex-col flex-grow gap-12">
      <h1 className="text-black text-2xl font-normal">{t('profile.Security')}</h1>

      <div className="grid grid-cols-[40%_60%] h-full w-full gap-8">
        <div>
          <ChangePasswordProfileForm />
        </div>
      </div>
    </div>
  )
}

export default SecurityPlatformContent
