import {
  ArrowForward,
  ChatBubbleOutline,
  InsertDriveFileOutlined,
  MoreVertOutlined,
  StickyNote2Outlined
} from '@mui/icons-material'
import { TablePagination } from '@mui/material'
import { Popover } from 'antd'
import { IcFolderList } from 'assets/icons'
import Table from 'components/atoms/Table'
import { ROLE_ADMIN, ROLE_USER } from 'constant'
import { useAuth } from 'hooks'
import moment from 'moment'
import React from 'react'
import IcSort from 'assets/icons/IcSort'
import { useTranslation } from 'react-i18next'
const ListCases = ({
  data = [],
  total = 0,
  onEdit = () => {},
  onExport = () => {},
  onDelete = () => {},
  onViewDetail = () => {}
}) => {
  const { platformData } = useAuth()
  const { t, i18n } = useTranslation()
  const optionsPopover = [
    {
      title: t('case.edit'),
      callback: onEdit,
      role: [ROLE_ADMIN]
    },
    {
      title: t('case.delete'),
      callback: onDelete,
      role: [ROLE_ADMIN]
    },
    {
      title: t('case.exportNotes'),
      callback: onExport,
      role: [ROLE_ADMIN, ROLE_USER]
    }
  ]
  const columns = [
    {
      name: t('case.name'),
      cell: (row) => (
        <div className="flex gap-2 items-center">
          <IcFolderList statusColor={`#${row?.case_status?.hex_code}`} />
          <div className="flex gap-2 items-center font-medium tracking-tight">{row?.name}</div>
        </div>
      ),
      selector: (row) => row?.name,
      sortable: true,
      minWidth: '240px'
    },
    {
      name: t('case.status'),
      selector: (row) => row?.case_status?.name,
      cell: (row) => <div className="flex gap-2 items-center">{row?.case_status?.name}</div>,
      sortable: true,
      width: '220px'
    },
    {
      name: t('case.lastChange'),
      selector: (row) => moment(row?.updated_at).format('DD/MM/YYYY'),
      sortable: true,
      width: '160px'
    },
    {
      name: t('case.openedChats'),
      cell: (row) => (
        <div className="flex gap-2 items-center">
          {row?.chat_total} <ChatBubbleOutline className="!w-4 !h-[18px] !text-[#828282]" />
        </div>
      ),
      selector: (row) => row?.chat_total,
      sortable: true,
      width: '120px'
    },
    {
      name: t('case.documents'),
      cell: (row) => (
        <div className="flex gap-2 items-center">
          {row?.document_total}{' '}
          <InsertDriveFileOutlined className="!w-4 !h-[18px] !text-[#828282]" />
        </div>
      ),
      selector: (row) => row?.document_total,
      sortable: true,
      width: '120px'
    },
    {
      name: t('case.notes'),
      cell: (row) => (
        <div className="flex gap-2 items-center">
          {row?.note_total} <StickyNote2Outlined className="!w-4 !h-[18px] !text-[#828282]" />{' '}
        </div>
      ),
      selector: (row) => row?.note_total,
      sortable: true,
      width: '120px'
    },
    {
      name: t('case.regulation'),
      selector: (row) => row?.regulation?.title,
      sortable: true,
      width: '120px'
    },
    {
      name: '',
      cell: (row) => (
        <div className="flex justify-center items-center" onClick={() => onViewDetail(row)}>
          <ArrowForward className="!text-[#595961] cursor-pointer" fontSize="small" />
        </div>
      ),
      width: '44px',
      sortable: false
    },
    {
      name: '',
      cell: (row) => (
        <Popover
          content={
            <div className="w-[158px] h-full flex flex-col text-black">
              {optionsPopover.map(
                ({ callback, ...option }, index) =>
                  option.role.includes(platformData?.role?.toUpperCase()) && (
                    <div
                      key={index}
                      onClick={() => {
                        callback(row)
                      }}
                      className="px-2 py-1 cursor-pointer hover:bg-[#0000000A] text-black rounded text-sm font-normal">
                      {option.title}
                    </div>
                  )
              )}
            </div>
          }
          trigger="click"
          placement="leftTop"
          arrow={false}>
          <MoreVertOutlined className={`cursor-pointer hover:text-[#0049EE]`} fontSize="small" />
        </Popover>
      ),
      width: '44px',
      sortable: false
    }
  ]

  return (
    <div className="h-full w-full flex flex-col justify-between">
      <div className="h-[calc(100vh-170px)] w-full overflow-y-auto pr-6">
        <Table
          hidePagination
          columns={columns}
          data={data}
          onRowClicked={(row) => onViewDetail(row)}
          pointerOnHover={true}
          highlightOnHover={true}
          sortIcon={<IcSort />}
        />
      </div>
      <div className="pr-6">
        <TablePagination
          labelRowsPerPage={null}
          slotProps={{
            select: {
              style: {
                display: 'none'
              }
            }
          }}
          component="div"
          count={total}
          page={0}
          onPageChange={() => {}}
          rowsPerPage={40}
        />
      </div>
    </div>
  )
}

export default ListCases
