import React from 'react'
import { Modal, ConfigProvider } from 'antd'

const ModalAdmin = ({
  title,
  show,
  onClose,
  width = 600,
  children,
  closable = false,
  modalClassName,
  ...modalProps
}) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: {
            headerBg: '#1A1A1A',
            titleColor: '#FFF',
            titleFontSize: '24px',
            fontWeightStrong: '700',
            fontFamily: '"Inter", sans-serif',
            contentBg: '#1A1A1A',
            colorIcon: '#FFF',
            colorIconHover: '#FFF',
            zIndexPopupBase: 1100
          }
        }
      }}>
      <Modal
        className={modalClassName}
        {...modalProps}
        title={title}
        style={{ top: 20 }}
        styles={{ mask: { backgroundColor: 'rgba(0, 0, 0, 0.8)' }, ...modalProps.styles }}
        width={width}
        open={show}
        centered
        footer={null}
        onCancel={onClose}
        maskClosable={closable}>
        {children}
      </Modal>
    </ConfigProvider>
  )
}

export default ModalAdmin
