import React, { useState, useRef, useEffect } from 'react'
import { Typography } from 'antd'
import ListRegulationQuery from 'components/molecules/user/regulation/ListRegulationQuery'
import { useTranslation } from 'react-i18next'
const RegulationQueryContainer = ({
  height,
  data,
  setData,
  setActiveData,
  onDelete = () => { },
  onExport = () => { }
}) => {
  const noteHeadRef = useRef()
  const [listNotesHeight, setListNotesHeight] = useState(height ?? 0)
  const { t } = useTranslation();

  useEffect(() => {
    if (noteHeadRef?.current) {
      setListNotesHeight(height - noteHeadRef?.current?.clientHeight)
    }
  }, [noteHeadRef?.current !== null, height])
  return (
    <div className="col-span-3"
      style={{
        borderRadius: "16px 0px 0px 16px",
        background: "#EDEDEF", 
        marginRight: "-20px",
        paddingRight: "20px",
        paddingLeft: "16px"
       
      }}

    >
      <div className="flex items-center mt-3" ref={noteHeadRef}>
        <Typography.Text className="text-[24px] flex items-center">
          {t('case.regulations')}
          {/* <Add className="ml-3" /> */}
        </Typography.Text>
      </div>

      <ListRegulationQuery
        height={height}
        data={data}
        setData={setData}
        setActiveData={setActiveData}
        onDelete={onDelete}
        onExport={onExport}
      />
    </div >
  )
}

export default RegulationQueryContainer
