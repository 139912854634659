import React, { useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { Layout, Menu, ConfigProvider, Popover } from 'antd'
import IcFolder from 'assets/icons/IcFolder'
import { IcRegulation } from 'assets/icons'
import { NullAvatar } from 'assets/images'
import useAuth from 'hooks/useAuth'
import logoIcon from 'assets/images/logoIcon.svg'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import { IcChevronDown } from 'assets/icons'
const { Sider } = Layout

const activeLinkStyle =
  'flex transition-all ease-in-out no-underline mx-auto rounded-xl w-14 h-14 justify-center items-center px-2 py-3 border border-solid border-[#E3E3E8] bg-white hover:bg-[#EBF1FF] hover:border-[#0049EE]'
const nonActiveLinkStyle =
  'flex transition-all ease-in-out no-underline mx-auto rounded-xl w-14 h-14 justify-center items-center px-2 py-3 border border-solid border-[#0000000A] bg-[#EDEDEF] hover:bg-[#EBF1FF] hover:border-[#0049EE]'

const SidebarUser = () => {
  const { t, i18n } = useTranslation()
  const { platformData, refetchProfile } = useAuth()
  const location = useLocation()
  const [selectedKeyPathLang, setSelectedKeyPathLang] = useState(['it'])
  const [hoveredKey, setHoveredKey] = useState(null)

  useEffect(() => {
    refetchProfile()
  }, [])

  const menuLanguage = [
    {
      key: 'it',
      label: <div>{t('languages.it')}</div>,
      icon: (
        <img
          className="w-6 h-6 flex items-center justify-center rounded-full font-bold mr-2 hover:cursor-pointer"
          src={'https://flagpedia.net/data/flags/w1160/it.webp'}
        />
      )
    },
    {
      key: 'en',
      label: <div>{t('languages.en')}</div>,
      icon: (
        <img
          className="w-6 h-6 flex items-center justify-center rounded-full font-bold mr-2 hover:cursor-pointer"
          src={'https://flagpedia.net/data/flags/w1160/gb.webp'}
        />
      )
    }
  ]
  return (
    <Sider width={100} className="overflow-x-hidden">
      {/* Logo Wrapper */}
      <div className="flex justify-center items-center py-5">
        <div className="w-14 h-14">
          <img src={logoIcon} alt="logo" className="object-contain w-full h-full" />
        </div>
      </div>
      {/* Sidebar Navigation */}
      <div className="flex flex-col gap-8">
        {/* Workspace */}
        <NavLink
          to="/cases"
          onMouseEnter={() => setHoveredKey('cases')}
          onMouseLeave={() => setHoveredKey(null)}
          className={({ isActive }) =>
            isActive || location.pathname === '/' ? activeLinkStyle : nonActiveLinkStyle
          }>
          {({ isActive }) => (
            <IcFolder isActive={isActive || location.pathname === '/' || hoveredKey === 'cases'} />
          )}
        </NavLink>
        {/* Regulations */}
        <NavLink
          to="/regulations"
          onMouseEnter={() => setHoveredKey('regulations')}
          onMouseLeave={() => setHoveredKey(null)}
          className={({ isActive }) => (isActive ? activeLinkStyle : nonActiveLinkStyle)}>
          {({ isActive }) => (
            <IcRegulation
              isActive={
                isActive || location.pathname === '/regulations' || hoveredKey === 'regulations'
              }
            />
          )}
        </NavLink>
      </div>

      <div className="fixed bottom-0 my-4 w-[100px] flex justify-center items-center">
        <div>
          <Popover
            placement="top"
            content={
              <ConfigProvider
                theme={{
                  components: {
                    Menu: {
                      itemBg: 'white',
                      itemSelectedBg: '#ddd',
                      itemHoverBg: '#ddd',
                      itemActiveBg: '#ddd',
                      itemMarginInline: 0,
                      itemBorderRadius: 4
                    }
                  }
                }}>
                <div className="w-[230px] bg-white">
                  <Menu
                    selectable={true}
                    defaultOpenKeys={selectedKeyPathLang}
                    defaultSelectedKeys={selectedKeyPathLang}
                    selectedKeys={selectedKeyPathLang}
                    mode="inline"
                    style={{
                      borderRight: 0
                    }}
                    items={menuLanguage}
                    onClick={(e) => {
                      setSelectedKeyPathLang(e.keyPath)
                      i18n.changeLanguage(e.keyPath)
                    }}
                  />
                </div>
              </ConfigProvider>
            }
            arrow={false}
            trigger="click">
            <div className="flex items-center justify-center  hover:bg-[#ddd] p-2 py-3 my-4 rounded-md hover:cursor-pointer">
              {selectedKeyPathLang[0] === 'en' ? (
                <img
                  className=" w-8 h-8 flex items-center justify-center rounded-full font-bold mr-2 hover:cursor-pointer"
                  src={'https://flagpedia.net/data/flags/w1160/gb.webp'}
                />
              ) : (
                <img
                  className=" w-8 h-8 flex items-center justify-center rounded-full font-bold mr-2 hover:cursor-pointer"
                  src={'https://flagpedia.net/data/flags/w1160/it.webp'}
                />
              )}

              {/*  <IcChevronDown
              className="text-[#5C5C5C] hover:text-white hover:cursor-pointer"
              size={14}
            /> */}
            </div>
          </Popover>
          <NavLink
            to={'/profile'}
            className="bg-gray-200 w-14 h-14 overflow-hidden flex items-center justify-center rounded-full text-white font-bold mr-2 hover:cursor-pointer">
            <img
              src={platformData?.profile?.avatar_url || NullAvatar}
              className={`${
                platformData?.profile?.avatar_url
                  ? 'object-cover w-full h-full'
                  : 'object-contain w-7 h-7'
              } object-center`}
              alt="avatar"
              aria-label="avatar profile"
            />
          </NavLink>
        </div>
      </div>
    </Sider>
  )
}

export default SidebarUser
