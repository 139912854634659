import CustomButton from 'components/atoms/CustomButton'
import ModalUser from 'components/molecules/user/ModalUser'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import { Breadcrumb } from 'antd'
import SearchInput from 'components/atoms/SearchInput'
import { debounce } from 'lodash'
import { FilterListOutlined } from '@mui/icons-material'
import NewChatFilterModal from './NewChatFilterModal'
import { useMutation, useQuery } from 'react-query'
import { showErrorMessage } from 'utils/showMessage'
import { confirmDocument, getDocumentListNewChat, postNewChats } from 'api'
import { useAuth } from 'hooks'
import { useNavigate } from 'react-router-dom'
import OtherCasesFilterModal from './OtherCasesFilterModal'
import SearchOtherCasesTable from './newChatModalComponent/SearchOtherCasesTable'
import useSelectDocsNewChat from 'hooks/new-chat/useSelectDocsNewChat'
import useSearchOtherCases from 'hooks/new-chat/useSearchOtherCases'
import SelectDocsNewChatTable from './newChatModalComponent/SelectDocsNewChatTable'
import UploadDocsNewChatTable from './newChatModalComponent/UploadDocsNewChatTable'
import useUploadDocsNewChat from 'hooks/new-chat/useUploadDocsNewChat'
import UploadDocsNewChatFilterModal from './UploadDocsNewChatFilterModal'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
const INITIAL_DOCS_TABLE_STATE = {
  sort: '-created_at',
  sort_order: 'desc',
  search: ''
}
const INITIAL_OTHER_CASES_TABLE_STATE = {
  sort: '-updated_at',
  sort_order: 'desc',
  search: ''
}
const INITIAL_UPLOAD_DOCS_TABLE_STATE = {
  search: ''
}
const CONST_CASES = 'CONST_CASES'
const NewChatModal = ({ show, data, closeModal }) => {
  const { getAccessToken } = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation();
  const intervalRef = useRef(null);
  const [currentContent, setCurrentContent] = useState({ ...data })
  const [tableState, setTableState] = useState(INITIAL_DOCS_TABLE_STATE)

  const uploadRef = useRef()
  const {
    filteredItems,
    progresses,
    documentModal,
    columnsUploadDocs,
    uploadDocFilterState,
    updates,
    isUploadingDocuments,
    handleChangeDocumentModal,
    openUploadFilterModal,
    closeUploadFilterModal,
    handleResetUploadFilter,
    handleSubmitUploadFilter,
    handleUploadDocs,
  } = useUploadDocsNewChat(data, tableState)

  const {
    documentListByCase,
    selectedDocuments,
    isFetchingDocumentByCase,
    filterModal,
    filterState,
    openFilterModal,
    closeFilterModal,
    handleSubmitFilter,
    handleResetFilterState,
    handleChangeSelectedDocuments,
    resetSelectedDocument,
    refetchingDocumentByCase
  } = useSelectDocsNewChat(currentContent, isContentSpecificCase, documentModal.show, tableState, show)

  const {
    otherCasesFilterState,
    otherCasesList,
    isFetchingOtherCases,
    casesStatus,
    regulationsData,
    openFilterOtherCases,
    closeFilterOtherCases,
    handleSubmitFilterOtherCases,
    handleOtherCasesResetFilterState
  } = useSearchOtherCases(tableState, isContentSearchOtherCases)

  function isContentSpecificCase() {
    return currentContent !== CONST_CASES
  }

  function isContentSearchOtherCases() {
    return currentContent === CONST_CASES
  }

  // Breadcrumbs
  const [breadcrumbItems, setBreadcrumbItems] = useState([
    {
      title: t('breadcrumb.cases')
    },
    {
      title: currentContent?.caseName
    }
  ])

  function handleBreadcrumbNavigation(value) {
    let newbreadItems = []
    if (value === CONST_CASES) {
      newbreadItems.push({
        title: t('breadcrumb.cases')
      })
    } else {
      newbreadItems = [...breadcrumbItems]
      newbreadItems.push({
        // Title Specific Case
        title: value?.caseName
      })
    }
    setTableState(
      isContentSearchOtherCases() ? INITIAL_OTHER_CASES_TABLE_STATE : INITIAL_DOCS_TABLE_STATE
    )
    resetSelectedDocument()
    handleResetFilterState()
    setCurrentContent(value)
    setBreadcrumbItems(newbreadItems)
  }

  const handleSort = (column, sortDirection) => {
    setTableState((prevState) => ({
      ...prevState,
      sort: `${sortDirection === 'desc' ? '-' : ''}${column?.id}`,
      sort_order: sortDirection
    }))
  }

  //Search
  const handleSearch = (e) => {
    setTableState((prev) => ({ ...prev, search: e.target.value }))
  }

  useEffect(() => {
    return () => {
      debouncedResults.cancel()
    }
  })

  const debouncedResults = useMemo(() => {
    return debounce(handleSearch, 300)
  }, [])

  // Confirm Chat
  const confirmNewChatMutation = useMutation({
    mutationKey: '',
    mutationFn: (selectedDocumentIds) => {
      return postNewChats(getAccessToken(), data?.caseId, selectedDocumentIds)
    },
    onSuccess: (res) => {
      navigate(`/chat`, {
        state: {
          caseId: data?.caseId,
          caseName: data?.caseName,
          chatData: res.resData.data
        }
      })
    },
    onError: () => {
      showErrorMessage(t('error messages.Error create new chat, please try again.'))
    }
  })

  // Confirm Document
  const confirmDocumentMutation = useMutation({
    mutationKey: '',
    mutationFn: (selectedDocumentIds) => {
      return confirmDocument(getAccessToken(), selectedDocumentIds)
    },
    onSuccess: () => {
      const getAnalyzeableDocs = Object.entries(updates)
        .filter(([, value]) => value?.isEligible)
        .map(([, value]) => value?.docId)
      return confirmNewChatMutation.mutate(getAnalyzeableDocs)
    }
  })

  function handleConfirmNewChat() {
    if (documentModal.show) {
      const getAnalyzeableDocs = Object.entries(updates)
        .filter(([, value]) => value?.isEligible)
        .map(([, value]) => value?.docId)
      let data = { document_ids: getAnalyzeableDocs }
      confirmDocumentMutation.mutate(data)
    } else {
      const selectedDocumentIds = selectedDocuments?.map((doc) => doc.id)
      confirmNewChatMutation.mutate(selectedDocumentIds)
    }
  }

  const totalFilterActive = Object?.entries(
    isContentSearchOtherCases()
      ? otherCasesFilterState.data
      : documentModal.show
        ? uploadDocFilterState.data
        : filterState
  )?.filter(([, { value }]) => value !== null)?.length

  function itemRenderBreadcrumb(currentRoute, _, items) {
    const isLast = currentRoute?.title === items[items.length - 1]?.title
    return isLast ? (
      <span>{currentRoute.title}</span>
    ) : (
      <span
        className={`${selectedDocuments.length > 0 ? 'cursor-default' : 'cursor-pointer'}`}
        onClick={() => {
          return selectedDocuments.length > 0 ? false : handleBreadcrumbNavigation(CONST_CASES)
        }}>
        {currentRoute.title}
      </span>
    )
  }

  const documentListByCaseRef = useRef(documentListByCase);

  useEffect(() => {
    documentListByCaseRef.current = documentListByCase;
  }, [documentListByCase]);


  const fetchDocuments = () => {
    getDocumentListNewChat(getAccessToken(), currentContent.caseId, filterState, tableState).then(data => {
      if(!arraysAreEqual(data?.resData?.data || [], documentListByCaseRef.current || [])){
        refetchingDocumentByCase();
      }
    }, err => {
      showErrorMessage(t('error messages.Failed fetch document list, Please refresh the page'));
    })
  };
  
  function arraysAreEqual(array1, array2) {
    const statusArray1 = array1.map(item => ({ id: item.id, status: item.status, sub_status: item.sub_status,is_eligible:item.is_eligible }));
    const statusArray2 = array2.map(item => ({ id: item.id, status: item.statuss, sub_status: item.sub_status,is_eligible:item.is_eligible }));
    return _.isEqual(statusArray1, statusArray2);
  }

  useEffect(() => {

    if (show) {
      fetchDocuments();
      intervalRef.current = setInterval(fetchDocuments, 30000);
    }


    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [show, refetchingDocumentByCase]);

  return (
    <ModalUser
      titleComponent={
        <div className="flex justify-between items-center pb-2">
          <span>{documentModal?.show ? t("Upload documents") : t("New Chat")}</span>
          <CustomButton
            buttonColor={selectedDocuments.length > 0 ? 'secondary' : 'primary'}
            disabled={isUploadingDocuments || selectedDocuments.length > 0 ? true : false}
            onClick={() => uploadRef.current?.click()}>
            <div className="flex gap-1">
              {t('Upload')}
              <AddIcon sx={{ width: '20px', height: '20px' }} />
            </div>
          </CustomButton>
        </div>
      }
      show={show}
      width={900}
      isCloseIconVisible={false}
      onClose={closeModal}
      closable={false}>
      {/* Breadcrumb */}
      <div className="flex justify-between items-center">
        <Breadcrumb
          itemRender={itemRenderBreadcrumb}
          items={documentModal.show ? [{ title: currentContent.caseName }] : breadcrumbItems}
        />
        {/* Filter & Search */}
        <div className="flex items-center gap-4">
          {/* Search Input */}
          <SearchInput variant="outlined" onSearch={debouncedResults} />
          {/* Filter Wrapper */}
          <div
            className="flex gap-1 justify-center items-center"
            onClick={
              isContentSearchOtherCases()
                ? openFilterOtherCases
                : documentModal.show
                  ? openUploadFilterModal
                  : openFilterModal
            }>
            <FilterListOutlined
              className={`cursor-pointer hover:text-[#0049EE] ${totalFilterActive > 0 && 'text-[#0049EE]'
                }`}
            />
            {/* Badge Total Count */}
            {totalFilterActive > 0 && (
              <div className="w-5 h-5 bg-[#0049EE] px-[6px] flex justify-center items-center rounded-full text-white">
                {totalFilterActive}
              </div>
            )}
          </div>
        </div>
      </div>
      {documentModal?.show ? (
        <UploadDocsNewChatTable
          documentModal={documentModal}
          updates={updates}
          progresses={progresses}
          //isConfirming={confirmNewChatMutation.isLoading}
          isUploading={isUploadingDocuments}
          uploadListDocument={filteredItems}
          columnsUploadDocs={columnsUploadDocs}
          //handleConfirmNewChat={handleConfirmNewChat}
          handleUploadDocs={handleUploadDocs}
          closeModal={closeModal}
        />
      ) : isContentSpecificCase() ? (
        <SelectDocsNewChatTable
          isFetchingDocumentByCase={isFetchingDocumentByCase}
          handleSort={handleSort}
          totalFilterActive={totalFilterActive}
          tableState={tableState}
          handleChangeSelectedDocuments={handleChangeSelectedDocuments}
          documentListByCase={documentListByCase}
          selectedDocuments={selectedDocuments}
          isConfirming={confirmNewChatMutation.isLoading}
          closeModal={closeModal}
          handleConfirmNewChat={handleConfirmNewChat}
        />
      ) : (
        <SearchOtherCasesTable
          isLoading={isFetchingOtherCases}
          handleSort={handleSort}
          closeModal={closeModal}
          handleBreadcrumbNavigation={handleBreadcrumbNavigation}
          documentListByCase={otherCasesList}
        />
      )}

      {filterModal && (
        <NewChatFilterModal
          onClose={closeFilterModal}
          data={filterState}
          show={filterModal}
          resetFilter={handleResetFilterState}
          onSubmit={handleSubmitFilter}
        />
      )}

      {isContentSearchOtherCases() && otherCasesFilterState.show ? (
        <OtherCasesFilterModal
          closeModal={closeFilterOtherCases}
          data={otherCasesFilterState.data}
          show={otherCasesFilterState.show}
          onSubmit={handleSubmitFilterOtherCases}
          resetFilter={handleOtherCasesResetFilterState}
          regulations={regulationsData?.data}
          casesStatus={casesStatus?.data?.resData?.data}
        />
      ) : null}

      {uploadDocFilterState.show ? (
        <UploadDocsNewChatFilterModal
          data={uploadDocFilterState.data}
          show={uploadDocFilterState.show}
          onSubmit={handleSubmitUploadFilter}
          resetFilter={handleResetUploadFilter}
          closeModal={closeUploadFilterModal}
        />
      ) : null}

      <input
        className={`hidden`}
        type="file"
        id="file"
        accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/jpeg,image/png,.pdf,text/plain"
        ref={uploadRef}
        style={{ display: 'none' }}
        multiple
        // THIS ONCLICK FOR HANDLING ONCHANGE NOT INVOKED WHEN INPUT SAME FILE
        onClick={(event) => {
          event.target.value = ''
        }}
        onChange={(e) => {


          if (e.target.files.length > 0) {
            const validatedFiles = []
            for (let i = 0; i < e.target.files.length; i++) {

              if (e.target.files[i].size > 20971520) {

                showErrorMessage(`File ${e.target.files[i].name} supera i 20MB`)
              } else {
                validatedFiles.push(e.target.files[i])
              }
            }
            const newFiles = [...documentModal.files, ...validatedFiles]


            handleChangeDocumentModal({
              show: true,
              files: newFiles
            })
            // if (!documentModal.show) setDocumentListByCase([])
            setCurrentContent({ caseName: data?.caseName, caseId: data?.caseId })
            handleResetFilterState()
            setTableState(INITIAL_UPLOAD_DOCS_TABLE_STATE)
            resetSelectedDocument()
          }
        }}
      />
    </ModalUser>
  )
}

export default NewChatModal
