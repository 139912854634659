import React from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { passwordValidation } from 'utils/passwordValidation'
import { Typography } from 'antd'
import PasswordFields from 'components/molecules/PasswordFields'
import CustomButton from 'components/atoms/CustomButton'
import { useMutation } from 'react-query'
import { putChangePassword } from 'api'
import useAuth from 'hooks/useAuth'
import { errorObj } from 'utils/handleError'
import { showErrorMessage, showSuccessMessage } from 'utils/showMessage'
import { isAdminPage } from 'utils/isAdminPage'
import { useTranslation } from 'react-i18next'

const ChangePasswordProfileForm = () => {
  const { t } = useTranslation()

  const schema = z
    .object({
      old_password: z.string().min(1, { message: t('Please input your current password') }),
      new_password: z
        .string()
        .min(10, {
          message: t('Password does not meet the safety guidelines')
        })
        .regex(passwordValidation(), t('Password does not meet the safety guidelines')),
      password_confirmation: z.string().min(1, { message: t('Confirm password is required') })
    })
    .refine((data) => data.new_password === data.password_confirmation, {
      message: t('Passwords do not match'),
      path: ['password_confirmation']
    })

  const { getAccessToken } = useAuth()
  const {
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid },
    control
  } = useForm({
    defaultValues: {
      old_password: '',
      new_password: '',
      password_confirmation: ''
    },
    mode: 'onChange',
    resolver: zodResolver(schema)
  })

  const putChangePasswordMutation = useMutation({
    mutationKey: 'putChangePassword',
    mutationFn: (data) => putChangePassword(getAccessToken(), data),
    onSuccess: () => {
      showSuccessMessage(t('success messages.Password changes saved'))
      reset()
    },
    onError: (err) => {
      const errObj = errorObj(err)
      showErrorMessage(t('error messages.Password was not possible to save the changes'))
    }
  })

  const onSubmit = (data) => {
    putChangePasswordMutation.mutate(data)
  }

  return (
    <div className="flex flex-col gap-10 w-100">
      <div className="flex gap-1 flex-col">
        <Typography.Title
          className={`${
            isAdminPage()
              ? '!text-white !text-lg !font-manrope'
              : '!text-black !text-base !font-semibold'
          } `}>
          {t('Edit Password')}
        </Typography.Title>

        <span className={`${isAdminPage() ? 'text-[#999999]' : 'text-[#595961]'} text-sm`}>
          {t('profile.edi psw text')}
        </span>
      </div>

      <div className="flex flex-col gap-10">
        <PasswordFields
          autoComplete="new-password"
          theme="admin"
          control={control}
          label={t('Current Password')}
          name={'old_password'}
          togglePassword={isDirty}
          errors={errors}
        />
        <PasswordFields
          theme="admin"
          autoComplete="off"
          control={control}
          label={t('New Password')}
          name={'new_password'}
          togglePassword={isDirty}
          errors={errors}
          placeHolder={'****'}
        />
        <PasswordFields
          theme="admin"
          autoComplete="off"
          control={control}
          label={t('Repeat Password')}
          name={'password_confirmation'}
          togglePassword={isDirty}
          errors={errors}
        />
        <CustomButton
          className={'!max-w-40'}
          disabled={!isDirty || !isValid}
          onClick={handleSubmit(onSubmit)}
          buttonColor={isDirty && isValid ? 'primary' : 'secondary'}
          fullWidth>
          {t('Save Changes')}
        </CustomButton>
      </div>
    </div>
  )
}

export default ChangePasswordProfileForm
