import { IcSend } from 'assets/icons'
import { CONST_SHORTCUT } from 'constant'
import useTCPP from 'hooks/useTCPP'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import TCModal from '../TCModal'
import { useAuth } from 'hooks'
import TCModalProfile from '../profile/TCModalProfile'
const INITIAL_STATE = {

  message: ''
}
const shortcutSummary = 'summary'
const shortcutSpecifications = 'specifications'
const shortcutTimeline = 'timeline'
const shortcutRegulation = 'regulation'
const shortcutKeyword = 'keyword'
const shortcutChat = [
  {
    titleButton: 'Summary',
    tooltipTitle: "Brief explanation of the document's main contents",
    value: shortcutSummary
  },
  {
    titleButton: 'Specifications',
    tooltipTitle:
      'Extracting the most relevant data of the document (client, crime, judge, etc...)',
    value: shortcutSpecifications
  },
  {
    titleButton: 'Timeline',
    tooltipTitle: 'Extracting all dates in chronological order',
    value: shortcutTimeline
  },
  {
    titleButton: 'Regulation',
    tooltipTitle: 'Extracting all criminal/civil articles in the document',
    value: shortcutRegulation
  },
  {
    titleButton: 'Keyword',
    tooltipTitle: 'Identificating a set of relevant keywords within the document',
    value: shortcutKeyword
  }
]
const RegulationChatInput = ({ onSubmit = () => { }, isLoading }) => {
  const { t } = useTranslation();
  const { handleSubmitTcModal, tcModal, acceptTC, isPPFetching, isTCFetching, openTcModal, closeTcModal } =
    useTCPP()
  const [showShortcut, setShowShortcut] = useState(false)
  function toggleShortcut() {
    setShowShortcut((prev) => !prev)
  }
  const { onLogout } = useAuth()
  const [keyword, setKeyword] = useState(INITIAL_STATE)
  const [isFocused, setIsFocused] = useState(false);
  const isFocus = useRef(false)
 
 
  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === 'Enter' && isFocus.current) {
        event.preventDefault()
        onSubmit(keyword)
        setKeyword(INITIAL_STATE)
      }
    }

    document.addEventListener('keydown', keyDownHandler)

    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [keyword, onSubmit])

  function handleChangeKeyword(value) {
    setKeyword((prev) => ({ ...prev, message: value }))
  }
  function handleShortcut(value) {
    onSubmit({ type: CONST_SHORTCUT, message: value })
  }
  
  return (
    <div className='mb-4'>
      <div

        className={`${isFocused ? 'border-neutral-600' : 'border-neutral-300'} flex gap-1 relative overflow-hidden px-3 flex-col border border-solid border-neutral-300 bg-white rounded-lg mb-2`}>
        <div className="w-full flex gap-2">
          <input
            id='regulationsInput'
            autoFocus
            disabled={isLoading}
            onFocus={() => {
              setIsFocused(true);
              isFocus.current = true;
            }}
            onBlur={() => {
              setIsFocused(false);
              isFocus.current = false;
            }}
            value={keyword.message}
            onChange={(e) => {
              e.preventDefault()
              handleChangeKeyword(e.target.value)
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && keyword.message.length === 0) {
                e.stopPropagation()
                e.preventDefault();
              }
            }}

            className={`${isFocused && "focused"} w-full h-14 rounded-lg border-none text-neutral-700 text-sm disabled:bg-white placeholder:pl-3 focus:outline-none`}
            placeholder={t("Scrivi un messaggio a LegalAi")}
            type="text"
          />

          <div className="flex justify-center items-center">
            <div
              className={`${keyword.message.length > 0 ? "bg-[#0049EE]" : "bg-[#EBF1FF]"}  h-9 w-9 p-2 rounded flex justify-center items-center cursor-pointer hover:bg-[#2e5fd1]`}
              onClick={() => {
                if (!isLoading && keyword.message.length > 0) {
                  onSubmit(keyword)
                  setKeyword(INITIAL_STATE)
                }
              }}>
              <IcSend color={keyword.message.length > 0 ? 'white' : '#0049EE'} className={'h-5'} />
            </div>
          </div>
        </div>

      </div>
      <div className="text-[11px] flex justify-center items-center pt-1 text-[#828282] text-center w-full">
        LegalAI può commettere errori, quindi verifica le sue risposte.{' '}
        <span className="underline cursor-pointer" onClick={openTcModal}>
          La tua privacy policy
        </span>
      </div>
      {tcModal.show && (
        <TCModalProfile
          isFetching={isPPFetching || isTCFetching}
          isLoading={acceptTC.isLoading}
          pp={tcModal.pp}
          tc={tcModal.tc}
          show={tcModal.show}
          onClose={closeTcModal}
        />
      )}</div>

  )
}

export default RegulationChatInput
