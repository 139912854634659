import { Button, CircularProgress } from '@mui/material'
import React from 'react'
import { isAdminPage } from 'utils/isAdminPage'

const buttonTheme = isAdminPage()
  ? {
    primary: {
      backgroundColor: '#0049EE',
      border:"1px solid #0049EE",
      '&.Mui-disabled': {
        background: '#001F66',
        color: '#FFFFFF3D'
      }
    },
    secondary: { backgroundColor: '#FFFFFF0F' },
    danger: {
      border: '1px solid #E53E3E',
      color: 'white',
      backgroundColor: 'black',
      ':hover': {
        backgroundColor: 'red'
      }
    },
    bordered: { backgroundColor: 'white', border:"1px solid #0049EE",color:"#0049EE",
      ':hover': {
        backgroundColor: '#EBF1FF',
        
      }
     }
  }
  : {
    primary: {
      backgroundColor: '#0049EE',
      border:"1px solid #0049EE",
      '&.Mui-disabled': {
        background: '#001F66',
        color: '#FFFFFF3D'
      }
    },
    secondary: {
      backgroundColor: '#FFFFFF0F',
      color: '#828282',
      borderWidth: '1px',
      borderRadius: '4px',
      borderColor: '#0000001F',
      ':hover': {
        backgroundColor: '#CFCFCF',
        color: 'white'
      }
    },
    bordered: { 
      backgroundColor: 'white', 
      border:"1px solid #0049EE",color:"#0049EE",
     ':hover': {
        backgroundColor: '#EBF1FF',
        
      }
    }
  }

const CustomButton = ({
  children,
  onClick,
  buttonType = 'button',
  buttonColor = 'primary',
  isLoading = false,
  buttonStyle = {},
  ...btnProps
}) => {
  return (
    <Button
      type={buttonType}
      onClick={onClick}
      variant="contained"
      sx={{
        ...buttonStyle,
        py: '6px',
        fontFamily: "'Inter',sans-serif",
        textTransform: 'capitalize',
        fontWeight: 600,
        borderRadius: '6px',
        fontSize: '14px',
        lineHeight: '20px',
        minWidth: "125px",
        ...buttonTheme[buttonColor]
      }}
      {...btnProps}>
      {isLoading ? <CircularProgress className="px-3" size={'20px'} /> : children}
    </Button>
  )
}

export default CustomButton
