import Table from 'components/atoms/Table'
import React from 'react'

import CustomButton from 'components/atoms/CustomButton'
import { useTranslation } from 'react-i18next'

const UploadDocsNewChatTable = ({
  updates,
  isUploading,
  uploadListDocument,
  columnsUploadDocs,
  handleUploadDocs,
  closeModal
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Table
        backgroundColorHeadCells="#F7F7F8"
        fixedHeader
        noDataComponent={
          <div className="flex w-full justify-start items-center text-[#828282] p-4">

            {t('No documents available')}
          </div>
        }
        className={'flex-auto h-[300px] overflow-y-auto pr-6'}
        hidePagination
        columns={columnsUploadDocs}
        data={uploadListDocument}
      />
      {/* {Object.entries(updates).length > 0 ? (
        <div className="flex w-full py-4 pl-3">
          <span className="text-[#0049EE] text-small">
            {Object.entries(updates).filter(([, value]) => value?.isEligible).length}
            {t('Uploaded documents')}
          </span>
        </div>
      ) : null} */}
      <div className="flex justify-end items-center gap-4 mt-4">
        <CustomButton
          buttonColor="secondary"
          disabled={isUploading}
          onClick={isUploading ? () => { } : closeModal}>
          {t("Cancel")}
        </CustomButton>
        <CustomButton
          buttonColor={'primary'}
          disabled={isUploading}
          //isLoading={isUploading}
          onClick={isUploading ? () => { } : handleUploadDocs}>
          {t("Confirm")}
        </CustomButton>
      </div>
    </>
  )
}

export default UploadDocsNewChatTable
