import { PostAdd, Refresh } from '@mui/icons-material'
import _ from 'lodash'
import React from 'react'
import parse from 'html-react-parser'
import { Spin } from 'antd'
import { NOTE_TYPE } from '../../../../constant'

const BotResponseItem = ({
  chatData,
  chatItem,
  text = '',
  isLastIndex = true,
  onAddNote = () => {},
  onRegenerate = () => {},
  isLoading
}) => {
  return (
    <div className="flex gap-2 w-full" id={chatItem?.id}>
      <div className="flex justify-center items-start w-10">
        <div className="rounded-full overflow-hidden h-6 w-6 bg-[#0049EE]"></div>
      </div>
      <div className="flex w-full h-full flex-col items-start justify-start">
        <span className="text-base font-bold">Legal AI</span>
        <div className="w-full h-full bot-response">
          {isLoading && isLastIndex ? (
            <div className="w-full h-full flex justify-center items-center">
              <Spin />
            </div>
          ) : (
            parse(text)
          )}
        </div>
        <div className="flex gap-2 items-center pt-2 text-[#919191] ">
          <div
            onClick={() => {
              onAddNote({
                type: NOTE_TYPE.CHAT,
                text: text.replace(/(<([^>]+)>)/gi, ''),
                chat: chatData
              })
            }}>
            <PostAdd className="cursor-pointer hover:text-[#5c5b5b]" />
          </div>

          <div
            onClick={() => {
              _.throttle(() => {
                return onRegenerate(chatItem.id)
              }, 1000)()
            }}>
            <Refresh className="cursor-pointer hover:text-[#5c5b5b]" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default BotResponseItem
