import {
  createCase,
  getRegulationList,
  getStatusListDetailCase,
  getWorkspaceList,
  updateCase
} from 'api'
import { useTranslation } from 'react-i18next'
import NullCaseComponent from 'components/molecules/user/cases/NullCaseComponent'
import DeleteCaseModal from 'components/organisms/users/DeleteCaseModal'
import ExportNotesModal from 'components/organisms/users/ExportNotesModal'
import TCModal from 'components/organisms/users/TCModal'
import CaseHeader from 'components/organisms/users/cases/CaseHeader'
import CaseModal from 'components/organisms/users/cases/CaseModal'
import GridCases from 'components/organisms/users/cases/GridCases'
import ListCases from 'components/organisms/users/cases/ListCases'
import WorkspaceFilter from 'components/organisms/users/workspace/WorkspaceFilter'
import {
  ADD_CASE,
  EDIT_CASE,
  GRID_VIEW,
  INITIAL_WORKSPACE_FILTER,
  LIST_VIEW,
  ROLE_SUPER_ADMIN
} from 'constant'
import { useAuth, useDeleteCase, useExportNotes } from 'hooks'
import useTCPP from 'hooks/useTCPP'
import React, { useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { showErrorMessage, showSuccessMessage } from 'utils/showMessage'
import { isAdminPage } from 'utils/isAdminPage'
const Workspace = () => {
  const navigate = useNavigate()
  const { getAccessToken, platformData, onLogout } = useAuth()
  const {
    exportNotesMutation,
    exportNotesModal,
    openExportNotesModal,
    closeExportNotesModal,
    handleExportNotes
  } = useExportNotes()
  const { handleSubmitTcModal, tcModal, acceptTC, isPPFetching, isTCFetching } = useTCPP(true)
  const { t } = useTranslation()

  const [filterModal, setFilterModal] = useState(INITIAL_WORKSPACE_FILTER)
  const [createUpdateCaseModal, setCreateUpdateCaseModal] = useState({
    show: false,
    type: ADD_CASE,
    data: {}
  })
  const [view, setView] = useState(GRID_VIEW)
  const [search, setSearch] = useState('')
  const { data, refetch } = useQuery({
    queryKey: ['getWorkspaceList', filterModal?.data, search],
    queryFn: async () => {
      let filter_date_type = ''
      let first_date = ''
      let second_date = ''
      let regulation_ids = []
      let status_ids = []
      if (filterModal?.data?.date?.value?.fromDate && filterModal?.data?.date?.value?.toDate) {
        filter_date_type = 'between'
        let fromDate = filterModal?.data?.date?.value?.fromDate?.split('-')
        first_date = `${fromDate[1]}/${fromDate[0]}/${fromDate[2]}`
        let toDate = filterModal?.data?.date?.value?.toDate?.split('-')
        second_date = `${toDate[1]}/${toDate[0]}/${toDate[2]}`
      } else {
        if (filterModal?.data?.date?.value?.fromDate) {
          filter_date_type = 'greater_than_or_equal'
          let fromDate = filterModal?.data?.date?.value?.fromDate?.split('-')
          first_date = `${fromDate[1]}/${fromDate[0]}/${fromDate[2]}`
        }
        if (filterModal?.data?.date?.value?.toDate) {
          filter_date_type = 'less_than_or_equal'
          let toDate = filterModal?.data?.date?.value?.toDate?.split('-')
          second_date = `${toDate[1]}/${toDate[0]}/${toDate[2]}`
        }
      }
      if (filterModal?.data?.regulation?.value)
        regulation_ids =
          Object.keys(filterModal?.data?.regulation?.value)?.map?.((item) => {
            return filterModal?.data?.regulation?.value?.[item]
          }) ?? []
      if (filterModal?.data?.status?.value)
        status_ids =
          Object.keys(filterModal?.data?.status?.value)?.map?.((item) => {
            return filterModal?.data?.status?.value?.[item]
          }) ?? []
      const data = {
        search: search,
        filter_date_type: filter_date_type,
        first_date: first_date,
        second_date: second_date,
        regulations: regulation_ids,
        status: status_ids
      }
      const res = await getWorkspaceList(getAccessToken(), data)
      return res
    },
    onError: (err) => {

      if (err.response.status === 403) {
        onLogout()
      }
    },
    enabled: platformData.role !== ROLE_SUPER_ADMIN
  })



  const {
    deleteCaseMutation,
    deleteCaseModal,
    openDeleteCaseModal,
    closeDeleteCaseModal,
    handleDeleteCase
  } = useDeleteCase(refetch)

  // FILTER
  function openFilterModal() {
    setFilterModal((prev) => ({ ...prev, show: true }))
  }
  function closeFilterModal() {
    setFilterModal((prev) => ({ ...prev, show: false }))
  }
  function handleSubmitFilter(data) {
    setFilterModal((prev) => ({ ...prev, data: data }))
    closeFilterModal()
  }
  function handleSearch(value) {
    setSearch(value)
  }

  // CASE
  function openCaseModal(type, data, message) {
    setCreateUpdateCaseModal((prev) => ({
      ...prev,
      show: true,
      type: type,
      data: data,
      message: message
    }))
  }
  function closeCaseModal() {
    setCreateUpdateCaseModal((prev) => ({ ...prev, show: false }))
  }
  async function handleSubmitCaseModal(data) {
    try {
      if (createUpdateCaseModal.type === ADD_CASE) {
        await createCase(getAccessToken(), data)
        refetch()
        showSuccessMessage(t('success messages.Case Added'))
      } else if (createUpdateCaseModal.type === EDIT_CASE) {
        await updateCase(getAccessToken(), data, createUpdateCaseModal?.data?.id)
        refetch()
        showSuccessMessage(t('success messages.Case Edited'))
      }
    } catch (error) {
      showErrorMessage(error?.response?.data?.errors?.[0]?.message)
    }
  }

  // GENERAL
  function handleToggleView(value) {
    if (value !== null) {
      return setView(value)
    }
  }

  /*   const regulationsData = useQuery({
      queryKey: ['workspace-regulation-list'],
      refetchOnWindowFocus: false,
      queryFn: async () => {
        const res = await getRegulationList(getAccessToken())
        const regulations = res?.resData?.data?.filter?.((item) => {
          if (item?.title?.toLowerCase() === 'civil' || item?.title?.toLowerCase() === 'criminal') {
            return item
          }
        })
        return regulations
      }
    }) */

  const regulationsData = [{ id: 1, title: 'Penale' }, { id: 2, title: 'Civile' }]


  const casesStatus = useQuery({
    queryKey: ['workspace-cases-status-list'],
    refetchOnWindowFocus: false,
    queryFn: () => getStatusListDetailCase(getAccessToken())
  })

  return (
    <div className="overflow-hidden grid grid-cols-1 h-full rounded-3xl">
      <div className="flex flex-col w-full h-full gap-11 pt-8 pl-6 pr-3 bg-[#EDEDEF] border-solid border-[#E3E3E8] border rounded-3xl">
        <CaseHeader
          view={view}
          onSearch={handleSearch}
          openFilterModal={openFilterModal}
          onToggleView={handleToggleView}
          totalFilterActive={
            Object?.entries(filterModal?.data)?.filter(([, { value }]) => value !== null)?.length
          }
          onAddCase={openCaseModal.bind(this, ADD_CASE, {})}
        />
        {(search ||
          Object?.entries(filterModal?.data)?.filter(([, { value }]) => value !== null)?.length !==
          0) &&
          (data?.resData?.data ?? []).length === 0 && (
            <span className="text-[#828282]">{t('no search results')}</span>
          )}
        {!search &&
          Object?.entries(filterModal?.data)?.filter(([, { value }]) => value !== null)?.length ===
          0 &&
          (data?.resData?.data ?? []).length === 0 && (
            <NullCaseComponent onAdd={openCaseModal.bind(this, ADD_CASE, {})} />
          )}
        {view === GRID_VIEW && (data?.resData?.data ?? []).length > 0 && (
          <GridCases
            data={data?.resData?.data ?? []}
            total={data?.resData?.meta?.total ?? 0}
            onViewDetail={(data) => navigate(`/cases/detail`, { state: { data: data } })}
            onEdit={(data) =>
              setCreateUpdateCaseModal((prev) => ({
                ...prev,
                show: true,
                type: EDIT_CASE,
                data: data
              }))
            }
            onDelete={openDeleteCaseModal}
            onExport={openExportNotesModal}
          />
        )}
        {view === LIST_VIEW && (data?.resData?.data ?? []).length > 0 && (
          <ListCases
            data={data?.resData?.data ?? []}
            total={data?.resData?.meta?.total ?? 0}
            onViewDetail={(data) => navigate(`/cases/detail`, { state: { data: data } })}
            onEdit={(data) =>
              setCreateUpdateCaseModal((prev) => ({
                ...prev,
                show: true,
                type: EDIT_CASE,
                data: data
              }))
            }
            onDelete={openDeleteCaseModal}
            onExport={openExportNotesModal}
          />
        )}
        {tcModal.show && (
          <TCModal
            isFetching={isPPFetching || isTCFetching}
            isLoading={acceptTC.isLoading}
            pp={tcModal.pp}
            tc={tcModal.tc}
            show={tcModal.show}
            onClose={onLogout}
            onSubmit={handleSubmitTcModal}
            onLogout={onLogout}
          />
        )}
        {filterModal.show && (
          <WorkspaceFilter
            onClose={closeFilterModal}
            data={filterModal.data}
            show={filterModal.show}
            onSubmit={handleSubmitFilter}
            regulations={regulationsData}
            casesStatus={casesStatus?.data?.resData?.data}
          />
        )}
        {createUpdateCaseModal.show && (
          <CaseModal
            show={createUpdateCaseModal.show}
            data={createUpdateCaseModal?.data ?? {}}
            regulations={regulationsData}
            casesStatus={casesStatus?.data?.resData?.data}
            message={createUpdateCaseModal.message}
            type={createUpdateCaseModal.type}
            onClose={closeCaseModal}
            onSubmit={handleSubmitCaseModal}
          />
        )}
        {exportNotesModal.show && (
          <ExportNotesModal
            show={exportNotesModal.show}
            onExport={handleExportNotes}
            closeModal={closeExportNotesModal}
            isLoading={exportNotesMutation.isLoading}
          />
        )}
        {deleteCaseModal.show && (
          <DeleteCaseModal
            show={deleteCaseModal.show}
            onDelete={handleDeleteCase}
            closeModal={closeDeleteCaseModal}
            isLoading={deleteCaseMutation.isLoading}
          />
        )}
      </div>
    </div>
  )
}

export default Workspace
